@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";
@import "@/styles/mixins/_breakpoint";

.genericErrorWrapper {
  padding: 1.5rem 2.6%;
}

.Container {
  display: flex;
  justify-content: center;
  max-width: 100%;
  height: 884px;
  @media (min-width: $portraitTab) {
    height: 514px;
    position: relative;
    left: 32px;
    right: 32px;
  }
  @media (min-width: $portraitTab) {
    width: 94vw;
  }
  @include breakpoint(desktop-lg) {
    justify-content: space-between;
    height: 635px;
  }
  @include breakpoint(desktop-xlg) {
    height: 765px;
    width: 100vw;
  }
  :global {
    .subContainer {
      display: flex;
      @media (max-width: $commonDevice) {
        flex-direction: column;
        gap: 50px;
      }

      @include breakpoint(desktop-lg) {
        width: 95vw;
        justify-content: center;
      }
      .mainSection {
        position: relative;
        top: 60px;
        gap: 50px;
        display: flex;
        flex-direction: column;
        padding: 0px 18px 0px 18px;
        width: auto;
        @include breakpoint(desktop-lg) {
          width: 623px;
          gap: 70px;
        }
        @include breakpoint(desktop-xlg) {
          width: 720px;
          gap: 70px;
        }
        .buttonSection {
          display: flex;
          gap: 30px;
          height: 240px;
          width: auto;
          @media screen and (max-width: $ipadTab) {
            flex-direction: column;
          }
          @include breakpoint(desktop-lg) {
            flex-direction: row;
            flex-wrap: wrap;
            height: fit-content;
            gap: 40px;
            .button > .small {
              letter-spacing: -0.3px;
              line-height: 22px;
            }
          }
          @include breakpoint(desktop-xlg) {
            flex-wrap: wrap;
            gap: 40px;
            .button > .small {
              letter-spacing: -0.35px;
              height: 50px;
              font-size: 18px;
              line-height: 26px;
            }
          }
          .paraG {
            p {
              display: flex;
              gap: 25px;
              a {
                text-decoration: underline;
              }
            }
            @include breakpoint(desktop-lg) {
              position: relative;
              top: 85px;
            }
          }
        }

        .heading > h1 > p {
          color: $color-slate;
          font-weight: 325;
          font-family: $font-light;
          font-size: 46px;
          line-height: 50px;
          letter-spacing: -3.4px;
          @include breakpoint(desktop-lg) {
            font-size: 60px;
            line-height: 68px;
            letter-spacing: -3.1px;
          }
          @include breakpoint(desktop-xlg) {
            font-size: 100px;
            line-height: 110px;
            letter-spacing: -7.5px;
          }
        }
      }
      .imageDiv {
        img {
          margin: 0px;
          padding: 0px;
          width: 370px;
          height: 370px;
        }
        @media screen and (min-width: $portraitTab) {
          display: flex;
          align-items: center;
        }
        @include breakpoint(desktop-extra-lite-lg) {
          img {
            width: 375px;
            height: 339px;
            display: flex;
            align-items: center;
          }
        }
        @include breakpoint(desktop-extra-lite) {
          img {
            width: 525px;
            height: 525px;
          }
        }
      }
    }
  }
}
