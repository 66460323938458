.image-reel {
  &__view {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center center;
    display: block;
  }

  &__image-thumb-view {
    .image-reel__image-thumb {
      img {
        height: 66px;
        object-fit: cover;
        object-position: 50% 50%;
        mix-blend-mode: multiply;
      }
    }

    .image-reel__image-list {
      margin-bottom: 5px;
      width: 88px;
      height: 66px;
      &:hover {
        cursor: pointer;
      }
      & > div.image-reel__thumbSelected {
        width: 88px;
        height: 66px;
        & > img {
          height: 100%;
          object-fit: cover;
          object-position: 50% 50%;
          mix-blend-mode: multiply;
        }
      }
    }
    ul {
      li {
        list-style: none;
      }
    }
  }

  &__image-list {
    display: block;
    padding-bottom: 5px;
    img {
      width: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      mix-blend-mode: multiply;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }

  &__image-stack-view {
    overflow: auto;
    transition: all 0.35s ease-in-out;
    .image-reel__link-more {
      text-decoration: underline;
    }
    @media print {
      transform: translateY(0px) !important;
      overflow: visible !important;
    }
  }

  &__thumbSelected {
    border: 1px solid $color-dark-gray;
    padding: 2px;
    border-radius: 5px;
  }

  &__image-view {
    display: block;
    margin: 15px auto 11px auto;
    text-align: center;
    cursor: pointer;
  }
  &__image-stack-list {
    margin-left: 2px;
  }

  &__link-more {
    font-size: 14px;
    font-weight: 500;
    color: #757577;
    display: block;
    background: none;
    width: auto;
    border: none;
    .view-more-icon {
      display: block;
      margin: 11px auto;
    }
  }

  &__badge {
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 40px;
    display: flex;
  }
  &__new {
    font-family: $font-medium;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.65px;
    color: $color-white;
    margin-bottom: 0px;
    padding: 9px 12px;
    background-color: $color-kohler-blue;
    text-align: center;
  }
  &__offer {
    background-color: $color-white;
    font-family: $font-medium;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.65px;
    color: $color-kohler-blue;
    margin-bottom: 0px;
    padding: 9px 12px;
    text-align: center;
  }
}

@media screen and (min-width: 1440px) {
  .image-reel__image-stack-view {
    overflow: hidden;
    .image-reel__image-list img {
      width: 660px;
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .image-reel {
    &__image-thumb-view {
      .image-reel__image-list > div.image-reel__thumbSelected {
        width: 64px;
        height: 54px;
      }
      .image-reel__image-list {
        width: 64px;
        height: 56px;
      }
      .image-reel__image-thumb img {
        height: 54px;
      }
    }
  }
}
