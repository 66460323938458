@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";
@import "@/styles/mixins/_breakpoint";

.countrySelectorMain {
  :global {
    @keyframes fadeout {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

    @keyframes fadein {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @media screen and (min-width: 1201px) {
      .countrybg {
        background: rgba(33, 33, 33, 0.7);
        position: absolute;
        width: 100%;
        height: 100vh;
        left: 0;
        top: 45px;
        opacity: 0;
        visibility: hidden;
        -webkit-animation: fadeout 2s;
        animation: fadeout 2s;
        -webkit-transition: visibility 2s linear 0.3s, opacity 0.3s;
        transition: visibility 2s linear 0.3s, opacity 0.3s;

        &.active {
          opacity: 1;
          -webkit-animation: fadein 3s;
          -moz-animation: fadein 3s;
          -o-animation: fadein 3s;
          animation: fadein 3s;
          visibility: visible;
          display: block;
        }
      }
    }

    .gbh-countryselector {
      overflow: hidden;
      position: absolute;
      height: 100vh;
      top: 45px;
      left: 0;

      @media screen and (min-width: 1201px) {
        -webkit-transition: all 1s linear;
        -moz-transition: all 1s linear;
        -ms-transition: all 1s linear;
        transition: all 1s linear;
        height: 0;
        display: none;
      }

      &.active {
        @media screen and (min-width: 1200px) {
          top: 45px;
          height: 640px;
          -webkit-transition: all 1s linear;
          -moz-transition: all 1s linear;
          -ms-transition: all 1s linear;
          transition: all 1s linear;
          display: list-item !important;
          visibility: visible;
        }
      }
      @include breakpoint(desktop-extra-lite-sm) {
        left: -100%;
        top: 0;
      }

      .gbh-close {
        @include breakpoint(desktop-extra-lite) {
          visibility: hidden;
          width: 0;
        }
        @include breakpoint(desktop-extra-lite-sm) {
          display: block;
        }
      }

      &.show {
        height: auto;
        min-height: 484px;
        overflow: hidden;
        z-index: 12;

        @include breakpoint(desktop-extra-lite-sm) {
          display: block;
          top: 0;
          position: absolute;
          z-index: 999;
          left: 0;
        }
      }
    }

    #gbh_utheader {
      color: $white;
      font-family: sans-serif; //update actual ff
      width: 100%;
      z-index: 99;
      .d-lg-none {
        @include breakpoint(desktop-extra-lite) {
          display: block !important;
          visibility: hidden !important;
          width: 0px;
        }
      }
      .pl-4 {
        @include breakpoint(desktop-extra-lite) {
          padding-left: 0px !important;
        }
      }
      .gbh-tab {
        background: $charcoal;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        height: 62px;
        border-bottom: 1px solid $slate;
        border-top: 1px solid $slate;

        @include breakpoint(desktop-extra-lite-sm) {
          border-bottom: 1px solid $slate;
          border-top: 1px solid $slate;
        }
        ul {
          margin: 20px 0 0 0;
          display: flex;
          flex-direction: row;
          position: relative;
          padding-left: 0;
          @include breakpoint(desktop-extra-lite-sm) {
            flex-direction: column;
          }

          li {
            font-size: 1.25rem;
            padding: 23px 30px 6px;
            letter-spacing: -0.025rem;
            color: $medium-gray;
            text-align: center;
            text-transform: inherit;
            font-family: $helvetica-medium;
            list-style: none;
            cursor: pointer;

            &.gbh-tabs__tab--active {
              color: $white;
              border: inherit;
              &:after {
                width: 140%;
                position: relative;
                left: -20%;
                @include breakpoint(desktop-extra-lite-sm) {
                  background: transparent;
                  width: auto;
                  left: 0;
                }
              }
              &:hover {
                &:after {
                  width: 140%;
                  position: relative;
                  left: -20%;
                  background: white;
                  @include breakpoint(desktop-extra-lite-sm) {
                    background: transparent;
                    width: auto;
                    left: 0;
                  }
                }
              }
            }

            &:after {
              content: "";
              display: block;
              width: 0;
              height: 4px;
              background: $white;
              transition: width 0.3s;
              position: relative;
              bottom: -10px;

              @include breakpoint(desktop-extra-lite-sm) {
                background: transparent;
              }
            }

            &:hover:after {
              background: none;
            }
          }
        }
      }

      .gbh-tab__panels {
        background: $charcoal;
        padding: 50px 32px;
        display: flex;
        justify-content: space-around;
        margin: 0 auto;
        .gbh-close {
          @include breakpoint(desktop-extra-lite) {
            visibility: hidden;
            width: 0;
          }
          @include breakpoint(desktop-extra-lite-sm) {
            display: block;
          }
        }

        .gbh-tab__content {
          @include breakpoint(desktop-extra-lite) {
            visibility: hidden;
            width: 0px;
          }
          @include breakpoint(desktop-extra-lite-sm) {
            display: none;
          }
          .left {
            border-right: 1px solid $slate;

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              font-size: 3.125rem;
              line-height: 1.08;
              letter-spacing: -0.1875rem;
              font-family: $helvetica-light;
              margin-bottom: 70px;
              color: $white;
            }

            p {
              color: $light-white;
              font-size: 16px;
              line-height: 1.5;
              letter-spacing: -0.55px;
              font-family: $helvetica-light;
              padding-right: 60px;
            }
          }

          .right {
            padding-left: 121px;

            @include breakpoint(desktop-lg) {
              padding-left: 90px;
            }

            .gbh-tab__list {
              min-height: 280px;
              height: 420px;
              width: 100%;
              flex-wrap: wrap;
              display: flex;
              flex-direction: column;
              margin: 0;
              padding: 0;
              align-items: flex-start;
              padding-left: 0;

              @include breakpoint(desktop-extra-lite-sm) {
                display: block;
              }

              > li {
                list-style: none;
                margin-bottom: 0;
                white-space: nowrap;
                width: 25%;
                margin-right: 60px;
                padding: 3px 0;

                @include breakpoint(desktop-extra-lite-sm) {
                  width: 100%;
                  border: 0;
                }

                a {
                  text-decoration: none;
                  color: $light-white;
                  font-size: 1.125rem;
                  font-family: $helvetica-regular;
                  line-height: 1.95rem;
                  letter-spacing: -0.033125rem;
                  margin: 0;

                  @include breakpoint(desktop-extra-lite-sm) {
                    font-size: 2rem;
                    color: $light-white;
                    padding: 0;
                    font-family: $helvetica-light;
                    letter-spacing: -0.11875rem;
                    line-height: 2.375rem;
                  }

                  .gbh-arrow-right {
                    display: none !important; // We are no need to show this image dropdown so used important

                    @include breakpoint(desktop-extra-lite-sm) {
                      display: inline-block;
                      margin-left: 10px;
                    }
                  }

                  @include breakpoint(desktop-extra-lite-sm) {
                    padding: 0;
                    color: $white;
                  }

                  &:hover {
                    color: $white;
                  }
                  &.selected {
                    color: $dark-gray;
                    + ul {
                      display: block;
                      margin-left: 10px;
                      li a {
                        color: $dark-gray;
                        &:hover {
                          text-decoration: underline;
                        }
                      }
                    }
                  }
                }

                ul {
                  &.sublevel {
                    display: block;
                  }
                  @include breakpoint(desktop-extra-lite-sm) {
                    display: none;
                  }

                  li {
                    display: inline-block;
                    padding: 0;

                    &:first-child {
                      padding-left: 0px;
                    }

                    &:last-child {
                      border-right: none;
                    }

                    a {
                      font-weight: bold;
                      font-family: $helvetica-bold;
                      padding: 0;
                      margin: 0;
                      color: $light-white;

                      &.mobile {
                        @include breakpoint(desktop-extra-lite) {
                          visibility: hidden;
                          width: 0px;
                          margin-left: -3.5px;
                        }
                      }

                      &:hover,
                      &:active {
                        color: $light-white;
                        text-decoration: underline;
                      }
                      &::after {
                        border-right: 1px solid $light-gray;
                        content: "";
                        height: 14px;
                        padding: 0 4px 0 0;
                        margin: 0 7px;
                        display: inline-block;
                        position: relative;
                        top: 2px;
                        @include breakpoint(desktop-extra-lite-sm) {
                          border-right: 0;
                        }
                      }
                    }
                    &:last-child {
                      a {
                        border-right: 0;
                        &::after {
                          border-right: 0;
                          padding: 0;
                          margin: 0 2px;
                        }
                      }
                    }
                  }

                  &.gbh-tab__content--active {
                    display: inline-block;

                    @include breakpoint(desktop-extra-lite-sm) {
                      display: block;
                    }
                  }
                }
              }
            }
          }

          &.gbh-tab__content--active {
            display: block;
            @include breakpoint(desktop-extra-lite) {
              visibility: visible;
            }
            @media only screen and (min-width: 1201px) {
              width: 100%;
              max-width: 1380px;
              margin: 0 auto;
            }
          }
        }
      }
      .gbh-tab__panels {
        .gbh-tab__content {
          .gbh-tab__list {
            li {
              display: flex;
              flex-direction: row;
              margin-right: 0;
              cursor: pointer;
              @media screen and (min-width: 1201px) {
                ul {
                  display: flex;
                  margin-left: 10px;
                  padding-left: 0;
                }
              }
            }
          }
        }
      }
    }

    @include breakpoint(desktop-extra-lite-sm) {
      .active-country + #gbh_utheader {
        left: 0;
      }

      #gbh_utheader {
        width: 100%;
        position: fixed;

        .gbh-tab {
          display: block;
          height: 100vh;
          padding: 15px 32px;
          width: 100%;
          overflow: scroll;
          @include breakpoint(mobile-portrait) {
            padding: 17px 18px;
          }

          .gbh-close-btn {
            display: -webkit-box;
            display: -ms-flexbox;
            color: $light-white;
            font-size: 1rem;
            line-height: 1.95rem;
            padding: 0 18px;
            margin: 37px 0 24px;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            width: 100%;
            font-family: $helvetica-light;
            letter-spacing: -0.0375rem;

            &:before {
              content: "\e92c";
              margin-right: 9px;
              font-size: 13px;
              font-family: icomoon !important;
              color: $light-white;
            }
          }

          ul {
            li {
              display: block;
              font-size: 2rem;
              color: $white;
              text-align: left;
              padding: 0;
              margin: 0;
              font-family: $helvetica-light;
              font-weight: 300;
              line-height: 2.375rem;
              letter-spacing: -0.11875rem;
              margin-bottom: 10px;
              &:last-child {
                margin-bottom: 0;
                padding-bottom: 80px;
              }

              &.active {
                border-bottom: none;
                font-weight: lighter;
                color: $medium-gray;
                font-size: 2rem;
                text-align: left;
                padding: 10px 15px;
                font-family: $helvetica-light;
                font-weight: 300;
                line-height: 2.375rem;
                letter-spacing: -0.11875rem;
              }
            }
          }
        }

        .gbh-tab__panels {
          display: none;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 99;
          width: 100vw;
          height: 100vh;
          background: $charcoal;
          padding: 15px 32px 0;
          overflow: scroll;
          @include breakpoint(mobile-portrait) {
            padding: 15px 18px 0;
          }

          .gbh-back-btn {
            font-size: 1.125rem;
            color: $light-white;
            padding: 0 18px;
            margin: 10px 0 24px;

            &:before {
              @include breakpoint(desktop-extra-lite-sm) {
                content: "\e92c";
                margin-right: 9px;
                font-size: 13px;
                font-family: icomoon !important;
                color: $light-white;
              }
            }
          }

          .gbh-close {
            display: none;

            @include breakpoint(desktop-extra-lite-sm) {
              display: block;
              font-size: 1.125rem;
              margin: 0;
              top: 35px;
              left: 18px;
              margin-bottom: 35px;
            }
          }

          .gbh-tab__content {
            background-color: $charcoal;

            .right {
              padding-left: 30px;

              .gbh-tab__list {
                margin-top: 25px;
                li {
                  font-size: 2rem;
                  font-weight: lighter;
                  display: flex;
                  flex-direction: column;
                  margin-bottom: 10px;
                  line-height: 2.375rem;
                  letter-spacing: -0.11875rem;
                  padding: 0;
                  &:last-child {
                    margin-bottom: 0;
                    padding-bottom: 80px;
                  }

                  ul {
                    width: 106%;
                    margin-left: -15px;
                    flex-direction: column;
                    padding: 20px 0;

                    @include breakpoint(mob-tab-max) {
                      margin-left: -30px;
                    }

                    @include breakpoint(mobile-portrait) {
                      width: 109%;
                    }

                    li {
                      padding: 0 32px 10px 52px;
                      width: 100%;
                      display: block;
                      background: $black;
                      border-right: none;
                      margin-bottom: 0;
                      &:last-child {
                        margin-bottom: 0;
                        padding-bottom: 25px;
                      }
                      @include breakpoint(mobile-portrait) {
                        padding: 0 18px 10px 49px;
                      }

                      &:first-child {
                        padding: 20px 0 10px 52px !important; //Over ride default css
                        @include breakpoint(mobile-portrait) {
                          padding-left: 49px;
                        }
                      }

                      a {
                        display: none;
                        font-family: $helvetica-light;
                        font-size: 2rem;
                        line-height: 1.375rem;
                        letter-spacing: -0.11875rem;

                        &.mobile {
                          display: block;
                          border: none;
                          line-height: 2.2rem;
                          font-weight: unset;
                          color: $white;
                        }
                        &.selected {
                          color: inherit;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    #gbh_utheader {
      .d-lg-none {
        @include breakpoint(desktop-extra-lite-sm) {
          display: block !important;
          visibility: visible !important;
          width: 0px;
        }
      }
      .gbh-tab__panels {
        .gbh-back-btn {
          &:before {
            @include breakpoint(desktop-extra-lite-sm) {
              content: "\e92c";
              margin-right: 9px;
              font-size: 13px;
              font-family: icomoon !important;
              color: $light-white;
            }
          }
        }
      }
    }

    .gbh-article-brand {
      padding: 30px 0;

      .gbh-image__image {
        width: 325px;
        height: 73px;
      }
    }

    .gbh-mobilebottom-menu {
      display: none;

      @include breakpoint(desktop-extra-lite-sm) {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }

      ul {
        @include breakpoint(desktop-extra-lite-sm) {
          display: flex;
          flex-direction: column;
          margin: 0;
          padding: 0;
          display: flex;
          flex-direction: column;
          margin: 0;
          list-style: none;
        }

        li {
          @include breakpoint(desktop-extra-lite-sm) {
            flex-direction: row;
            display: flex;
            justify-content: space-between;
          }

          @include breakpoint(mobile-portrait) {
            padding: 17px 18px;
            &:first-child {
              border-top: unset;
            }
          }

          @include breakpoint(tab) {
            padding: 17px 32px;
          }

          &.gbh-find-place {
            @include breakpoint(desktop-extra-lite-sm) {
              position: absolute;
              top: -5px;
              right: 5px;
            }

            &:before {
              visibility: visible;
              content: "\e903";
              font-size: 20px;
              color: $white;
              font-family: "icomoon";
              margin-right: 10px;
              top: 4px;
              position: relative;

              @include breakpoint(desktop-extra-lite-sm) {
                visibility: hidden;
              }
            }

            a {
              &:after {
                visibility: hidden;

                @include breakpoint(desktop-extra-lite-sm) {
                  content: "\e903";
                  font-size: 20px;
                  color: $white;
                  font-family: "icomoon";
                  margin-left: 10px;
                  top: 0;
                  position: relative;
                  visibility: visible;
                }
              }
            }
          }

          @include breakpoint(desktop-extra-lite-sm) {
            &.gbh-region-mob {
              &:after {
                content: "\e931";
                font-size: 20px;
                color: $white;
                font-family: "icomoon";
                margin-right: 0;
                top: 4px;
                position: relative;
              }
            }
          }
          @include breakpoint(mobile-portrait) {
            &.gbh-region-mob {
              padding: 17px 17px 17px 18px;
            }
          }

          a {
            @include breakpoint(desktop-extra-lite-sm) {
              color: $light-white;
              font-weight: 300;
              display: flex;
              align-items: center;
              font-size: 1.25rem;
              letter-spacing: -0.040625rem;
              font-family: $helvetica-regular;
              margin: 0;
            }

            img {
              @include breakpoint(desktop-extra-lite-sm) {
                margin-right: 10px;
              }
            }
          }
        }

        .gbh-find-img {
          transform: rotateZ(270deg);

          @include breakpoint(desktop-extra-lite-sm) {
            transform: rotateZ(180deg);
          }
        }
      }
    }

    .gbh-tab__panels {
      ul {
        &.gbh-tab__list {
          li {
            &.arrowdropdown {
              &.arrowrotate {
                a {
                  &:after {
                    transform: rotate(180deg);
                    margin-left: 17px;
                    transition-duration: 0.5s;
                  }
                }
              }
              a {
                &:after {
                  content: "\e92a";
                  font-family: "icomoon" !important;
                  font-size: 12px;
                  padding-left: 15px;
                  margin-top: 5px;
                  transition-duration: 0.5s;
                }
              }
            }
          }
        }
      }
    }

    // country active
    @media screen and (min-width: 1201px) {
      #united_states,
      #canada,
      #us-old {
        #gbh_utheader
          .gbh-tab__panels
          .gbh-tab__content
          .right
          .gbh-tab__list
          > li {
          &.united_states {
            a {
              color: $dark-gray;
              cursor: default;
            }
            ul {
              li {
                &:first-child {
                  a {
                    text-decoration: underline;
                    color: $dark-gray;
                  }
                }
              }
            }
          }
        }
      }
    }

    @media screen and (min-width: 1201px) {
      #canada
        #gbh_utheader
        .gbh-tab__panels
        .gbh-tab__content
        .right
        .gbh-tab__list
        > li.united_states
        a,
      #united_states
        #gbh_utheader
        .gbh-tab__panels
        .gbh-tab__content
        .right
        .gbh-tab__list
        > li.united_states
        a,
      #us-old
        #gbh_utheader
        .gbh-tab__panels
        .gbh-tab__content
        .right
        .gbh-tab__list
        > li.united_states
        a {
        color: #757575;
        cursor: default;
      }
      #canada
        #gbh_utheader
        .gbh-tab__panels
        .gbh-tab__content
        .right
        .gbh-tab__list
        > li.united_states
        ul
        li:first-child
        a,
      #united_states
        #gbh_utheader
        .gbh-tab__panels
        .gbh-tab__content
        .right
        .gbh-tab__list
        > li.united_states
        ul
        li:first-child
        a,
      #us-old
        #gbh_utheader
        .gbh-tab__panels
        .gbh-tab__content
        .right
        .gbh-tab__list
        > li.united_states
        ul
        li:first-child
        a {
        text-decoration: underline;
        color: #757575;
      }
    }
  }
}
