.folder-items {
  padding: 10px 20px 25px;
  background-color: #fff;

  hr {
    box-sizing: content-box;
    height: 0;
    border-style: inset;
    border-width: 1px;
  }

  &__tab-info {
    padding: 10px;
  }

  &__thead {
    margin: 15px 0 0;
    padding: 15px 0;

    &__tdata {
      &--price,
      &--qnty,
      &--total {
        display: none;
      }
    }

    &--bottom {
      border-bottom: 0px;
    }
  }

  &__filter {
    width: 100%;

    select {
      width: 100%;
      height: 40px;
      border-radius: 4px;
      border: 1px solid #ccc;
    }
  }

  &__checkbox {
    &--thead {
      display: none;
    }

    &--tbody {
      position: absolute;
      bottom: 15px;
      left: 66px;
    }
  }

  &__cta {
    border: none;
    outline: none;
    border-radius: 4px;
    background-color: #dbdbdb;
    width: 35px;
    height: 35px;
    margin: auto 10px auto 0;
    color: #232323;
    fill: #232323;

    &:disabled {
      cursor: default;
      opacity: 0.7;
    }
    &:hover {
      color: #fff;
      fill: #fff;
      background-color: #364573;
    }
    &--select-all {
      width: 25%;
      height: 40px;
      padding: 0 8px;
      &:hover {
        color: #232323;
        background-color: #dbdbdb;
      }
    }
  }

  &__action-bar {
    display: flex;
    justify-content: start;
    margin-top: 10px;
  }

  &__img {
    margin-right: 18px;
    width: 50px;
    float: left;

    img {
      width: 100%;
    }
  }

  &__product-info {
    float: left;
  }

  &__tbody {
    &__tdata {
      &--info {
        &:after {
          content: "";
          display: block;
          clear: both;
        }
      }

      &--price,
      &--qnty,
      &--total {
        margin-left: 68px;
      }
    }
  }

  &__update-qnty {
    margin-bottom: 10px;

    input {
      width: 50px;
      text-align: center;
      border: 0;
      border-radius: 4px;
      background: #fff;
      padding: 0 1px;
      height: 40px;
      border: solid 1px #ddd;
      margin: 0 10px;
    }
  }

  &__update-cta {
    border: none;
    outline: none;
    background-color: transparent;
    display: inline-block;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  &__find-store {
    border: none;
    outline: none;
    background-color: #364575;
    text-transform: uppercase;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    border-radius: 4px;
    color: #fff;
    padding: 0 20px;
    margin-top: 20px;
    display: inline-block;

    &:hover {
      background-color: #1f2b54;
    }
  }

  &__add-to-cart {
    border: none;
    outline: none;
    background-color: #84a648;
    text-transform: uppercase;
    height: 40px;
    cursor: pointer;
    border-radius: 4px;
    color: #fff;
    padding: 0 20px;
    margin-top: 20px;
  }

  &__trow {
    &--for-items {
      padding-top: 15px;
      position: relative;
      padding-bottom: 44px;
    }
  }
  &__name {
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) {
  .folder-items {
    &__img {
      margin-right: 15px;
      width: 110px;
    }

    &__checkbox {
      &--thead {
        display: block;
        float: left;
        margin-top: 10px;
      }

      &--tbody {
        position: relative;
        bottom: 0px;
        left: 0px;
      }
    }

    &__checkbox-label {
      span {
        display: none;
      }

      &--thead {
        span {
          display: inline-block;
        }
      }
    }

    &__thead {
      &__tdata {
        float: left;

        &--price,
        &--qnty,
        &--total {
          display: block;
          line-height: 40px;
        }

        &--qnty {
          width: 8.33333%;
        }

        &--info {
          width: 50%;
        }

        &--price,
        &--qnty,
        &--total {
          margin-left: 0px;
        }

        &--price {
          width: 16.66667%;
        }

        &--total {
          width: 25%;
          text-align: right;
        }

        &--grand-total {
          width: 50%;
          text-align: right;
        }
      }
    }

    &__tbody {
      &__tdata {
        float: left;

        &--select,
        &--qnty {
          width: 8.33333%;
        }

        &--info {
          width: 41.66667%;
          margin: 0 25px 0 -25px;
        }

        &--price,
        &--qnty,
        &--total {
          margin-left: 0px;
        }

        &--price {
          width: 16.66667%;
        }

        &--total {
          width: 25%;
          text-align: right;
        }

        .title {
          display: none;
        }
      }
    }

    &__product-info {
      width: calc(100% - 126px);
    }

    &__trow {
      &:after {
        content: "";
        display: block;
        clear: both;
      }
    }

    &__filter {
      width: 32%;
      float: right;
      margin-right: 10px;
    }

    &__cta {
      &--select-all {
        display: none;
      }
    }

    &__action-bar {
      margin-top: 0;
      margin-left: 10px;
      float: left;
    }

    &__update-qnty {
      input {
        padding: 0 1px;
        margin: 0px;
      }
    }

    &__add-to-cart,
    &__find-store {
      margin-top: 46px;
    }
    &__color {
      margin-bottom: 10px;
    }
  }
}
