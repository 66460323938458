@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";
@import "@/styles/mixins/_breakpoint";

.SelectProductCategoryWrapper {
  :global {
    .inpage-search-section {
      padding: 70px 60px 70px 60px;
    }
    .inpage-search_category {
      width: 100%;
      background-color: $color-white;
      padding-bottom: 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      @include breakpoint(tab-small) {
        min-height: auto;
      }
      & .select-category {
        padding-bottom: 30px;
        @include breakpoint(tab-small) {
          padding-bottom: 20px;
        }
      }
      & .inpage-category-text {
        font-weight: 325;
        text-align: left;
        @include text-font-style($font-light, 36px, 44px, -1.7px, $color-slate);
        @include breakpoint(tab-small) {
          @include text-font-style(
            $font-light,
            32px,
            40px,
            -2.26px,
            $color-slate
          );
        }
      }

      & .product-category {
        max-width: 1763px;
        padding: 50px 40px 155px;

        @include breakpoint(desktop-mid) {
          padding: 50px 32px 179px;
        }
        @include breakpoint(tab-max) {
          padding: 50px 0px 179px;
        }
        @include breakpoint(desktop-mimi-max) {
          padding: 50px 0px 0px;
        }

        & .cmp-carousel-text {
          padding-left: 0px;
          @include breakpoint(tab-max) {
            padding-left: 32px;
            padding-right: 32px;
            margin: 0px;
          }
        }
        & div.cmp-carousel__content {
          padding-left: 0px;
          padding-bottom: 35px;
          min-height: 357px;
          @include breakpoint(tab-max) {
            padding-bottom: 35px;
          }
          & div.cmp-carousel__item {
            padding: 0px;
            @include breakpoint(tab-max) {
              padding: 0px 3px;
            }
          }
        }
        @include breakpoint(tab-max) {
          & div.cmp-carousel__item.cmp-carousel__item--active:first-child {
            padding-left: 32px;
          }
        }
      }

      & .image-with-text {
        cursor: pointer;
      }
      & .image-part {
        width: 206px;
        height: 274.67px;
        gap: 0px;
      }
      & .text-label {
        font-weight: 500;
        text-align: left;
        padding: 15px 20px 0px 0px;
        @include text-font-style(
          $font-regular,
          10px,
          10px,
          0.5px,
          $color-charcoal
        );
        & .image-text {
          width: 186px;
          font-weight: 500;
          text-align: left;
          display: flex;
          flex-direction: column;
          text-overflow: ellipsis;
          overflow: hidden;
          height: 100%;
          @include text-font-style(
            $font-regular,
            20px,
            28px,
            -0.45px,
            $color-charcoal
          );
        }
      }

      & .coming-soon-text-overlay {
        padding: 15px 0px 0px 0px;
        & .image-text {
          width: 186px;
          font-weight: 500;
          text-align: left;
          display: flex;
          flex-direction: column;
          text-overflow: ellipsis;
          overflow: hidden;
          height: 100%;
          @include text-font-style(
            $font-light,
            20px,
            28px,
            -0.45px,
            $color-charcoal
          );
        }
      }

      & div.comingSoon {
        padding-top: 15px;
      }
      & .coming-soon {
        font-weight: 500;
        text-align: left;
        border: 1px solid $color-medium-gray;
        width: 94px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0px;
        @include text-font-style(
          $font-regular,
          10px,
          10px,
          0.5px,
          $color-charcoal
        );
      }
      .cmp-carousel__content {
        @include breakpoint(tab-small) {
          gap: 5px;
        }
      }
    }
  }
}
