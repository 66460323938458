@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";
.modalWrapper {
  :global {
    height: 100%;
    width: 436px;
    position: fixed;
    top: 0px;
    right: 0px;
    background-color: white;
    z-index: 2000;
    .open {
      transform: translateY(0);
      visibility: visible;
    }
    .close-button {
      width: 30px;
      height: 30px;
      top: 15px !important;
    }
    .modal-title {
      padding-bottom: 15px;
      font-size: 26px;
      line-height: 34px;
      letter-spacing: -1.5px;
      font-family: $helvetica-light;
      margin: 0;
      color: $color-slate;
      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        padding-bottom: 15px;
        font-size: 26px;
        line-height: 34px;
        letter-spacing: -1.5px;
        font-family: $helvetica-light;
        margin: 0;
        color: $color-slate;
      }
    }
    .modal-products {
      display: flex;
      flex-wrap: wrap;
      column-gap: 10px;
      row-gap: 20px;
      padding-top: 10px;
      .inspired-selling-module__card {
        margin-bottom: 10px;
      }
      .regional-brand-name {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.3px;
      }
      .short-description {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.45px;
      }
      .price {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.3px;
      }
    }
    .cta-wrapper {
      margin-bottom: 20px;
    }
    .modal-cta {
      p {
        color: $color-slate;
        width: fit-content;
        font-family: "Helvetica Now Text W05 Medium";
        text-decoration: underline;
        font-size: 16px;
        font-style: normal;
        line-height: 24px;
        letter-spacing: -0.35px;
        cursor: pointer;
      }
      color: $color-slate;
      width: fit-content;
      font-family: "Helvetica Now Text W05 Medium";
      text-decoration: underline;
      font-size: 16px;
      font-style: normal;
      line-height: 24px;
      letter-spacing: -0.35px;
      cursor: pointer;
    }

    .modal-description {
      p {
        margin-bottom: 20px;
        color: $color-dark-gray;
        font-feature-settings: "clig" off, "liga" off;
        font-family: $font-medium;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.55px;
        width: 363px;
        height: 24px;
      }
    }
    .modal-content {
      overflow: auto;
      margin-top: 55px;
      padding-bottom: 60px;
      padding-top: 15px;
      padding-left: 40px;
      padding-right: 20px;
      height: 100%;
      max-height: 98%;
      border: 0;
    }
    .close-button {
      position: absolute;
      top: 25px;
      right: 17px;
      cursor: pointer;
      margin: 6.5px;
    }
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}
