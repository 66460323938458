.sitewide-warning {
  max-width: 500px;
  margin-bottom: -10px;

  &__title {
    color: $color-slate;
    font-family: $font-light;
    font-size: 36px;
    letter-spacing: -1.7px;
    line-height: 44px;
    margin-top: 35px;
  }

  &__desc {
    color: $color-dark-gray;
    font-family: $font-regular;
    font-size: 20px;
    letter-spacing: -0.65px;
    line-height: 28px;
    margin-top: 20px;
    margin-bottom: 40px;
    p {
      color: $color-dark-gray;
      font-family: $font-regular;
      font-size: 20px;
      letter-spacing: -0.65px;
      line-height: 28px;
      margin-top: 20px;
      margin-bottom: 40px;
    }
  }

  .button.primary {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .sitewide-warning {
    max-width: 100%;
  }
}
