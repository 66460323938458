@mixin common-width {
  z-index: 9999999999;
  width: 288px;
  height: 70px;
}
@mixin display-flex-cross-browser {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.product-overview {
  display: grid;
  grid-template-columns: 56.65% 37.08%;

  @include breakpoint(desktop-large) {
    grid-template-columns: 57.19% 36%;
  }

  @include breakpoint(desktop-small) {
    padding-left: 2.6%;
  }
  @media only screen and (min-width: 2840px) {
    padding-left: 1.1%;
  }
}
body.pdp-image-gallery-modal-opened {
  overflow-y: hidden;
  .pdp-pdp-image-reel {
    display: none;
  }
}

/* responsive carousel style */
.pdp-image-gallery {
  position: relative;
  display: flex;

  ul,
  li {
    list-style: none;
    padding: 0;
  }
}

.pdp-image-reel {
  width: 100%;
  display: grid;
  grid-template-columns: 61px 88%;
  &.full-image-pdp {
    display: block;
  }
  &.hide-scrollbar {
    .pdp-image-reel__image-stack-view {
      .pdp-image-reel__image-stack-list {
        .slider-dots {
          display: none !important;
        }
      }
    }
  }
  @include breakpoint(tab-max) {
    grid-template-columns: 61px 84%;
  }
  @media only screen and (max-width: $ipadTab) {
    grid-template-columns: 100%;
    display: flex;
    flex-direction: column-reverse;
  }

  gap: 30px;
  @media only screen and (max-width: $ipadTab) {
    gap: 0;
  }
  @media (min-width: 1599px) and (max-width: 1602px) {
    grid-template-columns: 61px 89.5%;
  }
  @include breakpoint(desktop-large) {
    grid-template-columns: 61px 90%;
  }

  &__modal-image {
    height: 100%;
  }
  &__view {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center center;
    display: block;
  }

  &__image-thumb-view {
    display: flex;
    margin-left: 0;
    align-items: center;
    margin-bottom: 0;
    padding: 0 6.72%;
    margin-left: -2.6%;
    @media only screen and (min-width: 1920px) {
      padding: 0 130px;
      margin-left: -50px;
    }
    .pdp-image-reel__image-thumb {
      img {
        height: 80px;
        object-fit: cover;
        object-position: 50% 50%;
        mix-blend-mode: multiply;
      }
    }
    ul {
      li {
        list-style: none;
      }
    }
  }

  &__image-list {
    display: block;
    padding-bottom: 16px;
    img {
      width: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      mix-blend-mode: multiply;
      aspect-ratio: 1/1;
      cursor: default;
      @include breakpoint(desktop-large) {
        zoom: 1.00098;
      }

      @media print {
        mix-blend-mode: normal;
      }
      &.tap-image-zoom {
        display: none;
      }
    }
    .img-play-button {
      position: absolute;
      width: 64px;
      cursor: pointer;
      mix-blend-mode: initial;
      top: 45%;
      left: 45%;
      height: 64px;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }

  &__image-stack-view {
    width: 100%;
    &.full-image-width {
      width: 100%;
    }
    @media only screen and (max-width: $ipadTab) {
      width: 100%;
      padding-bottom: 30px;
    }
    @media only screen and (max-width: $commonDevice) {
      padding-bottom: 20px;
    }

    position: relative;

    .pdp-image-reel__link-more {
      font-family: $font-medium;
      color: $color-slate;
      font-size: 14px;
      letter-spacing: -0.3px;
      line-height: 22px;
      border: 1px solid $color-slate;
      width: 140px;
      height: 40px;

      display: flex;
      align-items: center;
      justify-content: center;
      -webkit-transition: all 0.5s ease, background-color 1.5s ease;
      -moz-transition: all 0.5s ease, background-color 1.5s ease;
      -o-transition: all 0.5s ease, background-color 1.5s ease;
      transition: all 0.5s ease, background-color 1.5s ease;
      &:hover {
        transform: scale(0.95);
      }
    }
    @media print {
      transform: translateY(0px) !important;
      overflow: visible !important;
    }
  }

  &__thumb {
    display: flex;
    margin-left: 40px;
    padding-top: 45px;
  }

  &__image-thumb-list {
    display: inline-block;
    padding-right: 16px;
    &:last-child {
      padding-right: 0;
    }
  }

  &__image-thumb {
    width: 80px;
    img {
      width: 80px;
      object-fit: cover;
      object-position: 50% 50%;
      mix-blend-mode: multiply;
      aspect-ratio: 1/1;
    }
    &:hover {
      cursor: pointer;
    }
  }

  &__image-thumbSelected {
    border: 1px solid $color-dark-gray;
    width: 80px;
    padding: 2px;
    border-radius: 5px;
    height: 80px;
    img {
      width: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      mix-blend-mode: multiply;
      aspect-ratio: 1/1;
    }
    &:hover {
      cursor: pointer;
    }
  }

  &__image-view {
    display: block;
    margin-top: 9px;
    text-align: right;
    cursor: pointer;
    float: right;
  }
  &__image-stack-list {
    .slick-slider {
      &.slider-pdp {
        .slider-dots {
          li {
            width: 100%;
            height: 5px;
            overflow: hidden;
            flex-basis: 100%;
            flex-grow: 0;
            flex-shrink: 1;
          }
        }
        .slider-dots {
          display: flex !important;
          margin: 0;
          flex-wrap: nowrap;
          list-style-type: none;
          width: 100%;
          position: relative;
          bottom: 6px;
          .slick-active {
            button {
              background: $color-dark-gray;
            }
          }
          button {
            text-indent: -9999px;
            width: 100%;
            height: 5px;
            background: $color-light-gray;
            border: unset;
          }
        }
      }

      @include breakpoint(desktop-small) {
        &:hover {
          .slick-arrow {
            background: rgba(255, 255, 255, 0.75);
            display: block !important;
            transition: all 0.5s ease;
          }
        }
      }

      @media (hover: none) {
        touch-action: pan-y;
      }
      touch-action: unset;
      .slick-arrow {
        @media only screen and (min-width: $ipadTab) {
          display: none !important;
        }
        cursor: pointer;
        z-index: 1;
        @media only screen and (min-width: $ipadTab) {
          width: 50px;
          height: 50px;
        }

        &:hover {
          background: $color-white;
        }
        &.slick-disabled {
          &::before {
            color: $color-medium-gray;
          }

          cursor: default;
        }
        &::before {
          content: "";
          font-family: "icomoon";
          color: $color-charcoal;
          opacity: 1;
        }
      }
    }
    margin-left: 0px;
    @media only screen and (max-width: $ipadTab) {
      margin-bottom: 0;
    }
    .slick-prev {
      left: 20px;
    }
    .slick-next {
      right: 20px;
      &::before {
        display: block;
        transform: rotate(-180deg);
      }
    }
  }

  &__link-more {
    font-size: 14px;
    font-weight: 500;
    color: #757577;
    display: block;
    background: none;
    width: auto;
    border: none;
    .view-more-icon {
      display: block;
      margin: 11px auto;
    }
  }
  &__horizontal-gallery {
    .pdp-image-reel {
      &__image-stack-list,
      &__thumb {
        display: flex;
        margin-bottom: 0;
        @media only screen and (max-width: $ipadTab) {
          display: block;
          padding-bottom: 40px;
        }
      }
      &__image-stack-view,
      &__thumb {
        &::-webkit-scrollbar {
          display: none;
        }
        &.os-theme-dark {
          &.os-host-overflow {
            padding-bottom: 35px;
            margin-left: 2.6%;
            @media only screen and (max-width: 1280px) {
              padding-bottom: 25px;
              margin-left: 2.54%;
            }
            @media only screen and (min-width: 1920px) {
              margin-left: 50px;
            }
          }
          .os-scrollbar {
            padding: 0px;
            &-handle {
              background-color: $color-medium-gray;
            }
            &-horizontal {
              right: 0;
              height: 5px;
              padding: 0 6.72%;
              margin-left: -2.6%;
              margin-right: 0;
              @media only screen and (min-width: 1920px) {
                padding: 0 130px;
                margin-left: -50px;
              }
            }
          }
          .os-content {
            padding-left: 0px !important;
          }
        }
      }
      &__image-list {
        display: inline-flex;
        padding-right: 16px;
        padding-bottom: 0;
        &:last-child {
          padding-right: 0;
        }
        @include breakpoint(tab-landscape) {
          position: relative;
        }

        img,
        iframe {
          width: 100vw;
          max-width: 960px;
          height: 100%;
        }
        img {
          @include breakpoint(tab-landscape) {
            &.tap-image-zoom {
              display: block;
              position: absolute;
              margin: auto;
              left: 0;
              right: 0;
              text-align: center;
              z-index: 1;
              top: 44%;
              width: 223px;
              height: 58px;
              mix-blend-mode: unset;
              -moz-animation: cssAnimation 0s ease-in 3s forwards;
              -webkit-animation: cssAnimation 0s ease-in 3s forwards;
              -o-animation: cssAnimation 0s ease-in 3s forwards;
              animation: cssAnimation 0s ease-in 3s forwards;
              -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards;
            }
          }
        }
        &.pdp-image-reel__modal-image {
          img {
            height: auto;
            width: 700px;
            object-fit: cover;
          }
          iframe {
            height: auto;
            width: 700px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1280px) {
    &__horizontal-gallery {
      .pdp-image-reel {
        &__image-list {
          padding-right: 8px;
          &.pdp-image-reel__modal-image {
            img {
              width: 350px;
            }
            iframe {
              width: 350px;
            }
          }
        }
        &__thumb {
          padding-top: 25px;
        }
        &__image-thumb-list {
          padding-right: 8px;
        }
        &__image-thumb {
          width: 45px;
          img {
            height: 45px;
            width: 45px;
          }
        }
        &__image-thumbSelected {
          height: 45px;
          width: 45px;
        }
      }
    }
  }

  @media only screen and (max-width: 1023px) {
    .slick-arrow {
      display: none !important;
    }
    &__horizontal-gallery {
      .pdp-image-reel__slider-container {
        .pdp-video-wrapper {
          img {
            &.img-fluid {
              margin: -40px 0;
            }
          }
        }

        overflow: hidden;
        position: relative;
      }
      .pdp-image-reel__slider-list {
        overflow: initial;
        white-space: nowrap;
        transition: transform 0.3s ease-in;
        .pdp-image-reel__image-list {
          align-items: center;
          justify-content: center;
          display: inline-flex;
          padding-right: 0;
          .img-play-button {
            position: absolute;
            height: 55px;
            width: 64px;
            cursor: pointer;
            mix-blend-mode: initial;
          }
        }
      }
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    &__image-stack-view {
      margin-left: 0px;
    }
    &__image-thumb-view {
      padding-left: 5px;
    }
  }
  @include breakpoint(mobile-potrait) {
    &__horizontal-gallery {
      .pdp-image-reel__slider-container {
        .pdp-video-wrapper {
          img {
            &.img-fluid {
              margin: 0;
              width: 100%;
              max-width: 100%;
              object-fit: fill;
            }
          }
        }
      }
    }
  }
}

.zoom-image-scroll {
  overflow: hidden;
  height: 100vh;
  position: relative;
  overflow-y: hidden;
  width: 100%;
}
// zoom image css start
.image-overlay {
  display: none;
  position: fixed;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 11111111 !important;
  overflow: auto;
  top: 0;
  padding: 0;
  height: 100vh;
  opacity: 1;
  visibility: visible;
  @media only screen and (max-width: 500px) {
    height: 100% !important;
    overflow: hidden !important;
  }
  @include breakpoint(desktop-small) {
    :focus-visible {
      outline: -webkit-focus-ring-color auto 4px;
      outline-color: #0071e3;
    }
  }
}
.hide-video-present,
.hide-slider {
  display: none;
}
[data-namespace="s7classic"].s7zoomview[cursortype="drag"] {
  cursor: grabbing !important;
}
[data-namespace="s7classic"].s7zoomview[cursortype="zoomin"],
[data-namespace="s7classic"].s7zoomview[cursortype="zoomin"] [role="button"],
.pdp-image-reel__image-display img {
  cursor: url("../images/zoom-image-icon.svg"), auto;
}

.pdp-image-reel__image-display img {
  @include breakpoint(tab-max) {
    cursor: pointer;
  }
}

@keyframes cssAnimation {
  to {
    width: 0;
    height: 0;
    overflow: hidden;
  }
}

@-webkit-keyframes cssAnimation {
  to {
    width: 0;
    height: 0;
    visibility: hidden;
  }
}
.popup-content {
  width: 100%;
  height: 100%;
  display: flex;
  @include display-flex-cross-browser;
  justify-content: center;
  align-items: center;
}

.close-zoom-image {
  position: absolute;
  top: 10px;
  right: 25px;
  cursor: pointer;
  z-index: 99999;
  @media only screen and (max-width: 1024px) {
    right: 20px;
  }
}

.iframe-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;

  @include display-flex-cross-browser;
  flex-direction: column;
  -webkit-box-direction: column;
  -moz-box-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  align-items: center;
  -webkit-align-items: center;
  @include breakpoint(mobile-potrait) {
    background-color: $color-white;
  }
  iframe {
    width: 100%;
    height: 100%;
    border: none;
    position: relative;
    top: 20px;
  }
  .image-wrapper {
    &.imageActive {
      &.remove-border {
        img {
          border: unset !important;
        }
      }
    }
  }
}
.gallery {
  width: 100%;
  text-align: center;
  margin: 0 auto;
  padding: 15px 0px 15px 20px;
  background-color: $color-white;
  z-index: 9;
  @media (max-width: $commonDevice) {
    padding: 20px 0px 20px 20px;
  }

  .slick-slide.cmp-carousel-slick__item:has(div:empty) {
    display: none !important;
  }

  @include breakpoint(mobile-potrait) {
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding-right: 0;
    margin-left: 0;
    padding-left: 0;
    ::after {
      content: "";
      background-color: $color-white;
      width: 100%;
      height: 100%;
      left: -10px;
      right: 0;
      z-index: -1;
      position: absolute;
      bottom: 10px;
    }
  }
}
.zoom-image-product-section {
  .zoom-video {
    height: 100%;
    width: 100%;
    z-index: 9;
    background-color: $color-black;
    top: 15px;
    margin-bottom: 30px;
    @include breakpoint(mobile-potrait) {
      top: 8px;
      position: relative;
      background: linear-gradient($color-black, $color-white);
      margin-bottom: 0;
      iframe {
        height: 81vh;
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    height: 50px;
    div#s7viewer_zoomOutButton:after {
      content: "";
      height: 50px;
      width: 50px;
      background: $color-white;
      position: relative;
      display: block;
      z-index: -1;
      right: 15px;
      border-radius: 100px;
      top: -15px;
    }

    .slick-arrow {
      display: none !important;
    }
  }

  .slick-track {
    margin: 0 auto;
    gap: 15px;
    @media (max-width: $commonDevice) {
      gap: 20px;
    }

    @media (min-width: $commonDevice) {
      display: flex;
      @include display-flex-cross-browser;
      align-items: center;
      justify-content: center;
    }

    @media only screen and (max-width: 970px) {
      @include display-flex-cross-browser;
    }
  }
  .slick-list {
    max-width: 12in;
    margin: 0 auto;
  }
  @include breakpoint(desktop-xxxlg) {
    .slick-slider {
      max-width: 12in;
      margin: 0 auto;
    }
  }

  .slick-next {
    right: -40px;
  }
  .slick-prev {
    left: -40px;
  }
  .slick-disabled {
    cursor: default !important;
  }
  .slick-prev:before,
  .slick-next:before {
    color: $color-charcoal;
    content: "\e92c";
    font-size: 21px;
    justify-content: center;
    align-items: center;
    opacity: 1;
    display: flex;
    font-family: icomoon;
    transform: rotate(-180deg);
  }
  .slick-prev:before {
    transform: rotate(1deg);
  }
  @media only screen and (max-width: 1300px) {
    .slick-next,
    .slick-prev {
      display: none !important;
    }
  }

  .product-detail-page__left-sticky {
    position: relative !important;
    top: unset !important;
  }
  .s7zoomviewer {
    background-color: $color-off-white;
    height: 100% !important;
  }
  #s7viewer {
    #s7viewer_container {
      height: 100% !important;
    }
  }
  .slick-slide {
    display: flex !important;
    align-items: center;
    justify-content: center;
    text-align: center;
    &:last-child {
      margin-right: 0;
    }

    @include breakpoint(mobile-potrait) {
      margin-right: 0;
    }
  }
  .image-wrapper {
    &.imageActive {
      img {
        width: 61px;
        height: 61px;
      }
    }

    cursor: pointer;
    overflow: hidden;
    @include breakpoint(mobile-potrait) {
      outline: unset !important;
    }
    img {
      border: 1px solid $white;
      object-fit: cover;
      @include breakpoint(mobile-potrait) {
        border: unset;
      }
      &:hover {
        border: 0.2px solid $slate;
      }
      @media (hover: none) {
        &:hover {
          border: unset;
        }
      }
    }
    &.imageActive {
      img {
        border: 1px solid $slate;
      }
    }
  }

  .s7zoomviewer.s7touchinput .s7zoomview .s7iconeffect[media-type="standard"] {
    background: url("../images/pinch-image-zoom.svg"), auto !important;
    @include common-width;
  }

  .zoom-activated
    .s7zoomviewer.s7mouseinput
    .s7zoomview
    .s7iconeffect[media-type="standard"],
  .zoom-activated
    .s7zoomviewer.s7touchinput
    .s7zoomview
    .s7iconeffect[media-type="multitouch"],
  .zoom-activated
    .s7zoomviewer.s7touchinput
    .s7zoomview
    .s7iconeffect[media-type="standard"] {
    background: unset !important;
  }
  .s7zoomviewer.s7mouseinput .s7zoomview .s7iconeffect[media-type="standard"] {
    background: url("../images/click-image-zoom.svg") !important;
    @include common-width;
  }

  .s7zoomviewer.s7touchinput .s7zoomview .s7iconeffect[media-type="multitouch"],
  .s7zoomviewer .s7zoomview .s7iconeffect[media-type] {
    @include common-width;
    background: url("../images/pinch-image-zoom.svg") !important;
  }
  .s7button {
    &.s7fullscreenbutton {
      display: none;
    }
  }

  div#s7viewer_zoomInButton,
  div#s7viewer_zoomOutButton,
  div#s7viewer_zoomResetButton,
  #s7viewerGallery_zoomInButton,
  #s7viewerGallery_zoomOutButton,
  #s7viewerGallery_zoomResetButton {
    width: 100%;
    display: block;
    position: relative;
    top: unset !important;
    left: 0 !important;
    bottom: unset !important;
  }
  div#s7viewer_zoomInButton,
  #s7viewerGallery_zoomInButton {
    margin-bottom: 20px;
    z-index: unset;
    display: block !important;
    @media only screen and (max-width: 1024px) {
      margin-bottom: 0;
      opacity: 1 !important;
    }
  }
  div#s7viewer_zoomResetButton {
    top: 50px !important;
    z-index: unset;
  }
  div#s7viewer_zoomInButton:after {
    content: "";
    height: 95px;
    width: 50px;
    background: $color-white;
    position: relative;
    display: block;
    z-index: -1;
    right: 15px;
    border-radius: 100px;
    top: -15px;
    @media only screen and (max-width: 1024px) {
      height: 50px;
    }
  }
  @media only screen and (min-width: 810px) and (max-width: 815px) {
    .gallery {
      display: none;
    }
  }

  div#s7viewer_divcontainer {
    width: 50px !important;
    right: 8px !important;
    left: unset !important;
    float: right !important;
    position: absolute;
    z-index: 9999999999999999 !important;
    top: 43% !important;
    @media only screen and (max-width: 1024px) {
      top: 25px !important;
      right: 69px !important;
    }
  }
  div#s7viewer_zoomResetButton:after {
    content: "";
    width: 50px;
    height: 51px;
    background: $color-white;
    position: relative;
    display: block;
    z-index: -1;
    right: 15px;
    border-radius: 100px;
    top: -16px;
  }
  .s7zoomviewer .s7zoominbutton[state] {
    background-image: url("../images/zoom-in.svg") !important;
    background-repeat: no-repeat;
    background-color: $color-off-white;
  }
  .s7zoomviewer .s7zoomoutbutton[state] {
    background-image: url("../images/zoom-out-action.svg") !important;
    background-repeat: no-repeat;
    @media only screen and (max-width: 1024px) {
      visibility: visible !important;
      z-index: unset;
    }
  }
  [data-namespace="s7classic"].s7zoomview[cursortype="reset"] {
    cursor: url("../images/zoom-out-action.svg"), auto;
  }

  div#s7viewer_zoomResetButton[state="disabled"]:after,
  #s7viewer_zoomResetButton[state="disabled"],
  #s7viewerSpinThreeSixty_zoomResetButton[disabled],
  div#s7viewerSpinThreeSixty_zoomResetButton[state="disabled"]:after {
    background-image: url("../images/reset-inactive.png") !important;
    background-size: cover;
    background: unset;
    z-index: unset;
    background-repeat: no-repeat;
    cursor: default;
  }
  .s7zoomviewer .s7zoomresetbutton[state="up"],
  #s7viewerSpinThreeSixty_zoomResetButton[state="up"],
  .s7zoomviewer .s7zoomresetbutton[state="over"] {
    background-image: url("../images/zoom-reset.svg") !important;
    background-repeat: no-repeat;
  }
  #s7viewer_zoomOutButton[state="disabled"] {
    background-image: url("../images/zoom-out-disable.svg") !important;
    background-repeat: no-repeat;
    cursor: default;
  }

  #s7viewer_zoomInButton[state="disabled"],
  #s7viewerGallery_zoomInButton[state="disabled"] {
    background-image: url("../images/zoom-disable.svg") !important;
    background-repeat: no-repeat;
    cursor: default;
  }

  @media only screen and (max-width: 1024px) {
    .s7zoomoutbutton,
    .s7zoomresetbutton {
      display: none !important;
    }
    .s7zoomviewer.s7size_small.s7touchinput .s7zoominbutton {
      visibility: visible !important;
    }
  }
}
@include breakpoint(mobile-potrait) {
  .video-open {
    .iframe-container {
      background: linear-gradient($color-black, $color-white);
    }
    .close-zoom-image {
      top: 0;
    }
  }
}

// zoom image css end
.pdp-thumbnail-vertical {
  @media only screen and (max-width: $ipadTab) {
    margin-left: 12px;
    &.pdp-slide-change {
      margin-left: -16px;
      @media only screen and (max-width: $commonDevice) {
        margin-left: -13px;
      }
    }
    .slick-track {
      gap: 20px;
      display: flex;
      @media only screen and (max-width: $commonDevice) {
        gap: 18px;
      }
    }
  }
  @media only screen and (max-width: $commonDevice) {
    margin-left: -2px;
  }

  .slick-slider {
    .slick-slide {
      cursor: pointer;
      border: unset;
      .image-wrapper {
        margin-bottom: 15px;
        @media only screen and (min-width: $commonDevice) {
          width: 61px;
          height: 61px;
        }
        @media only screen and (max-width: $ipadTab) {
          margin-bottom: 0;
          @media only screen and (min-width: $commonDevice) {
            width: 62px;
            height: 62px;
          }
        }

        img {
          @media only screen and (min-width: $commonDevice) {
            width: 60px;
            height: 60px;
          }

          &:hover {
            transition: all 0.5s ease;
            border: 1px solid $color-slate;
          }
        }
      }

      &.slick-current {
        .image-wrapper {
          img {
            border: 1px solid $color-slate;
            width: 61px;
            height: 61px;
          }
        }
      }
    }
  }
}

.gallery-image-number {
  @media only screen and (max-width: $ipadTab) {
    display: none;
  }
  display: flex;
  width: 60px;
  height: 60px;
  padding: 6px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border: 1px solid $color-light-gray;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.3px;
  font-family: $font-medium;
  font-weight: 500;
  cursor: pointer;
  color: $color-charcoal;
  &:hover {
    transition: all 0.5s ease;
    border: 1px solid $color-slate;
  }
}
.pdp-video-wrapper {
  position: relative;
  .pdp-image-reel__image-list {
    position: relative;
    height: 100%;
    padding-bottom: calc(95% * 1);
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      aspect-ratio: 16 / 9;
    }
  }
}
.zoom-video-icon {
  position: absolute;
  top: 0;
  left: 0;
  height: 60px;
  width: 60px;
  img {
    height: 60px;
    width: 60px;
  }
}
.zoom-video-section {
  position: relative;
  cursor: pointer;
  img {
    height: 60px;
    width: 60px;
  }
  @media only screen and (max-width: $ipadTab) {
    width: 60px;
    img {
      height: 60px;
      width: 60px;
    }
  }
}
.height-auto {
  .slick-track {
    height: auto !important;
  }
}

@media only screen and (max-width: $commonDevice) {
  .hide-scrollbar {
    .pdp-thumbnail-vertical {
      .slick-slider {
        .slick-slide {
          width: 61px !important;
        }
      }
    }
  }
  .pdp-image-reel__image-stack-list {
    .slick-list {
      padding: 1px;
    }
  }
}
@media only screen and (min-width: 1381px) and (max-width: 1500px) {
  #image-gallery__image-stack-gallery {
    width: 100%;
    overflow: hidden;
    padding-right: 1px;
  }
}
.slider-pdp .slick-slide.cmp-carousel-slick__item.slick-active,
.slider-pdp-thumbnail .slick-slide.cmp-carousel-slick__item.slick-active {
  visibility: visible;
}

.slider-pdp .slick-slide.cmp-carousel-slick__item {
  visibility: hidden;
}
@media only screen and (min-width: $ipadTab) {
  .slider-pdp-thumbnail {
    .slick-slide {
      &.cmp-carousel-slick__item {
        visibility: hidden;
      }
    }
  }
}

.three-sixty-image-gallery,
.three-sixty-image-pdp-gallery {
  [data-namespace="s7classic"].s7container[mode="fullscreen"] {
    .s7fullscreenbutton[selected="true"][state="up"],
    .s7fullscreenbutton[selected="true"][state="over"] {
      background: url("../images/minimize-icon.svg") !important;
    }
    #s7viewerGallery_divToolsButtons,
    #s7viewerSpinThreeSixty_divToolsButtons {
      @media only screen and (min-width: $ipadTab) {
        left: 42% !important;
        bottom: 75px !important;
      }
      @media only screen and (max-width: $commonDevice) {
        left: 40% !important;
      }
      @media only screen and (max-width: $mobile) {
        left: 27.5% !important;
      }
      @media only screen and (max-width: $cartToster) {
        bottom: 30% !important;
      }
    }
    .s7spinbuttons {
      @media only screen and (min-width: $ipadTab) {
        bottom: 95px !important;
        left: calc(42% + 105px) !important;
      }
    }
    .s7fullscreenbutton {
      @media only screen and (min-width: $ipadTab) {
        bottom: 75px !important;
        left: calc(42% + 227px) !important;
      }
      @media only screen and (max-width: $commonDevice) {
        left: calc(44% + 80px) !important;
      }
      @media only screen and (max-width: $mobile) {
        left: calc(27.5% + 110px) !important;
      }
      @media only screen and (max-width: $cartToster) {
        bottom: 30% !important;
      }
    }
  }

  &.zoom-image-product-section {
    .s7zoomoutbutton,
    .s7zoomresetbutton {
      display: block !important;
    }
  }
  .s7zoomresetbutton {
    margin: 0 !important;
  }
  .s7zoomresetbutton[state="over"] {
    background-image: url("../images/zoom-reset.svg") !important;
  }
  .s7fullscreenbutton {
    width: 20px !important;
    height: 19px !important;
    background: url("../images/360-full-screen.svg") !important;
    background-position: center !important;
    background-size: contain !important;
    right: unset !important;
    display: block !important;
    opacity: 1 !important;
    background-repeat: no-repeat !important;
  }
  .s7spinviewer .s7spinview .s7iconeffect[state="spin_1D"] {
    background: url("../images/360-timer.svg") !important;
    width: 312px !important;
    height: 70px !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
  }
  .s7spinviewer.s7touchinput .s7spinview .s7iconeffect[state="spin_1D"] {
    background: url("../images/360-timer-mobile.svg") !important;
  }
  .s7zoominbutton[state="disabled"] {
    background-image: url("../images/360-zoom-in-diabled.svg") !important;
  }
  .s7zoominbutton {
    background-image: url("../images/zoom-in-360.svg") !important;
    margin-right: 25px !important;
  }
  .s7spinviewer .s7spinbuttons .s7panrightbutton[state] {
    background-image: url("../images/right-arrow-360.svg") !important;
  }
  .s7spinviewer .s7spinbuttons .s7panleftbutton[state] {
    background-image: url("../images/left-arrow-360.svg") !important;
  }
  .s7zoomoutbutton {
    margin-right: 20px !important;
  }
  .s7zoomoutbutton[state="disabled"] {
    background-image: url("../images/360-zoom-out-disabled.svg") !important;
  }
  .s7zoomresetbutton[state="disabled"],
  #s7viewer_zoomResetButton[state="disabled"] {
    background-image: url("../images/360-controls-disabled.svg") !important;
  }
  .s7spinbuttons {
    .s7button {
      background-position: center !important;
      background-size: contain !important;
      width: 20px !important;
      height: 20px !important;
      &.s7panleftbutton,
      &.s7panrightbutton {
        height: 15px !important;
      }
    }
  }
  div#s7viewer_divcontainer,
  #s7viewer_divToolsButtons,
  #s7viewerGallery_divToolsButtons,
  #s7viewerSpinThreeSixty_divToolsButtons {
    position: absolute;
    left: 41% !important;
    top: unset !important;
    float: unset !important;
    right: unset !important;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: auto !important;
    bottom: 15px !important;
    @include breakpoint(desktop-extra-sm) {
      left: 39% !important;
    }
    @include breakpoint(desktop-large) {
      left: 42% !important;
    }
    @media only screen and (max-width: $commonDevice) {
      bottom: 110px !important;
      left: 32% !important;
    }
    .s7button {
      left: 0 !important;
      position: relative !important;
      bottom: unset !important;
      visibility: visible !important;
      display: block !important;
      opacity: 1 !important;
    }

    div#s7viewer_zoomInButton {
      margin-bottom: 0px;
      background-color: unset;
      &:after {
        content: unset;
      }
    }
    .s7zoomoutbutton[state="up"],
    .s7zoomoutbutton[state="over"] {
      background-image: url("../images/zoom-out-360.svg") !important;
    }
    #s7viewer_zoomOutButton {
      margin: 0 20px;
      &:after {
        content: unset !important;
      }
    }
    #s7viewer_zoomResetButton {
      top: unset !important;
      background-color: unset;
      &::after {
        content: unset;
      }
    }
  }
}
.overlay-image-360 {
  position: relative;
}
.three-sixty-image-pdp-gallery {
  .s7spinviewer .s7spinbuttons {
    bottom: 64px;
    left: calc(30% + 105px);
    margin-left: 50px;
    @include breakpoint(desktop-xlg) {
      left: calc(34% + 105px);
    }
    @media only screen and (max-width: 1280px) {
      left: calc(25% + 105px);
    }
    @media only screen and (max-width: 1090px) {
      left: calc(16% + 105px) !important;
    }
  }
  .s7fullscreenbutton {
    left: calc(30% + 227px);
    bottom: 45px !important;
    right: unset !important;
    margin-left: 50px;
    width: 20px !important;
    height: 19px !important;
    background-repeat: no-repeat !important;
    @media only screen and (max-width: 1280px) {
      left: calc(25% + 227px) !important;
    }
    @media only screen and (max-width: 1090px) {
      left: calc(16% + 227px) !important;
    }
    @include breakpoint(desktop-xlg) {
      left: calc(34% + 227px) !important;
    }
    @media only screen and (max-width: $ipadTab) {
      left: calc(45% + 105px) !important;
      bottom: 40px !important;
    }
    @media only screen and (max-width: $commonDevice) {
      left: calc(28% + 105px) !important;
      bottom: 30px !important;
    }
    @media only screen and (max-width: $commonDevice) {
      left: calc(35% + 105px) !important;
      bottom: 30px !important;
    }
    @media only screen and (max-width: 500px) {
      left: calc(28% + 105px) !important;
    }
  }
  #s7viewerGallery_divToolsButtons {
    bottom: 45px !important;
    position: absolute;
    left: 30% !important;
    @media only screen and (max-width: 1280px) {
      left: calc(25%) !important;
    }
    @media only screen and (max-width: 1090px) {
      left: calc(16%) !important;
    }
    @include breakpoint(desktop-xlg) {
      left: calc(34%) !important;
    }
    @media only screen and (max-width: $ipadTab) {
      left: 45% !important;
      bottom: 40px !important;
    }
    @media only screen and (max-width: $commonDevice) {
      left: 35% !important;
      bottom: 30px !important;
    }
    @media only screen and (max-width: 500px) {
      left: 28% !important;
    }
  }
  .s7zoomresetbutton[state="up"],
  .s7zoomresetbutton[state="over"] {
    background-image: url("../images/zoom-reset.svg") !important;
  }
}
.s7zoominbutton[state],
.s7zoomoutbutton[state],
.s7zoomresetbutton[state] {
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 20px !important;
  height: 20px !important;
}

#s7viewerSpinThreeSixty,
#s7viewer_zoomView,
#s7viewerSpinThreeSixty_spinView {
  background-color: $color-off-white;
}
.three-sixty-image-gallery {
  .s7spinviewer .s7spinbuttons {
    bottom: 33px !important;
    left: calc(41% + 105px) !important;
    margin-left: 50px !important;
    width: 65px !important;

    @include breakpoint(desktop-extra-sm) {
      left: calc(39% + 105px) !important;
    }
    @include breakpoint(desktop-large) {
      left: calc(42% + 105px) !important;
    }
  }
  .s7fullscreenbutton {
    left: calc(41% + 218px) !important;

    bottom: 15px !important;
    margin-left: 50px !important;
    @include breakpoint(desktop-extra-sm) {
      left: calc(39% + 225px) !important;
    }
    @include breakpoint(desktop-large) {
      left: calc(42% + 225px) !important;
    }
  }
}
@media only screen and (max-width: $ipadTab) {
  .three-sixty-image-gallery,
  .three-sixty-image-pdp-gallery {
    .s7zoominbutton,
    .s7zoomoutbutton,
    .s7zoomresetbutton,
    .s7fullscreenbutton {
      opacity: 1 !important;
    }
    .s7zoomresetbutton {
      display: block !important;
      visibility: visible !important;
      margin-left: 0 !important;
    }
  }
  .three-sixty-image-gallery {
    .s7fullscreenbutton {
      left: calc(44% + 90px) !important;
      @media only screen and (max-width: $commonDevice) {
        left: calc(33% + 90px) !important;
        bottom: 110px !important;
      }
    }
  }
}
#s7viewer_swatches {
  display: none !important;
}
@media only screen and (max-width: $commonDevice) {
  [data-namespace="s7classic"].s7container[mode="fullscreen"] {
    height: 100vh !important;
  }
}
:not(:root):fullscreen::backdrop {
  background: $color-off-white !important;
}

.full-screen-open {
  .nav-up,
  .nav-down {
    position: relative !important;
  }
  overflow: visible !important;
  #s7viewerGallery_container_inner {
    top: 0 !important;
    height: 100vh !important;
  }
  .header-mega-menu-container,
  .carousel-promo-banner,
  .header-container {
    display: none !important;
  }
  @media only screen and (min-width: $portraitTab) and (max-width: $ipadMaxTab) {
    [data-namespace="s7classic"].s7container[mode="fullscreen"] {
      #s7viewerGallery_divToolsButtons,
      .s7fullscreenbutton {
        bottom: 30% !important;
      }
      .s7fullscreenbutton {
        left: calc(42% + 133px) !important;
      }
    }
    .product-detail-page__content-section,
    .pdp-thumbnail-vertical {
      display: none;
    }
    .pdp-image-reel {
      grid-template-columns: 100%;
    }
  }
}
