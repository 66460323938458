.inpage-search {
  padding: 60px;

  &__title {
    font-family: $font-light;
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: -0.65px;
    color: $color-dark-gray;
    &--dark {
      color: $color-light-gray;
    }
  }
  &__header {
    width: 100%;
    z-index: 1;
  }

  &__header-section {
    display: flex;
    margin: auto;
    position: relative;
    padding-top: 40px;
    padding-bottom: 10px;
    align-items: center;
    border-bottom: 1px solid $color-dark-gray;
    & .visual_search_button {
      margin-left: 30px;
    }
    &--light:focus-within {
      border-bottom: 1px solid $color-slate;
    }
  }

  &__search-filed {
    width: 100%;
    position: relative;
    padding: 0px;
    @media (max-width: 1025px) {
      padding: 0 0px 20px 0;
    }
  }
  &__label {
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin-bottom: 10px;
  }
  &__search-control {
    width: 100%;
    height: 90px;
    font-weight: 325;
    text-align: left;
    border: 0;
    padding-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @include text-font-style($font-light, 80px, 0px, -7px, $color-dark-gray);

    &::placeholder {
      font-size: 80px;
      color: $color-dark-gray;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:focus + i {
      display: none;
    }

    &--dark {
      background: $color-charcoal;
      color: $color-white;

      &::placeholder {
        color: $color-dark-gray;
      }
    }
  }
  &__search-visual-icon {
    position: relative;
    cursor: pointer;
    @media (min-width: 1025px) {
      justify-items: center;
      width: 80px;
      height: 80px;
      align-items: center;
      display: flex;
      justify-content: center;
      border-radius: 50px;
    }
    &:hover {
      .tool-tip-custom {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    & .tool-tip-custom {
      position: absolute;
      width: 185px;
      height: 52px;
      display: none;
      text-align: center;
      box-shadow: 0px 0px 20px 0px $shadow-gray;
      background: $white;
      top: 96px;
      left: -60px;
      padding: 15px 20px 15px 20px;
      gap: 10px;
      transform: translate(10px, 10px);
      font-weight: 400;
      margin: auto;
      border-radius: 5px;
      @include text-font-style(
        $font-regular,
        14px,
        22px,
        -0.36px,
        $color-dark-gray
      );
      @media (max-width: 813px) {
        top: 45px;
        left: -135px;
      }
    }
  }

  @media (min-width: 1025px) {
    &__search-visual_show {
      background-color: $color-white;
    }
    &__search-visual {
      background-color: $color-white;
      &:hover {
        background-color: $color-off-white;
      }
    }
  }
  &__search-button {
    position: absolute;
    right: 0;
    top: 70px;
    z-index: 99;
    color: $color-charcoal;
    border: 1px solid $color-slate;
    font-family: $font-medium;
    height: 60px;
    margin: auto;
    padding: 17px 60px 19px;
    border-radius: 30px;
    background: $color-white;

    &--dark {
      color: $color-white;
      background: $color-charcoal;
      border: 1px solid $color-white;
    }
  }

  &--dark {
    background: $color-charcoal;
  }
}
.inpage-search-section {
  padding: 70px 60px;
  @include breakpoint(tab-max) {
    padding: 70px 24px;
  }
  @include breakpoint(desktop-mimi-max) {
    padding: 70px 13px;
  }
}

@media screen and (max-width: 813px) {
  .inpage-search {
    padding: 40px 0px 0px 20px;

    &__search-control {
      font-size: 32px;
      font-weight: 300;
      line-height: 1.25;
      letter-spacing: -2.5px;
      height: 50px;

      &::placeholder {
        font-size: 32px;
        color: rgba(0, 0, 0, 0.2);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      &--dark {
        &::placeholder {
          color: $color-dark-gray;
        }
      }
    }

    &__header-section {
      padding-bottom: 20px;
    }

    &__search-filed {
      padding-bottom: 0;
      padding-right: 20px;
    }

    .button {
      font-size: 18px;
      letter-spacing: -0.35px;
      width: 100%;
      height: 46px;
      margin-top: 30px;
    }
  }

  .inpage-search-section {
    padding: 50px 0px 50px 0px;
  }
}

@media screen and (orientation: landscape) and (max-width: 900px) {
  .inpage-search {
    padding: 40px 0px 0px 45px;

    &__search-control {
      font-size: 32px;
      line-height: 1.25;
      letter-spacing: -2.5px;
      height: 50px;

      &::placeholder {
        font-size: 32px;
        color: rgba(0, 0, 0, 0.2);
      }
    }
  }
  .inpage-search-section {
    padding: 50px 0px 50px 0px;
    @media (max-width: 813px) {
      padding: 50px 0px;
    }
  }
}
