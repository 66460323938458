.shopping-cart {
  margin: 0 20px;

  &__headline {
    font-size: 60px;
    font-weight: 200;
    font-style: normal;
    color: #232323;
    margin: 72px 0 22px;
    text-align: center;
  }

  &__sub-headline {
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    color: #84a648;
    padding-bottom: 0;
    text-align: center;
  }

  &__divider {
    margin: 48px 0;
    border: 0;
    border-top: 1px solid #979797;
  }

  .cart-details {
    &__tbody__data {
      &--shipping,
      &--qnty-status {
        display: none;
      }
    }
  }
}
