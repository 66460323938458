@import "@/styles/base/_colors";
@import "@/styles/mixins/_breakpoint";

.roomDetailWrapper {
  :global {
    @mixin product-card-css {
      .inspired-selling-module__card-container {
        width: 507px;

        .shop-the-room-card-details {
          margin-top: 0.625rem;

          .brand-name {
            color: $dark-gray;
            font-family: $helvetica-regular;
            font-size: 0.875rem;
            letter-spacing: -0.0194rem;
            line-height: 1.125rem;
            padding-top: 0.625rem;
          }

          .regional-brand-name {
            color: $slate;
            font-family: $helvetica-regular;
            font-size: 1.125rem;
            letter-spacing: -0.0512rem;
            line-height: 1.5rem;
            padding-top: 5px;
          }

          .short-description {
            color: $dark-gray;
            font-family: $helvetica-regular;
            font-size: 1rem;
            letter-spacing: -0.0344rem;
            line-height: 1.375rem;
            padding-top: 0.313rem;
            height: 2.75rem;
            width: 85%;

            @media screen and (max-width: 375px) {
              height: 3.6688rem;
            }

            @media screen and (max-width: 767px) {
              height: 4.125rem;
            }

            @media screen and (max-width: 1024px) and (orientation: landscape) {
              height: 4.125rem;
            }
          }

          .price {
            margin-top: 19px;
            color: $charcoal;
            font-family: $helvetica-regular;
            font-size: 1rem;
            letter-spacing: -0.05rem;
            line-height: 22px;
            @media screen and (min-width: 640px) and (max-width: 1023px) {
              margin-top: 0.625rem;
              font-size: 1rem;
            }
            @media screen and (max-width: 639px) {
              margin-top: 0.313rem;
              font-size: 0.875rem;
              letter-spacing: -0.044rem;
            }
          }
        }
      }
    }

    @mixin children-css {
      .title-image-container {
        max-height: 86.563rem;
        width: 100%;
        .room-details {
          margin: 38px 8px 30px 0;

          @media screen and (max-width: 639px) {
            margin: 1.188rem 0 1.25rem 0;
          }
          .product-count {
            height: 1.375rem;
            width: 100%;
            color: $dark-gray;
            font-family: $helvetica-medium;
            font-size: 1rem;
            letter-spacing: -0.35px;
            line-height: 1.375rem;
            margin-bottom: 0.938rem;
            @media screen and (min-width: 640px) and (max-width: 1023px) {
              height: auto;
            }
            @media screen and (max-width: 640px) {
              width: 100%;
              height: auto;
              margin-bottom: 0.625rem;
            }
          }

          .title {
            height: 56px;
            width: 100%;
            color: $slate;
            font-family: $helvetica-light;
            font-size: 2.812rem;
            letter-spacing: -0.2rem;
            line-height: 3.5rem;
            margin-bottom: 20px;
            @media screen and (max-width: 1023px) {
              width: 100%;
              font-size: 1.75rem;
              letter-spacing: -0.124rem;
              height: auto;
              line-height: 2.25rem;
            }
          }

          .description {
            width: 100%;
            color: $dark-gray;
            font-family: $helvetica-regular;
            font-size: 1.25rem;
            letter-spacing: -0.041rem;
            line-height: 1.75rem;
            @media screen and (max-width: 1023px) {
              width: 100%;
              font-size: 0.875rem;
              letter-spacing: -0.028rem;
            }
          }
        }
      }

      .products-container {
        width: 90.9%;
        display: flex;
        flex-wrap: wrap;
        column-gap: 6.66%;
        align-items: center;
        row-gap: 3.75rem;

        @media screen and (max-width: 1023px) {
          width: 100%;
          row-gap: 1.875rem;
          column-gap: 8.84%;
        }

        @include product-card-css;
        .v3_productCardWrapper__FIxaK {
          width: 46.67%;
          @media screen and (min-width: 640px) and (max-width: 1023px) {
            width: 45.58%;
          }
          @media screen and (max-width: 639px) {
            width: 45.58%;
          }
          .inspired-selling-module__card-container {
            width: 100%;
          }
        }
      }
    }

    .room-detail-parent-container {
      padding-left: 14.0625%; //270px-1920px
      padding-right: 14.0625%; //270px-1920px
      padding-bottom: 7.5rem; //120px
      @media screen and (min-width: 640px) and (max-width: 1023px) {
        padding-left: 3.94%; //32px
        padding-right: 3.94%;
        padding-bottom: 2.5rem; //40px
      }
      @media screen and (max-width: 639px) {
        padding-left: 4.8%; //18px
        padding-right: 4.8%;
        padding-bottom: 2.5rem; //40px
      }
      .room-detail-product-detail {
        display: flex;
        flex-direction: row;
        column-gap: 4.348%; //60px-1380
        @media screen and (min-width: 640px) and (max-width: 1023px) {
          column-gap: 9.358%; //70px-748
        }
        @media screen and (max-width: 639px) {
          display: flex;
          flex-direction: column;
          row-gap: 1.875rem;
        }
        @include children-css;
      }

      .room-detail-product-detail__align-right {
        display: flex;
        flex-direction: row-reverse;
        column-gap: 4.348%; //60px-1380
        @media screen and (min-width: 640px) and (max-width: 1023px) {
          column-gap: 9.358%; //70px-748
        }
        @media screen and (max-width: 639px) {
          display: flex;
          flex-direction: column;
          row-gap: 1.875rem;
        }
        @include children-css;
        .title-image-container {
          .room-details {
            text-align: right;
            @include breakpoint(mobile-potrait) {
              text-align: unset;
            }
          }
        }
      }
    }
  }
}
