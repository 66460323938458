@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";
@import "@/styles/mixins/_breakpoint";

.CarouselSlickVertical {
  :global {
    .verticalCarouselTitle {
      color: $color-slate;
      font-family: $font-regular;
      font-size: 2.5rem;
      letter-spacing: -0.156px;
      line-height: 2.875rem;
    }
    .verticalCarouselTitleDescription {
      color: $color-slate;
      font-family: $font-regular;
      font-size: 1.25rem;
      letter-spacing: -0.041rem;
      line-height: 1.75rem;
    }
    .carousel-header-info {
      @include breakpoint(mobile-landscape) {
        position: absolute !important;
        width: 45% !important;
      }
    }
    .cmp-teaser {
      @include breakpoint(mobile-landscape) {
        flex-direction: row-reverse !important;
        width: 100%;
        .cmp-teaser__image {
          margin-right: 0px !important;
          max-height: 100% !important;
        }
        .cmp-teaser__content {
          align-self: flex-end !important;
        }
      }
    }
    .cmp-teaser__title {
      color: $color-slate;
      font-family: $font-regular;
      font-size: 2rem;
      letter-spacing: -0.138rem;
      line-height: 2.5rem;
    }
    .cmp-teaser__description {
      color: $color-slate;
      font-family: $font-regular;
      font-size: 1rem;
      letter-spacing: -0.034rem;
      line-height: 1.5rem;
    }
  }
}
