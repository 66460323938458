@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";

.cart-indicator {
  display: inline-block;

  &__count {
    position: relative;

    span {
      font-family: $font-medium;
      font-size: 11px;
      line-height: 1.09;
      letter-spacing: -0.38px;
      color: #ffffff;
      position: absolute;
      top: 2px;
      left: 8px;
      background: #000;
      @media only screen and (max-width: 1200px) and (orientation: landscape),
        only screen and (min-width: 768px) and (max-width: 1200px) {
        color: #212121;
      }
    }

    &--center {
      span {
        width: 100%;
        left: 0;
        text-align: center;
        margin-left: 2px;
        background: transparent;
      }
    }
  }
}
// Cart indicator widget background
.header-mega-menu-container {
  .cart-indicator {
    &.cart-indicator {
      &__with-count {
        span {
          @include breakpoint(desktop-extra-lite-sm) {
            background: transparent;
          }
        }
      }
    }
  }
  &.nav-up {
    .cart-indicator {
      &.cart-indicator {
        &__with-count {
          span {
            @include breakpoint(desktop-extra-lite-sm) {
              background: $color-white;
              color: $color-charcoal;
              filter: unset;
            }
          }
        }
      }
    }
  }
}
