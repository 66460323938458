@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";
@import "@/styles/mixins/_breakpoint";

.ErrorContainer {
  gap: 60px;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  @include breakpoint(desktop-xxxlg) {
    margin-left: 10px;
  }

  :global {
    .error-section {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
    }
    .sorry-section {
      text-align: left;
      padding-bottom: 40px;
      @include text-font-style($font-light, 60px, 68px, -3.09px, $color-slate);
      @include breakpoint(mobile) {
        @include text-font-style($font-light, 40px, 46px, -2.5px, $color-slate);
      }
    }
    .try-section {
      text-align: left;
      @include text-font-style(
        $font-light,
        20px,
        28px,
        -0.64px,
        $color-dark-gray
      );
    }
    .back-button {
      margin-top: 60px;
      width: 69px;
      border: none;
      border-bottom: 1px solid $color-slate;
      background-color: $white;
    }
  }
}
