.cart-landing-recalculate {
  width: 660px;
  height: 650px;
  overflow-y: scroll;
  padding: 50px;
  margin: auto;
  top: 10%;
  left: 28%;
  background: $white;
  position: absolute;
  @media only screen and (min-width: 375px) and (max-width: 767px) {
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    padding: 75px 17.51px 63.84px 18px;
  }
  @media only screen and (min-width: 812px) and (max-width: 1024px) {
    width: 650px;
    height: 90%;
    top: 5%;
    left: 10%;
  }
  @media only screen and (min-width: 768px) and (max-width: 811px) {
    width: 610px;
    height: 90%;
    top: 5%;
    left: 10%;
  }
  &__modal-popup {
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    height: 100vh;
    z-index: 9999;
    opacity: 1;
    visibility: visible;
  }
  &__tile-calculator {
    &__closeImg {
      float: right;
      margin-top: -25px;
      cursor: pointer;
    }
    &__title {
      height: 44px;
      color: $slate;
      font-family: $helvetica-light;
      font-size: 36px;
      font-weight: 300;
      letter-spacing: -1.7px;
      line-height: 44px;
      display: block;
      margin-top: 15px;
      margin-bottom: 15px;
      @media only screen and (min-width: 375px) and (max-width: 767px) {
        font-size: 32px;
        letter-spacing: -2.2px;
        line-height: 40px;
      }
    }
    &__description {
      height: 48px;
      width: 100%;
      color: $dark-gray;
      font-family: $helvetica-light;
      font-size: 16px;
      letter-spacing: -0.55px;
      line-height: 24px;
      margin-bottom: 40px;
      @media only screen and (min-width: 375px) and (max-width: 767px) {
        font-size: 16px;
        letter-spacing: -0.55px;
        width: auto;
      }
      @media only screen and (min-width: 768px) and (max-width: 811px) {
        width: 500px;
      }
    }
    &__tile-values {
      display: flex;
      padding-left: 0px;
      padding-top: 40px;
      width: 100%;
      @media only screen and (min-width: 375px) and (max-width: 767px) {
        display: inherit;
      }
    }
    &__square-ft {
      width: 50%;
      padding-left: 0px;
      @media only screen and (min-width: 375px) and (max-width: 767px) {
        width: auto;
      }
    }
    &__square-ft-value-details {
      background: $white;
      padding-top: 10px;
      font-size: 18px;
      font-family: $font-medium;
      letter-spacing: 0.5px;
      line-height: 24px;
      input {
        padding-left: 10px;
        width: 190px;
        @media only screen and (min-width: 375px) and (max-width: 767px) {
          width: 100%;
          padding-left: 15px;
        }
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
    &__square-ft-title {
      height: 22px;
      color: $dark-gray;
      font-family: $helvetica-light;
      font-size: 14px;
      letter-spacing: -0.65px;
      line-height: 22px;
    }
    &__tooltip {
      height: 16px;
      width: 16px;
      margin-left: 5px;
    }
    &__square-ft-value {
      height: 60px;
      color: $slate;
      font-family: $helvetica-light;
      font-size: 18px;
      letter-spacing: -0.7px;
      line-height: 26px;
      border: 1px solid $dark-gray;
      border-radius: 4px;
      position: relative;
      display: flex;
      flex-direction: column;
    }
    &__square-ft-sf {
      height: 12px;
      color: $slate;
      font-family: $helvetica-light;
      font-size: 12px;
      letter-spacing: 0.65px;
      line-height: 12px;
      top: 273px;
      font-weight: 500;
      position: absolute;
      left: 210px;
      @media only screen and (min-width: 375px) and (max-width: 767px) {
        top: 295px;
        right: 30px;
        left: inherit;
      }
    }
    &__overage {
      padding-left: 88px;
      width: 50%;
      @media only screen and (min-width: 375px) and (max-width: 767px) {
        width: auto;
        padding-left: 0px;
        padding-top: 20px;
      }
      @media only screen and (min-width: 768px) and (max-width: 811px) {
        padding-left: 65px;
      }
    }
    &__overage-title {
      height: 22px;
      color: $dark-gray;
      font-family: $helvetica-light;
      font-size: 14px;
      letter-spacing: -0.65px;
      line-height: 22px;
    }
    &__overage-select {
      margin-top: 10px;
    }
    &__details {
      padding-top: 40px;
      padding-bottom: 25px;
      border-bottom: 1px solid $color-dark-gray;
    }
    &__total-sf {
      height: 22px;
      color: $slate;
      font-family: $helvetica-light;
      font-size: 14px;
      letter-spacing: -0.45px;
      line-height: 22px;
    }
    &__total-sf-value {
      height: 24px;
      color: $slate;
      font-family: $helvetica-light;
      font-size: 16px;
      letter-spacing: -0.55px;
      line-height: 24px;
      float: right;
    }
    &__total-coverage {
      padding-top: 25px;
    }
    &__total-coverage-title {
      height: 22px;
      width: 93px;
      color: $slate;
      font-family: $helvetica-light;
      font-size: 14px;
      letter-spacing: -0.45px;
      line-height: 22px;
    }
    &__total-coverage-details-head {
      height: 22px;
      width: 126px;
      color: $dark-gray;
      font-family: $helvetica-light;
      font-size: 14px;
      letter-spacing: -0.65px;
      line-height: 22px;
    }
    &__total-coverage-details-value {
      height: 24px;
      color: $slate;
      font-family: $helvetica-light;
      font-size: 16px;
      letter-spacing: -0.55px;
      line-height: 24px;
      float: right;
    }
    &__total-boxes {
      padding-top: 22px;
    }
    &__total-boxes-title {
      height: 22px;
      width: 93px;
      color: $slate;
      font-family: $helvetica-light;
      font-size: 14px;
      letter-spacing: -0.45px;
      line-height: 22px;
    }
    &__total-boxes-details-head {
      height: 22px;
      width: 126px;
      color: $dark-gray;
      font-family: $helvetica-light;
      font-size: 14px;
      letter-spacing: -0.65px;
      line-height: 22px;
    }
    &__total-boxes-details-value {
      height: 24px;
      color: $slate;
      font-family: $helvetica-light;
      font-size: 16px;
      letter-spacing: -0.55px;
      line-height: 24px;
      float: right;
      text-transform: uppercase;
    }
    &__total-price {
      padding-top: 22px;
    }
    &__total-price-title {
      height: 22px;
      width: 93px;
      color: $slate;
      font-family: $helvetica-light;
      font-size: 14px;
      letter-spacing: -0.45px;
      line-height: 22px;
    }
    &__total-price-details-head {
      height: 22px;
      width: 126px;
      color: $dark-gray;
      font-family: $helvetica-light;
      font-size: 14px;
      letter-spacing: -0.65px;
      line-height: 22px;
    }
    &__total-price-details-value {
      height: 68px;
      color: $slate;
      font-family: $helvetica-light;
      font-size: 26px;
      letter-spacing: -1.5px;
      line-height: 34px;
      float: right;
      font-weight: 300;
    }
  }
  &__add-to-cart {
    margin-top: 40px;
    height: 60px;
    border-radius: 1px;
    background-color: $charcoal;
    padding-top: 15px;
    text-align: center;
  }
  &__add-to-cart-btn {
    height: 24px;
    color: $white;
    font-family: $helvetica-light;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.35px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
  }
  &__add-to-cart-btn:hover {
    color: $white;
    text-decoration: none;
  }
  &__footer {
    display: flex;
    padding: 41px 0px 25px 0px;
    @media only screen and (min-width: 375px) and (max-width: 767px) {
      padding: 20px 0px 21px;
    }
    &__body {
      display: contents;
    }
    &__body img {
      height: 156px;
      width: 150px;
      border-radius: 1px;
    }
    &__contents {
      padding-left: 30px;
      @media only screen and (min-width: 375px) and (max-width: 767px) {
        padding-left: 20px;
        width: auto;
      }
      .title {
        width: 250px;
        color: $charcoal;
        font-family: $helvetica-light;
        font-size: 16px;
        letter-spacing: -0.4px;
        line-height: 22px;
        margin-bottom: 15px;
        @include breakpoint(mobile) {
          width: 100%;
        }
      }
      .description {
        width: 250px;
        color: $dark-gray;
        font-family: $helvetica-light;
        font-size: 16px;
        letter-spacing: -0.55px;
        line-height: 22px;
        @include breakpoint(mobile) {
          width: 100%;
        }
      }
    }
    &__price-details {
      display: block;
      position: absolute;
      right: 50px;
      padding-top: 5px;
      @media only screen and (min-width: 375px) and (max-width: 767px) {
        position: inherit;
        right: auto;
        padding-top: 40px;
      }
      .price-sf {
        height: 12px;
        color: $slate;
        font-family: $helvetica-light;
        font-size: 12px;
        letter-spacing: 0.65px;
        text-align: right;
        font-weight: 500;
        line-height: 12px;
      }
      .price {
        height: 28px;
        color: $slate;
        font-family: $helvetica-light;
        font-size: 20px;
        letter-spacing: -0.65px;
        line-height: 28px;
      }
    }
  }
  &__recalculate-main-block {
    font-size: 16px;
    letter-spacing: -0.55px;
    line-height: 24px;
    font-family: $helvetica-light;
    color: $slate;
  }
}
.cart-landing-recalculate__popup-link {
  color: #494949;
  font-family: $helvetica-regular;
  font-size: 16px;
  letter-spacing: -0.55px;
  line-height: 24px;
  margin-left: 15px;
  text-decoration: underline;
  @media only screen and (min-width: 375px) and (max-width: 767px) {
    margin-left: 20px;
  }
}
.cart-landing-recalculate__popup-link:hover {
  color: #494949;
  text-decoration: underline;
}
// Cart page text fix
