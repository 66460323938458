@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";

.hotspotProductCardWraper {
  :global {
    @mixin font-style-mixin {
      font-size: 0.875rem;
      line-height: 1.375rem;
      letter-spacing: -0.0187rem;
    }

    .hotspotProductCard {
      width: 100%;
      background-color: $color-extra-light-gray;
      border: 4px solid $color-light-gray;
      width: 250px;

      a {
        .hotspotProductContainer {
          width: 100%;
          position: relative;
          display: flex;
          flex-direction: column;
          gap: 15px;

          .imageContainer {
            position: relative;
            width: 100%;
            height: 242px;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            aspect-ratio: 1/1;

            .imageCard {
              width: 100%;
              height: 100%;
              -o-object-fit: cover;
              object-fit: cover;
              -o-object-position: 50% 50%;
              object-position: 50% 50%;
            }

            .product-tile__share {
              opacity: 1;
              top: 0;
              right: 0;
              width: 36px;
              height: 36px;

              .product-tile__share-icon {
                background-color: transparent;

                img {
                  width: 15px;
                  height: 13.3px;
                }
              }
            }
          }

          .detailsContainer {
            width: 100%;
            display: flex;
            padding: 0px 10px 10px 10px;
            gap: 5px;
            flex-direction: column;

            .badgeTextContaoner {
              height: 24px;
              padding: 5px 8px 5px 8px;
              background-color: $white;
              border: 1px solid $color-light-gray;
              width: fit-content;

              .textStyle {
                font-family: $font-regular;
                color: $slate;
                line-height: 10px;
                letter-spacing: 0.5px;
                font-size: 10px;
                margin-top: 2px;
              }
            }

            .titleContainer {
              height: 24px;
              font-family: $font-medium;
              letter-spacing: -0.35px;
              color: $charcoal;

              .name {
                float: left;
              }

              .price {
                float: right;
              }
            }

            .descriptionContainer {
              flex: 1;
              font-family: $font-medium;
              letter-spacing: -0.55px;
              color: $charcoal;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}
