@import "@/styles/fonts/style.scss";

// Import base css utilities
@import "@/styles/base/colors";
@import "@/styles/base/fonts";
@import "@/styles/base/icons";
@import "@/styles/base/global";

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.image-placeholder {
  position: relative;
  display: flex;
}

[aria-hidden="true"]:not(.cmp-carousel-slick__item) {
  a,
  button {
    visibility: hidden;
  }
}

[aria-hidden="true"]:not(
    .container-inspired-selling-4up-content .cmp-carousel-slick__item
  ) {
  a,
  button {
    visibility: unset;
  }
}

.h-center {
  display: flex;
  align-content: center;
}
.searchbar-open {
  overflow-y: hidden;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin-right: auto;
  margin-left: auto;
}

.container {
  width: 100% !important;
}
@media print {
  #survey {
    display: none !important;
  }
}

.page {
  &.drawer-open {
    // position: fixed;
    overflow: hidden;
    .product-detail-page__right-sticky {
      position: static;
    }
  }
}
