@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";
@import "@/styles/mixins/breakpoint";

.UploadImageContainer {
  background-color: $color-charcoal;
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  height: min(100%, 100vh);
  z-index: 100;
  animation: slideUp 0.5s ease-out forwards;
  overflow-y: auto;
  padding: 30px 18px 0px;

  @include breakpoint(desktop-extra-lite-sm) {
    padding: 30px 32px 0px;
  }

  @include breakpoint(mobile-potrait) {
    padding: 30px 18px 0px;
  }
  @include breakpoint(desktop-small) {
    padding: 22px 60px 0px;
    display: flex;
    gap: 80px;
    justify-content: center;
    width: 100%;
    height: auto;
    position: relative;
    background-color: $white;
    animation: none;
    overflow: inherit;
  }

  :global {
    .outer-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include breakpoint(mobile-landscape) {
        align-items: flex-start;
      }

      @include breakpoint(desktop-small) {
        margin-top: 30px;
        width: 100%;
      }
      @include breakpoint(desktop-xxxlg) {
        width: 100%;
      }
      @include breakpoint(desktop-mimi-max) {
        height: 100%;
        margin-bottom: 0px;
        display: block;
        max-width: 613px;
      }
    }
    .drag-container {
      border: 2px dashed $medium-gray;
      overflow: hidden;
    }
    .file-upload {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $color-off-white;
      max-height: 601px;
      .file-upload-dropzone {
        @include breakpoint(desktop-xlg) {
          width: 712px;
        }
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        background-color: $color-off-white;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 100%;

        @include breakpoint(tab-max) {
          width: 80%;
          max-width: 446px;
          padding: 50px;
        }

        & > * {
          width: max-content;
        }

        .title {
          @include text-font-style(
            $font-light,
            32px,
            40px,
            -1.6px,
            $color-slate
          );
          margin-bottom: 15px;
        }

        .title-hover {
          @include text-font-style(
            $font-light,
            32px,
            40px,
            -1.6px,
            $color-slate
          );
          margin: auto;
        }

        .seperator {
          @include text-font-style(
            $font-regular,
            16px,
            24px,
            -0.55px,
            $color-slate
          );
          margin-bottom: 25px;
        }

        .browse {
          @include text-font-style(
            $font-regular,
            14px,
            22px,
            -0.3px,
            $color-slate
          );
          padding: 9px 30px;
          border: 1px solid $color-slate;
        }
      }
      .file-upload-dropzone.drag {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 99;
      }
      .drag-wrapper {
        display: flex;
        justify-content: space-between;
        align-content: center;
        flex-direction: column;
        max-width: 712px;
        height: 601px;
        position: relative;
        width: 100%;
      }
      .product_change {
        margin: 0 auto;
        .change-button {
          border: none;
          margin-bottom: 30px;
          border-bottom: 1px solid $color-slate;
          gap: 0px;
          background-color: transparent;
          & span {
            font-weight: 500;
            text-align: left;
            @include text-font-style(
              $font-light,
              16px,
              24px,
              -0.345px,
              $color-slate
            );
          }
        }
      }
    }
    .how-it-works {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      height: 601px;
      margin-left: 0px;
      padding-top: 62px;

      @include breakpoint(desktop-small) {
        margin-left: 80px;
        padding-top: 0px;
      }
      @include breakpoint(desktop-mid) {
        flex-direction: column;
      }
      @include breakpoint(desktop-mimi-max) {
        margin-left: 0px;
      }
      @include breakpoint(desktop-small) {
        height: 601px;
      }
      @include breakpoint(desktop-extra-sm) {
        margin-left: 40px;
      }
      @include breakpoint(tab-max) {
        flex-direction: column;
      }
      @include breakpoint(desktop-mimi-max) {
        padding-top: 62px;
        margin-left: 0px;
        align-items: center;
        flex-direction: column;
      }
      @include breakpoint(mobile-potrait) {
        display: flex;
        justify-content: center;
        padding-top: 0px;
        max-width: 340px;
        margin: 0 auto;
      }
      & .outer-wrapper-wrongImage {
        display: flex;
        justify-content: space-between;
        align-items: center;

        flex-direction: column;
        @include breakpoint(desktop-small) {
          margin-left: 80px;
        }
        @include breakpoint(desktop-extra-sm) {
          margin-left: 0px;
        }
        @include breakpoint(tab-max) {
          flex-direction: row;
          margin-top: 22px;
          gap: 20px;
        }
        @include breakpoint(desktop-mid-max) {
          flex-direction: row;
          margin-top: 22px;
          gap: 20px;
          margin-left: 0px;
        }

        @include breakpoint(desktop-mimi-max) {
          margin-top: 30px;
          width: 100%;
        }
      }
      & .image-caption {
        @include text-font-style($font-light, 16px, 24px, -0.8px, $white);
        font-weight: 325;
        text-align: left;
        margin-top: 5px;
        @include breakpoint(desktop-small) {
          @include text-font-style($font-light, 16px, 24px, -0.8px, $black);
        }
      }
      .main-image-container {
        @include breakpoint(desktop-small) {
          width: 100%;
        }

        @include breakpoint(mobile-landscape) {
          width: 100%;
        }
      }
      & .corret-image-container {
        @include breakpoint(desktop-small) {
          max-width: 474px;
          height: 572px;
        }

        @include breakpoint(desktop-mid) {
          height: 401px;
          max-width: 100%;
        }

        @include breakpoint(mobile-landscape) {
          max-width: 612px;
          height: 612px;
        }
        @include breakpoint(desktop-mimi-max) {
          max-width: 612px;
          height: 612px;
        }
        @include breakpoint(mobile-potrait) {
          width: 340px;
          height: 340px;
          max-width: 340px;
        }
      }
      & .correct-image {
        width: 100%;
        height: 100%;
        max-width: 100%;
        object-fit: cover;
        @include breakpoint(mobile-landscape) {
          height: 100%;
        }
        @include breakpoint(mobile-potrait) {
          width: 340px;
          height: 340px;
          max-width: 340px;
        }
      }
      & .wrong-image-section {
        display: flex;
        flex-direction: column;
        position: relative;
        @include breakpoint(desktop-xlg) {
          width: 158px;
        }
        @include breakpoint(desktop-lg) {
          width: 131px;
        }
        @include breakpoint(tab-max) {
          width: auto;
        }
        @include breakpoint(desktop-mimi-max) {
          width: 188.14px;
        }
        @include breakpoint(mobile-landscape) {
          width: 188.14px;
          & img {
            height: auto;
          }
        }
        @include breakpoint(mobile-potrait) {
          width: 97px;
          height: 97px;
        }
      }
      & .incorrect-image {
        width: 100%;
        @include breakpoint(desktop-small) {
          max-height: 158px;
        }
        @include breakpoint(desktop-mimi) {
          height: auto;
        }
      }
      & .indicator-icon {
        position: absolute;
        top: 0px;
        right: 0px;
        transform: translate(50%, -50%);
        @include breakpoint(mobile) {
          transform: translate(47%, -35%);
        }
      }
      & .indicator-wrong-icon {
        position: absolute;
        top: 0px;
        right: 0px;
        transform: translate(50%, -50%);
      }
    }

    .mobile-wrapper {
      position: fixed;
      top: 32px;
      right: 32px;
      left: 32px;
      @include breakpoint(mobile) {
        top: 16px;
        right: 18px;
        left: 18px;
      }
      & .close-container {
        position: absolute;
        top: 0px;
        right: 40px;
        left: 40px;
        @include breakpoint(desktop-mimi-max) {
          right: 0px;
          left: 0px;
        }
        margin-left: 5px;
      }
      background-color: $color-charcoal;

      & .outer-wrapper-wrongImage {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
      }

      & .corret-image-container {
        @include breakpoint(desktop-mimi-max) {
          min-width: 612px;
          min-height: 612px;
        }
      }

      .guide-photo {
        flex: 1;
        & p {
          text-align: center;
          font-weight: 325;
          @include text-font-style($font-light, 24px, 30px, -1.2px, $white);
          @include breakpoint(mobile-potrait) {
            @include text-font-style($font-light, 20px, 28px, -1px, $white);
          }
        }
      }
      .close-Icon {
        .close-big {
          display: block;
          @include breakpoint(mobile-potrait) {
            display: none;
          }
        }
        .close_small {
          display: none;
        }
        @include breakpoint(mobile-potrait) {
          .close_small {
            display: block;
          }
        }
        @include breakpoint(desktop-small) {
          .close-big {
            display: none;
          }
        }
      }
    }
  }
}
.QrWrapper {
  :global {
    padding: 50px;
    display: flex;
    justify-content: center;
    .photo_label {
      padding-right: 30px;
    }
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
    background-color: $black;
  }
}
