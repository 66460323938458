// BREAKPOINTS

// *********************

@mixin breakpoint($point) {
  @if $point == desktop-xxxlg {
    @media (min-width: 1401px) and (max-width: 1920px) {
      @content;
    }
  } @else if $point == desktop-large {
    @media (min-width: 1920px) {
      @content;
    }
  } @else if $point == desktop-extra-lite-sm {
    @media (max-width: 1200px) {
      @content;
    }
  } @else if $point == desktop-extra-lite-lg {
    @media (min-width: 1023px) and (max-width: 1200px) {
      @content;
    }
  } @else if $point == desktop-extra-lite {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $point == desktop-extra-sm {
    @media (min-width: 1024px) and (max-width: 1280px) {
      @content;
    }
  } @else if $point == desktop-xlg {
    @media (min-width: 1601px) {
      @content;
    }
  } @else if $point == desktop-lg {
    @media (min-width: 1024px) and (max-width: 1600px) {
      @content;
    }
  } @else if $point == desktop-mid-max {
    @media (max-width: 1440px) {
      @content;
    }
  } @else if $point == desktop-mid {
    @media (min-width: 1024px) and (max-width: 1440px) {
      @content;
    }
  } @else if $point == desktop-mimi {
    @media (min-width: 812px) and (max-width: 1024px) {
      @content;
    }
  } @else if $point == desktop-mimi-max {
    @media (max-width: 812px) {
      @content;
    }
  } @else if $point == tab-small {
    @media (min-width: 375px) and (max-width: 812px) {
      @content;
    }
  } @else if $point == mobile-small {
    @media (max-width: 376px) {
      @content;
    }
  } @else if $point == desktop-small {
    @media (min-width: 1024px) {
      @content;
    }
  } @else if $point == tab-max {
    @media (max-width: 1024px) {
      @content;
    }
  } @else if $point == tab-min {
    @media (min-width: 561px) and (max-width: 768px) {
      @content;
    }
  } @else if $point == tab {
    @media (min-width: 561px) and (max-width: 1024px) {
      @content;
    }
  } @else if $point == mobile {
    @media (max-width: 560px) {
      @content;
    }
  } @else if $point == mobile-potrait {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $point == mobile-landscape {
    @media (min-width: 768px) and (max-width: 1023px) {
      @content;
    }
  } @else if $point == tab-landscape {
    @media (max-width: 1024px) {
      @content;
    }
  } @else if $point == mobile-portrait-giant {
    @media (min-width: 638px) and (max-width: 640px) {
      @content;
    }
  } @else if $point == tab-max-large {
    @media (min-width: 992px) and (max-width: 1200px) {
      @content;
    }
  }
}
$portraitTab: 768px;
$mobile: 432px;
$ipadTab: 1023px;
$commonDevice: 767px;
$cartToster: 812px;
$XSMobile: 376px;
$smallMobile: 320px;
$ipadMaxTab: 1024px;
$smallMobile: 639px;
$largeTabMax: 1200px;
$tab-min-giant: 820px;
$tab-max-sm: 1180px;
