@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";

.findAProByZipWrapper {
  :global {
    .zipcode-page {
      &__zipcode-right {
        .zipcode-text-box {
          .submitCTA {
            font-size: 14px;
            line-height: 24px;
            letter-spacing: -0.25px;
            min-width: 105px;
            height: 44px;
          }
        }
      }
    }
  }
}
