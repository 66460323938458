.shimmer-loading {
  &__title {
    height: 40px;
  }
  &__headerTitle {
    height: 110px;
  }

  &__title-h2 {
    height: 35px;
  }
  &__title-h3 {
    height: 30px;
  }
  &__title-h4 {
    height: 25px;
  }
  &__para {
    height: 20px;
    margin-bottom: 10px;
  }
  &__headpara {
    height: 40px;
  }
  &__para30 {
    height: 30px;
  }
  &__button {
    height: 40px;
    margin-bottom: 20px;
  }
  &__input {
    height: 60px;
    margin-bottom: 20px;
  }
  &__image {
    height: 100%;
    margin-bottom: 20px;
  }
  &__mt {
    margin-top: 10px;
  }
}
.mt42 {
  margin-top: 42px;
}
.borderNone {
  border: none !important;
}
.h74 {
  height: 74px;
}
.mt80 {
  margin-top: 80px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb200 {
  margin-bottom: 200px;
}
.mb60 {
  margin-bottom: 60px;
}
.mt60 {
  margin-top: 60px;
}
.height-60 {
  height: 60px;
}
.height-400 {
  height: 400px;
}
.width-full {
  width: 100%;
}
.width-140 {
  width: 140px;
}
.width-200 {
  width: 200px;
}
.width-80 {
  width: 80px;
}
.width-100 {
  width: 100px;
}
.width-170 {
  width: 170px;
}
.width-250 {
  width: 250px;
}
.width-320 {
  width: 320px;
}
.width-300 {
  width: 300px;
}
.width-240 {
  width: 240px;
}
.width-90 {
  width: 90px;
}
.height-full {
  height: 100%;
}
.height-300 {
  height: 300px;
}
.p0 {
  padding: 0px;
}
.mr-20 {
  margin-right: 20px;
}
.mb100 {
  margin-bottom: 100px;
}
.loadingHeight {
  height: 90px;
}
.inputBoxLoading {
  height: 150px;
}
.products__controls_shimmer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  .width-140 {
    width: 140px;
  }
  .width-80 {
    width: 80px;
  }
}
.cartIcon::after {
  background: url(../../public/icons/cart-icon-empty.svg);
  content: "";
  width: 26px;
  height: 20px;
  top: 13px;
  position: absolute;
  display: inline-block;
}
