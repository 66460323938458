@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";

.QRCodeWrapper {
  :global {
    .qr-section {
      display: flex;
      justify-content: center;
      align-self: center;
      align-items: center;
      & .photo_label {
        padding-right: 30px;
        text-align: right;
        @include text-font-style($font-light, 20px, 26px, -1.15px, $black);
      }
      .qr-part {
        padding: 13px;
      }
    }
  }
}
