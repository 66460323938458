@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";
@import "@/styles/mixins/breakpoint";

.ComingSoonContainer {
  gap: 60px;
  width: 100%;
  @include breakpoint(desktop-xxxlg) {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  :global {
    .para-section {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    .button-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 60px 0px;

      @include breakpoint(tab-small) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    .para-two-section {
      @include text-font-style(
        $font-light,
        20px,
        28px,
        -0.6px,
        $color-dark-gray
      );
      text-align: left;
      @include breakpoint(tab-small) {
        margin-bottom: 40px;
      }
    }
    .working-para {
      text-align: left;
      @include text-font-style($font-light, 64px, 64px, -4.5px, $color-slate);
      @include breakpoint(tab-max) {
        @include text-font-style(
          $font-light,
          60px,
          68px,
          -3.09px,
          $color-slate
        );
      }
      @include breakpoint(tab-small) {
        @include text-font-style($font-light, 40px, 46px, -2.5px, $color-slate);
      }
    }
    .view-button {
      width: 213px;
      height: 60px;
      gap: 10px;
      border: 1px solid $color-slate;
      background-color: white;
      & .button-label {
        text-align: center;
        @include text-font-style(
          $font-regular,
          18px,
          26px,
          -0.35px,
          $color-slate
        );
      }
    }
    .back-button {
      width: 124px;
      border: none;
      border-bottom: 1px solid $color-slate;
      background-color: $white;
    }
    .secong-line {
      display: block;
      padding-top: 10px;
    }
  }
}
