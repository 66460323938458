.options-selector {
  width: 100%;
  .Collapsible {
    border-bottom: none;
    .collapsible__header {
      span {
        font-family: $font-light;
        font-size: 22px;
        line-height: 1.45;
        letter-spacing: -1.2px;
        color: $color-black;
      }
    }
    .Collapsible__contentOuter {
      .Collapsible__contentInner {
        margin-top: 15px;
      }
    }
  }
  &__heading {
    margin-bottom: 0.625rem;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    position: relative;
    img {
      display: inline-block;
      cursor: pointer;
      max-height: 1.875rem;
      max-width: 1.875rem;
    }
  }
  &__dropdown {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.875rem;
    position: relative;
  }
  &__tooltip {
    display: flex;
  }
  .tooltip__container {
    position: absolute;
    left: 0;
    top: 32px;
    z-index: 9;
  }
  .select-dropdown {
    width: 100%;
    &__value {
      bottom: 0;
    }
  }
}

@media only screen and (max-width: 860px) and (orientation: landscape),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .options-selector {
    .select-dropdown {
      padding-right: 10px;
    }
  }
}
