.preview-cart {
  position: fixed;
  z-index: 10000000;
  display: flex;
  flex-direction: column;
  right: 0;
  top: 0;
  bottom: 0;
  width: 435px;
  height: 100vh;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  transition: transform 0.5s ease;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  @media (max-width: $mobile) {
    width: calc(100% - 50px);
  }
  &--open {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    .bundles-checkout-message {
      color: $slate;
    }
  }
  &__header {
    padding: 50px 40px 0 40px;
    @media (max-width: $cartToster) {
      padding: 25px 20px;
      padding-bottom: 0;
    }
    .title-group {
      border-bottom: 1px solid $color-dark-gray;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;
      .close-btn {
        padding: 7.5px;
        .close-icon {
          display: none;
          @media (max-width: $cartToster) {
            display: block;
          }
        }
      }
    }
  }
  &__title {
    font-family: $font-light;
    font-size: 26px;
    line-height: 2.125rem;
    letter-spacing: -1.55px;
    color: $color-slate;
    &.--guest {
      font-size: 22px;
    }
  }
  &__welcome {
    color: $color-dark-gray;
    font-family: $font-regular;
    font-size: 14px;
    letter-spacing: -0.65px;
    line-height: 22px;
    margin-top: 10px;
  }
  &__signin {
    cursor: pointer;
    text-decoration: underline;
    color: $color-dark-gray;
    font-family: $font-regular;
    font-size: 14px;
    letter-spacing: -0.45px;
    line-height: 22px;
    margin-top: 10px;
  }
  &__list {
    overflow-y: auto;
    flex-grow: 1;
    padding: 30px 40px 30px;
    @media (max-width: $cartToster) {
      padding: 30px 20px 30px 20px;
    }
  }
  &-item {
    display: flex;
    border-top: 1px solid $color-dark-gray;
    padding-top: 20px;
    padding-bottom: 20px;
    &:first-child {
      margin-top: 0;
      padding-top: 0;
      border-top: none;
    }
    &:last-child {
      padding-bottom: 0;
    }
    &__item-of {
      padding: 15px 0;
      border-top: 1px solid $color-dark-gray;
      color: $color-slate;
      font-family: $font-regular;
      font-size: 14px;
      letter-spacing: -0.45px;
      line-height: 22px;
    }

    &__image {
      width: 60px;
      height: 80px;
      object-fit: contain;
      margin-right: 25px;
    }
    &__details {
      flex: 1;
    }
    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
    &__remove {
      span {
        cursor: pointer;
        text-decoration: underline;
        font-family: $font-regular;
        font-size: 14px;
        line-height: 1.57;
        letter-spacing: -0.65px;
        color: $color-dark-gray;
        margin-right: 2px;
      }
      &.--loading {
        div {
          cursor: not-allowed;
        }
        span {
          pointer-events: none;
        }
      }
    }
    &__brand {
      font-family: $font-medium;
      font-size: 16px;
      line-height: 1.38;
      letter-spacing: -0.25px;
      color: $color-dark-gray;
    }
    &__name {
      font-family: $font-light;
      font-size: 22px;
      line-height: 1.45;
      letter-spacing: -1.2px;
      color: $color-charcoal;
      margin-bottom: 5px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
    &__finish {
      font-family: $font-regular;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.55px;
      color: $color-slate;
      margin-bottom: 20px;
    }
    &__footer {
      display: flex;
      justify-content: space-between;
      @media (max-width: $portraitTab) {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
      }
    }
    &__qty {
      width: 90px;
      @media only screen and (min-width: 1024px) and (max-width: 1366px) {
        width: 65px;
      }
      &-label {
        font-family: $font-regular;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.55px;
        color: $color-slate;
      }
    }
    &__price-details {
      display: flex;
      flex-direction: column;
      text-align: right;
      margin-right: 2px;
    }
    &__price {
      font-family: $font-bold;
      font-size: 16px;
      line-height: 1.38;
      letter-spacing: -0.8px;
      color: $color-charcoal;
      margin-bottom: 5px;
    }
    &__discount {
      font-family: $font-regular;
      font-size: 16px;
      line-height: 1.38;
      letter-spacing: -0.8px;
      color: $color-dark-gray;
      text-decoration: line-through;
      margin-bottom: 0;
      margin-left: 15px;
    }
    &__alert {
      display: flex;
      padding-top: 30px;
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
      span {
        color: $color-error-red;
        font-family: $font-regular;
        font-size: 14px;
        letter-spacing: -0.3px;
        line-height: 22px;
        font-weight: 500;
      }
    }
    &__discount-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    &__offPercent {
      font-family: $font-medium;
      font-size: 16px;
      line-height: 1.38;
      letter-spacing: -0.8px;
      color: $color-slate;
      padding-right: 0;
    }
  }
  &__empty {
    margin-top: 45px;
    p {
      margin-bottom: 15px;
      font-family: $font-light;
      font-size: 36px;
      line-height: 1.22;
      letter-spacing: -1.7px;
      color: $color-slate;
    }
    span {
      cursor: pointer;
      font-family: $font-regular;
      font-size: 20px;
      line-height: 1.4;
      letter-spacing: -0.65px;
      color: $color-dark-gray;
      text-decoration: underline;
    }
  }
  &__footer {
    box-shadow: 0 -10px 20px 0 rgba(0, 0, 0, 0.07);
    // margin: auto -40px;
    padding: 30px 40px;
    @media (max-width: $cartToster) {
      padding: 30px 20px;
    }
  }
  &__subtotal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    &--label {
      font-family: $font-regular;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.55px;
      color: $color-slate;
    }
    &--value {
      font-family: $font-light;
      font-size: 32px;
      line-height: 1.25;
      letter-spacing: -1.5px;
      color: $color-slate;
    }
  }
  &__cta {
    display: flex;
    gap: 10px;
  }

  &.preview-cart {
    &--open {
      margin: 0;
      @include breakpoint(desktop-small) {
        padding-right: 0;
      }
      .preview-cart {
        &__title {
          @include text-styles($font-light, 26px, 34px, -1.5px);
          font-style: normal;
          font-weight: 325;
          color: $color-slate;
          @media (max-width: $cartToster) {
            font-size: 20px;
            line-height: 26px;
          }
        }
        &-item__image {
          margin-right: 0.9375rem;
          width: 6.25rem;
          height: 6.25rem;
          object-fit: contain;
        }
        &-item__header {
          margin: 0;
        }
        &-item__brand {
          margin-bottom: 2px;
          @include text-styles($font-medium, 14px, 22px, -0.3px);
          font-weight: 500;
          color: $color-dark-gray;
          text-transform: uppercase;
        }
        &-item__name {
          margin-bottom: 0.9375rem;
          @include text-styles($font-light, 1rem, 1.5rem, -0.55px);
          color: $color-slate;
        }
        &-item__finish {
          margin: 0;
          @include text-styles($font-light, 0.875rem, 1.375rem, -0.45px);
          color: $color-slate;
        }
        &-item__footer {
          margin-top: 1.25rem;
        }
        &-item__remove {
          display: flex;
          justify-content: flex-end;
          @media (max-width: $portraitTab) {
            justify-content: flex-start;
            margin-top: 10px;
          }
          @include text-styles($font-regular, 0.875rem, 1.375rem, -0.3px);
          color: $color-charcoal;
          span {
            @include text-styles($font-regular, 0.875rem, 1.375rem, -0.3px);
            color: $color-charcoal;
          }
        }
        &-item__price {
          margin: 0;
          @include text-styles($font-medium, 1rem, 1.5rem, -0.35px);
          color: $color-charcoal;
          font-weight: 500;
        }
        &-item__discount-container {
          display: flex;
          flex-direction: column-reverse;
          align-items: flex-end;
        }
        &-item__offPercent {
          padding-top: 0.625rem;
          padding-bottom: 1.25rem;
          margin: 0;
          @include text-styles($font-medium, 0.75rem, 1.125rem, -0.25px);
          color: $color-charcoal;
        }
        &-item__discount {
          @include text-styles($font-regular, 1rem, 1.5rem, -0.35px);
          color: $color-dark-gray;
          margin: 0;
          span {
            @include text-styles($font-regular, 1rem, 1.5rem, -0.35px);
            color: $color-dark-gray;
            margin: 0;
          }
        }
        &-item__qty {
          min-width: 110px;
        }
        &-item__alert {
          @include text-styles($font-medium, 0.875rem, 1.375rem, -0.3px);
          color: $color-error-red;
          margin: 0;
          padding-top: 0.9375rem;
        }
        &__list {
          @include breakpoint(desktop-small) {
            padding-right: 20px;
            margin-right: 20px;
          }
          &::-webkit-scrollbar {
            @include breakpoint(desktop-small) {
              scrollbar-width: thin;
              width: 5px;
              background-color: $light-gray;
            }
          }
          &::-webkit-scrollbar-thumb {
            @include breakpoint(desktop-small) {
              background-color: $dark-gray;
            }
          }
          &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 5px $light-gray;
            background-color: $light-gray;
          }
        }

        &__subtotal {
          margin-bottom: 1.25rem;
        }
        &__subtotal--label {
          @include text-styles($font-bold, 1.125rem, 1.625rem, -0.35px);
          color: $color-slate;
          font-weight: 500;
        }
        &__subtotal--value {
          @include text-styles($font-bold, 1.125rem, 1.625rem, -0.35px);
          color: $color-slate;
        }
        &__cta {
          .button {
            line-height: 1.625rem;
            height: 50px;
            margin-right: 0;
            @include text-styles($font-medium, 18px, 26px, -0.3px);
            width: 100%;
            font-weight: 500;
          }
        }
      }
    }
  }
}

.combo-preview-item {
  padding-left: 30px;
  &__note {
    display: inline-block;
    padding-bottom: 20px;
    color: $color-charcoal;
    font-family: $font-medium;
    font-size: 16px;
    letter-spacing: -0.35px;
    line-height: 24px;
  }
  .preview-cart-item {
    padding-top: 0;
    border-top: none;
  }
}

.preview-bundle-product-message {
  @include text-styles($font-medium, 14px, 22px, -0.3px);
  color: $color-charcoal;
  margin-top: 10px;
  @include breakpoint(mobile-landscape) {
    position: absolute;
    right: 0;
    top: 35px;
  }
}
.cart-bundle-discount-message {
  @include text-styles($font-medium, 14px, 22px, -0.3px);
  color: $color-charcoal;
  @include breakpoint(mobile-landscape) {
    display: inline-block;
  }
}
.bundles-checkout-message {
  @include text-styles($font-medium, 14px, 22px, -0.3px);
  color: $color-charcoal;
  margin: 15px 0 20px 0;
}
