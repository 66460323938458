@import "@/styles/base/colors";

.utilityWidgetsWrapper {
  :global {
    .utility-widgets {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: row;

      &__brand-switcher {
        margin-left: 0;
        border-left: 1px solid $color-slate;
        min-width: 118px;
      }
      .wishlist-widget {
        a {
          display: inline-block;
        }
        img {
          height: 20px;
          margin-top: 0px;
        }
      }
      .auth-widget {
        padding-left: 20px;
        display: flex;
        align-items: end;
      }
      .wishlist-widget {
        display: flex;
        align-items: end;
        @media screen and (min-width: 992px) and (max-width: 1200px) {
          display: flex;
        }
      }
      .auth-widget {
        margin-top: -2px;
        @media only screen and (max-width: 900px) and (orientation: landscape),
          only screen and (min-width: 768px) and (max-width: 991px) {
          &__menu {
            padding: 11px 32px;
          }
        }
        @media only screen and (max-width: 767px) {
          &__menu {
            padding: 11px 18px;
          }
        }
      }
      .cart-indicator-widget {
        display: flex;
        margin-top: 0;
        padding-left: 15px;
        @media screen and (min-width: 992px) and (max-width: 1200px) {
          display: flex !important;
        }
        .cart-indicator {
          display: flex;
          align-items: end;
          &.cart-indicator__with-count {
            .cart-indicator__count {
              img {
                margin-top: 0;
              }
            }
          }
        }
      }

      .cart-indicator-widget {
        .cart-indicator {
          .gbh-data-layer {
            img {
              margin-top: 2px;
            }
          }
        }
      }
    }

    @media only screen and (max-width: 900px) and (orientation: landscape),
      only screen and (min-width: 768px) and (max-width: 991px),
      only screen and (max-width: 767px) {
      .utility-widgets {
        .search-widget {
          display: flex;
          align-items: flex-end;
        }
      }
    }
  }
}
