.finding-model-number {
  .modal-popup .modal-popup-content {
    width: 500px;
    overflow-y: auto;
    .modal-popup-body {
      padding: 70px 40px 50px;
    }
    text-align: left;

    .close-button {
      right: 14px;
      top: 12px;
    }

    .modal-popup-body {
      width: 100%;
      height: 100%;

      iframe {
        height: 100%;
        width: 100%;

        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 1px $color-medium-gray;
          border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background: $color-dark-gray;
          border-radius: 5px;
        }
      }
    }
  }
  .image-explanation {
    padding-bottom: 40px;
  }

  .title {
    font-family: $font-light;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -1.7px;
    color: $color-slate;
    padding-bottom: 39px;
  }

  &__content {
    margin: 0px -40px -50px;
    padding: 40px 40px 0;
  }
  &__content-bg {
    background: $color-off-white;
  }

  .description {
    font-size: 16px;
    color: $color-slate;
    line-height: 24px;
    letter-spacing: -0.55px;
    padding-bottom: 30px;

    a {
      color: $color-slate;
      text-decoration: underline;
    }
    ul {
      margin-left: 16px;
      padding-bottom: 20px;
      padding-top: 20px;
      li {
        padding-bottom: 10px;
        &::marker {
          font-size: 12px;
        }
      }
    }
  }

  .signUp-text {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    a.kf-react-button {
      width: 100%;
    }
  }
}
@media only screen and (max-width: 900px) and (orientation: landscape),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .finding-model-number {
    &__content {
      padding: 40px 32px 0;
    }
  }
}
@media only screen and (max-width: 767px) {
  .finding-model-number {
    .modal-popup .modal-popup-content {
      margin: 0px auto;
      .modal-popup-body {
        padding-left: 18px;
        padding-right: 18px;
      }
      height: auto;
      .close-button {
        top: 14px;
        right: 9px;
      }
    }
    &__content {
      padding: 40px 18px 0;
      margin: 0px -18px -50px;
    }
  }
}

@media only screen and (min-width: 992px) {
  .finding-model-number {
    .modal-popup .modal-popup-content {
      width: 500px;
    }
  }
}
