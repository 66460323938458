.create-folders {
  &__btn-wrapper {
    display: block;
  }

  &__submit {
    display: inline-block;
    padding: 0 36px;
    background-color: #364575;
    height: 40px;
    cursor: pointer;
    color: #fff;
    font-size: 13px;
    line-height: 40px;
    text-align: center;
    border-radius: 4px;
    border: none;
    text-transform: uppercase;
  }

  &__cancel {
    display: inline-block;
    padding: 0 36px;
    background-color: #fff;
    height: 40px;
    cursor: pointer;
    color: #232323;
    font-size: 13px;
    line-height: 40px;
    text-align: center;
    border-radius: 4px;
    border: none;
    text-transform: uppercase;
  }

  &__text {
    margin: 40px 0 60px 0;
  }

  .form {
    &__group {
      textarea {
        border-radius: 4px;
        background: #fff;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 12px;
        border: solid 1px #ddd;
        display: block;
        width: 100%;
        clear: both;
        outline: none;
        height: 160px;
        resize: none;
      }
    }
  }
}

@media (min-width: 768px) {
  .create-folders {
    .form {
      &__group {
        textarea {
          width: 83.33333%;
          display: inline-block;
        }

        label {
          width: 16.66667%;
          display: inline-block;
          text-align: right;
          padding: 0 20px 0 0;
        }
      }

      &__input[type="text"] {
        width: 83.33333%;
        display: inline-block;
      }
    }

    &__submit,
    &__cancel {
      padding: 0 58px;
    }
  }
}
