.added-to-cart {
  .pop-up {
    &__header {
      border-bottom: none;
      margin-bottom: 27px;
    }

    &__content {
      max-width: 800px;
      padding: 50px 10px;
    }

    &__body {
      padding: 2px 0;
    }
  }

  &__cart-details {
    .cart-details {
      &__data {
        padding: 0 15px;
      }

      &__confirm-total {
        display: inline-block;
        width: 45%;
        margin-left: auto;
      }

      &__mobile-counter {
        left: 0;

        &--plus {
          left: unset;
        }
      }

      &__tbody {
        &__data {
          &--qnty-status {
            display: none;
          }

          &--qnty-input {
            display: inline-block;
            width: 55%;
          }

          &--remove-cta,
          &--status,
          &--total,
          &--shipping {
            display: none;
          }

          &--cart-summary {
            display: block;
          }

          &--cta-wrapper {
            display: block;
          }
        }

        &-value {
          &--total {
            display: block;
            margin-bottom: 23px;
          }
        }
      }

      &__qnty-label {
        display: none;
      }

      &__product-action--confirm-cta {
        display: block;
        margin-bottom: 10px;
      }

      &__cart-total {
        width: 100%;
        height: 40px;
      }

      &__tax-msg {
        height: 40px;
      }
    }
  }

  &__no-item {
    border-top: 1px solid #e8e8e8;
    padding: 25px;

    .cart-details {
      &__cta-shopping {
        max-width: 250px;
        display: inline-block;
      }
    }
  }
}

@media (min-width: 980px) {
  .added-to-cart {
    .pop-up {
      &__content {
        padding: 25px 34px;
      }
    }

    &__cart-details {
      .shopping-cart__view {
        .cart-details {
          &__data {
            padding: 0px;
          }

          &__confirm-total {
            display: none;
          }

          &__thead__data {
            width: 25%;

            &--status {
              display: none;
            }

            &:first-child {
              width: 50%;
            }

            &--total {
              width: 16.66667%;
            }

            &--qnty {
              width: 8.33333%;
              padding-left: 0px;
            }
          }

          &__tbody__data {
            width: 25%;

            &:first-child {
              width: 50%;
            }

            &--cart-summary {
              width: 100%;
            }

            &--cta-wrapper {
              display: block;
              width: 100%;
              float: left;
            }

            &--qnty {
              width: 8.33333%;
              padding-left: 0px;
            }

            &--total {
              display: block;
              width: 16.66667%;
            }
          }

          &__cta-view-cart {
            width: 200px;
            float: right;
          }

          &__cta-shopping {
            width: 200px;
            float: right;
            margin-right: 30px;
          }
        }
      }
    }
  }
}
