.shared-cart {
  .cart-item {
    &__label {
      &.--quantity {
        font-family: $font-bold;
        color: $color-charcoal;
        @media screen and (max-width: 480px) {
          text-align: center;
        }
        @media screen and (min-width: 480px) {
          margin-left: 0;
        }
      }
      &.--mobile {
        @media screen and (max-width: 480px) {
          text-align: unset;
        }
      }
    }
    &__price {
      font-family: $font-bold;
    }
    &__image {
      object-fit: contain;
    }
  }
}
