@import "@/styles/base/_colors";
.overlayWrapper {
  :global {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: black;
    z-index: 500;
    opacity: 0.5;
  }
}
