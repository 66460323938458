.icon {
  &:before {
    display: inline-block;
    font-family: "Icons";
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    padding: 0 10px;
  }

  &--close {
    &:before {
      content: "\e602";
    }
  }

  &--bookmark {
    &:before {
      content: "\e604";
    }
  }
}
