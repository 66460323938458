.add-an-installation {
  padding: 40px 0px;
  border-top: 1px solid $color-dark-gray;

  &__title {
    margin-bottom: 25px;
    font-family: $font-light;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -1.2px;
    color: $color-charcoal;
  }
  &__zipcode-availability {
    padding-top: 20px;
    display: flex;
    align-items: center;
    .input-field {
      margin-bottom: 0px;
    }
    .button {
      margin-left: 15px;
    }
    .kf-react-button.ghost {
      color: $color-slate;
    }
  }

  &__eligible-service {
    p {
      margin-bottom: 0px;
    }
    &-txt {
      font-family: $font-medium;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.4px;
      color: $color-charcoal;
      display: inline-block;
    }

    &-edit {
      font-family: $font-regular;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.55px;
      color: $color-slate;
      text-decoration: underline;
      padding-left: 10px;
      cursor: pointer;
      display: inline-block;
    }

    &-message {
      margin-top: 10px;
      font-family: $font-regular;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.55px;
      color: $color-dark-gray;
      p,
      h1,
      h2,
      h3 {
        font-family: $font-regular;
        display: inline;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.55px;
        color: $color-dark-gray;
      }
      a {
        text-decoration: underline;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.55px;
        color: $color-dark-gray;
        padding-left: 3px;
      }
    }
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    margin-top: 33px;
    align-items: end;
  }
  &__button-tab {
    .button {
      &.ghost {
        color: $color-slate;
      }
      &:last-child {
        margin-left: 10px;
      }
    }
  }
  &__original-price {
    font-family: $font-light;
    font-size: 26px;
    line-height: 34px;
    letter-spacing: -1.5px;
    color: $color-slate;
    display: block;
  }
  &__discount-price {
    display: flex;
    align-items: center;
    span {
      font-size: 16px;
      font-family: $font-regular;
      line-height: 22px;
      letter-spacing: -0.8px;
      color: $color-dark-gray;
      text-decoration: line-through;
    }
  }
  &__zipcode-error {
    margin-top: 10px;
    font-family: $font-regular;
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: -0.3px;
    color: $color-error-red;
  }
  &__zipcode-input-error .input-field__border {
    border-color: $color-error-red;
  }
  &__zipcode {
    font-family: $font-medium;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.4px;
    color: $color-charcoal;
    padding-left: 5px;
    display: inline-block;
  }
  &__percent-discount {
    font-family: $font-medium;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.65px;
    color: $color-slate;
    margin-left: 15px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .add-an-installation {
    &__button-tab {
      .button {
        &.small {
          padding: 8px 15px;
        }
      }
    }
  }
}
@media only screen and (max-width: 900px) and (orientation: landscape),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .add-an-installation {
    padding: 28px 0px 40px;
    &__footer {
      margin-top: 25px;
    }
    &__zipcode-availability {
      justify-content: space-between;
    }
  }
}
@media only screen and (max-width: 767px) {
  .add-an-installation {
    padding: 28px 0px 40px;
    &__footer {
      display: block;
      margin-top: 25px;
    }
    &__price {
      margin-bottom: 25px;
    }
    &__button-tab {
      display: flex;
      justify-content: space-between;
    }
    &__zipcode {
      padding-left: 0px;
    }
    &__eligible-service-txt {
      display: block;
    }
    &__zipcode-availability .input-field__border {
      display: grid;
    }
  }
}
