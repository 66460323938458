@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";

.PartDiagramViewer {
  margin: 0;
  padding: 0 !important;
  touch-action: pan-y;
  height: 100%;

  z-index: 99999999;
  @media screen and (min-width: 813px) {
    height: auto;
    overflow: hidden !important;
  }
  :global {
    #svg-viewer-iframe {
      width: 80vw;
      height: 80vh;
      border: none;
      margin-top: 20px;
      @media screen and (max-width: 767px) {
        width: 90vw;
      }
    }

    div.modal-dialog {
      min-width: 100%;
      margin: 0px;
      min-height: 100%;
      div.modal-content {
        border: none;
        border-radius: 0px;
      }
    }
    .partviewer-modal-container.part-h-115 {
      button.close-btns {
        position: absolute;
        right: 24px;
        top: 13px;
      }
      .parts-cart-wrapper {
        top: 15px;
        right: 55px;
      }
    }
    div.partviewer-modal-container {
      width: 100%;
      height: 100dvh;

      button.close-btns {
        position: absolute;
        right: 48px;
        top: 26px;
        z-index: 2;
        cursor: pointer;
        border: none;
        background: transparent;
        @media screen and (max-width: 1024px) {
          right: 16px;
          top: 10px;
        }
      }

      .parts-cart-wrapper {
        position: absolute;
        height: 50px;
        top: 27px;
        right: 82px;
        z-index: 1;
        @media screen and (max-width: 1024px) {
          top: 12px;
          right: 46px;
        }
      }
      div.modal-popup {
        div.modal-popup-body {
          padding: 0px !important;
          height: 100%;
        }
        div.modal-popup-content {
          @media (min-width: 813px) {
            overflow-y: hidden;
          }
        }
      }
      div.modal-popup.show-modal-popup.part-diagram-custom {
        height: 100%;
      }
      div.modal-popup-content {
        max-height: 100%;
        min-width: 100%;
        min-height: 100%;
      }

      div.modal_panel {
        width: 100%;
        top: 78px;
        gap: 0px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 1025px) {
          flex-grow: inherit;
          flex-direction: column;
          align-items: self-start;
          justify-content: space-between;
        }
      }

      span.sku-title {
        font-size: 24px;
        margin-right: 10px;
        @media (min-width: 376px) and (max-width: 1024px) {
          font-size: 22px;
          margin-right: 5px;
        }
        @media (max-width: 376px) {
          font-size: 20px;
          margin-right: 5px;
        }
        font-weight: 400;
        line-height: 30px;
        text-align: left;
        color: $slate;
      }
      div.image-container {
        display: flex;
        align-items: center;
        & img {
          object-fit: contain;
        }
      }

      div.modal_header {
        & div.sku-part {
          @media (max-width: 1025px) {
            margin-left: 25px;
          }
          @media (min-width: 376px) and (max-width: 812px) {
            margin-left: 32px;
            width: 70%;
          }
          @media (max-width: 376px) {
            margin-left: 18px;
            width: 66%;
          }
        }
      }

      .sku {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
        color: $dark-gray;
      }
      div.image-container img {
        display: none;
        @media (min-width: 1025px) {
          display: inline;
        }
      }
      div.modal_header {
        @media (max-width: 812px) {
          width: 80%;
        }

        @media (max-width: 376px) {
          width: 70%;
        }
      }
      div.header-content {
        @media (max-width: 812px) {
          width: 100%;
        }
      }

      div.flex-item {
        white-space: nowrap;
        overflow: scroll;
      }

      div.flex-item::-webkit-scrollbar {
        display: none;
      }

      span.header-icons {
        cursor: pointer;
        display: inline-block;
        padding: 8px 20px;
        border-radius: 20px;
        background: $half-white;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: $dark-gray;
        @media (max-width: 812px) {
          margin-right: 10px;
        }
      }
      span.activeTab {
        color: $charcoal;
        background: $light-white;
      }
      div.print-share-div {
        visibility: hidden;
      }
      .header-img img {
        margin: 4px;
        padding-bottom: 10px;
      }
      div.modal-container {
        width: 100%;
        border-top: $light-white 1px solid;
        border-bottom: $light-white 1px solid;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (min-width: 813px) and (max-width: 1025px) {
          height: 115px;
          padding: 5px 0px 15px 0px;
        }

        @media (min-width: 376px) and (max-width: 813px) {
          height: 115px;
          padding: 5px 0px 15px 0px;
          border: none;
        }

        @media (max-width: 376px) {
          border: none;
          height: auto;
          margin: 10px 0px;
        }
      }
    }

    div.modal_content {
      div.mobile-view {
        visibility: hidden;
        @media (max-width: 812px) {
          visibility: visible;
        }
      }

      .mobile-tabs {
        display: flex;
        align-items: center;
        justify-self: center;
        height: 100vh;
        height: calc(
          100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom)
        );
        height: 100dvh;
        background: $white;
        .tab-content,
        .tab-diagram {
          @media (max-width: 1024px) {
            width: 100%;
          }
          height: 100%;
          z-index: 1;
          overflow-y: auto;
        }
        ul.nav {
          width: 100%;
          height: 60px;
          display: flex;
          border-bottom: 1px solid $dark-gray;
          align-items: center;
          justify-content: center;
          padding: 11px 18px;
          gap: 0px;
        }
        .active-tabs {
          height: 4px;
          background-color: $slate;
        }
        .nav-item button {
          border: none;
          background-color: transparent;
          color: $dark-gray;
          font-size: 18px;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: -0.5px;
          text-align: center;

          & .active-tabs {
            height: 4px;
            background-color: $slate;
          }
        }
        & .nav-tabs {
          border: none;
          margin: 0px;
          position: sticky;
          top: 0px;
          & a {
            width: 50%;
            text-align: center;
            background: $black;
            color: $dark-gray;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            border-color: transparent;
            &:hover {
              border-color: transparent;
              margin: 0;
            }
          }

          & button.active {
            background: transparent;
            border-color: transparent;
            color: $slate;
            position: relative;
            &:after {
              content: "";
              width: auto;
              height: 4px;
              background: $slate;
              position: absolute;
              bottom: -7px;
              left: 0%;
              right: 0%;
              z-index: 1;
            }
          }
        }
      }
    }
    .product-list-viewer {
      padding: 20px;
      background: $white;
      overflow: auto;
      & .dropdown-menu {
        width: 207px;
        text-align: right;
        font-size: 16px;
        inset: 10px 0px auto auto;
        border: none;
        box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1),
          0px 0px 35px rgba(0, 0, 0, 0.15);

        & .radio__custom {
          border: 2px solid $dark-gray;
          width: 20px;
          height: 20px;
        }

        .radio__input:checked + .radio__custom span:nth-child(even)::after {
          background: $charcoal;
          transform: translate(-3%, -2%);
        }
        .radio__input:checked + .radio__custom::after {
          background: $charcoal;
          transform: translate(-3%, 2%);
        }
        & .dropdown-menu li:focus,
        .dropdown-menu li:active,
        .dropdown-item:active {
          background: $white;
          color: $black;
        }
        & .dropdown-item {
          color: $dark-gray;
        }
        & .sort-by {
          background: $half-white;
        }
        @media (max-width: 768px) {
          & .sort-by {
            color: $black;
            background-color: transparent;
          }
        }
      }
      & .sorting-btn {
        border: none;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 148%;
        text-decoration: none;
        color: $slate;
        cursor: default;
      }
      & .sorting-btn:focus {
        box-shadow: none;
        outline: none;
        text-decoration: none;
      }
      & .sorting-btn:hover {
        background: transparent;
        color: $black;
        text-decoration: none;
      }
      & .sorting-btn:after {
        display: none;
      }
      & .sort-by {
        background: $half-white;
      }
      @media (max-width: 813px) {
        & .sort-by {
          color: $black;
          background-color: transparent;
        }
      }
      & .btn-group.btn-group-toggle {
        @media (max-width: 812px) {
          margin-left: 12px;
        }
        & label {
          border: 1px solid $medium-light-gray;
          display: flex;
          align-items: center;
          padding: 0;
          width: 32px;
          height: 32px;
          justify-content: center;
        }
        & label.active {
          background: $white;
          & svg path {
            fill: $dark-blue;
          }
          @media (min-width: 1025px) {
            z-index: 0;
          }
        }
      }
    }

    .list-items {
      & .card {
        border: none;
        border-radius: 0;
        &.list-section {
          padding: 10px 0;
        }
        &:hover {
          cursor: pointer;
        }

        & .card-bodys {
          padding: 0px;
          & .model-number {
            color: $slate;
            min-width: 90px;
            @media (max-width: 1919px) {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              text-overflow: ellipsis;
              font-weight: 400;
              line-height: 22px;
              letter-spacing: -0.36px;
              text-align: left;
            }
            @media (min-width: 376px) and (max-width: 812px) {
              font-size: 18px;
            }
            @media (max-width: 376px) {
              font-size: 16px;
            }
          }
          & .model-price {
            color: $black;
          }
          & .viewdetails {
            border-color: $spanish-gray;
            border-radius: 20px;
            background: $white;
            color: $dark-gray;
          }
        }
      }
    }

    .popup-default {
      position: fixed;
      right: 0;
      bottom: 0;
      top: 81px;

      background: $white;
      max-width: 603px;
      width: 100%;
      z-index: 9999;

      @media (max-width: 1024px) {
        max-width: 362px;
        top: 115.5px;
      }

      @media (max-width: 812px) {
        max-width: 100%;
        top: 0px;
      }
      @media (min-width: 1025px) and (max-width: 1919px) {
        width: 49.5%;
      }

      & button.btn.closebtns {
        background: $white;
        border: 1px solid $white;
        border-radius: 5px;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
        width: 40px;
        height: 40px;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: auto;

        &:hover {
          background: $white;
          border: 1px solid $white;
        }
      }

      & .viewdetails {
        border-color: $medium-light-gray;
        border-radius: 20px;
        background: $white;
        color: $teal;

        &:hover {
          background: $white;
          border-color: $medium-light-gray;
        }
      }
      & button {
        background: $teal;
        border: 1px solid $teal;
        border-radius: 20px;
        height: 50px;
        color: $white;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 138%;
        min-width: 124px;
        &:hover {
          border-color: $teal;
        }
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      & .main-image img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .popup-wrapper {
      padding: 0px;
      overflow: auto;
      position: absolute;
      height: 100%;
      width: 100%;
      background: $white;
    }
    button.expand-button {
      border: 1px solid $medium-light-gray;
      border-radius: 4px;
      background: $white;
      width: 32px;
      height: 32px;
      padding: 0px;
      margin: 0px -8px;
    }
    button.load-more-button {
      height: 40px;
      gap: 10px;

      background: $white;
      border: 1px solid $slate;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: -0.36px;
      text-align: center;
    }

    .loading-animation {
      position: relative;
      width: max-content;
      background: $white;
      color: $white;
      &::after {
        content: " ";
        width: calc(100% + 1px);
        height: calc(100% + 1px);
        background-size: 1200px 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: 0px;
        background-color: $alice-blue;
        background-image: linear-gradient(
          to right,
          $alice-blue 0%,
          $solitude-blue 20%,
          $alice-blue 40%,
          $alice-blue 100%
        );
        background-repeat: no-repeat;
        -webkit-animation-duration: 1s;
        -webkit-animation-fill-mode: forwards;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-name: placeholderShimmer;
        -webkit-animation-timing-function: linear;
      }
      &.large::after {
        width: calc(100% + 6px);
        height: calc(100% + 6px);
        top: -3px;
        left: -3px;
      }
      & .loading-spinner {
        z-index: 100;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        & svg {
          width: 24px;
        }
      }
    }
    span.thumb-size.loading-animation {
      margin-bottom: 10px;
    }
    .load-widgets.border-active {
      border-color: $soft-peach;
    }

    .mobile-dropdown {
      .dropdown-menu {
        position: fixed;
        z-index: 99;
        transform: translateY(50vh);
        transition: all 0.5s ease-in-out;
        inset: 0;
        top: inherit;
        @media (max-width: 767px) {
          & a:nth-child(odd) > * {
            .radio__input:checked + .radio__custom::after {
              background: $charcoal;
              transform: translate(-3%, -2%);
            }
          }
        }

        & .sort-by {
          color: $black;
          background-color: transparent;
        }
      }
    }
    .mobile-dropdown.show {
      a.close-icon {
        display: flex;
        align-items: center;
        justify-content: right;
      }
      a.dash-icon.dropdown-item {
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          background: transparent;
        }
      }
      .dash-bar {
        width: 40px;
        height: 4px;
        background: $medium-light-gray;
        position: relative;
        border-radius: 120px;
      }

      .black-dash {
        padding: 21px 121px 8px 121px;
      }
      .black-dash-bar {
        width: 133px;
        height: 5px;
        border-radius: 100px;
        background: $black;
      }

      .dropdown-menu {
        text-align: left;
        font-size: 16px;
        transform: translateY(0vh);
        height: 175px;
        width: 100%;
        background: $white;
        top: initial;
      }
      .mobile-overlay {
        position: fixed;
        background: rgb(0 0 0 / 75%);
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
      }
    }

    .sort-by-title {
      font-style: normal;
      padding: 10px 11px 20px 18px;
      color: $slate;
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: -0.5px;
      text-align: left;
    }
    .mobile-viewer {
      & .popup-default {
        max-width: 100%;
        height: 100%;
        top: inherit;
      }
      & .modal-body {
        overflow-y: inherit;
      }
    }

    .mobile-popup {
      transform: translateY(100vh);
      -webkit-transform: translateY(100vh);
      -moz-transform: translateY(100vh);
      transition: all 0.5s ease-in-out;
      -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      left: 0px;
      position: fixed;
      right: 0px;
      bottom: 0;
      top: 0px;
      z-index: 99;
    }
    .mobile-popup.active {
      transform: translateY(0vh);
      -webkit-transform: translateY(0vh);
      -moz-transform: translateY(0vh);
      z-index: 9999;
    }
    .main-image {
      aspect-ratio: 1;
      margin: 0 auto 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .loading-spinner {
      & svg {
        -webkit-animation: rotating 2s linear infinite;
        -moz-animation: rotating 2s linear infinite;
        -ms-animation: rotating 2s linear infinite;
        -o-animation: rotating 2s linear infinite;
        animation: rotating 2s linear infinite;
        width: 56px;
      }
    }

    .thumbnail-items {
      .loading-spinner {
        & svg {
          -webkit-animation: rotating 2s linear infinite;
          -moz-animation: rotating 2s linear infinite;
          -ms-animation: rotating 2s linear infinite;
          -o-animation: rotating 2s linear infinite;
          animation: rotating 2s linear infinite;
          width: 56px;
        }
      }
      & .loading-animation {
        & .loading-spinner {
          & svg {
            width: 56px;
            @media (min-width: 1919px) {
              width: 56px;
            }
            @media (min-width: 768px) and (max-width: 1279px) {
              width: 64px;
            }
            @media (max-width: 1280px) {
              width: 40px;
            }
            @media (max-width: 769px) {
              width: 40px;
            }
          }
        }
      }
    }

    .fullscreen-extend.right + .popup-default {
      box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
    }

    .card-grid.empty-card {
      height: 0px;
      padding: 0;
      margin: 0px;
      &:hover {
        border-color: transparent;
      }
    }

    .right {
      & .expand-button {
        & svg path {
          fill: $haiti;
        }
      }
    }
    .thumb-size.loading-animation,
    .thumb-list.loading-animation {
      position: relative;
      & img {
        visibility: hidden;
        overflow: hidden;
      }
    }

    .load-widgets {
      margin: 0 0 10px;
      & .loading-number {
        width: 90px;
        height: 17px;
      }
      & .loading-text {
        width: 149px;
        @media (min-width: 1919px) {
          width: 157px;
        }
        height: 17px;
      }
      & .loading-price {
        width: 53px;
        height: 9px;
        margin-top: 12px;
        margin-bottom: 20px;
      }
    }

    .list-view-icons {
      & svg {
        & path {
          fill: $dark-gray;
        }
      }
    }
    .load-more {
      margin: 0px 0px 30px;
    }

    .grid-btn .tool-tip-custom.bottom {
      right: -30px;
    }

    .thumbnail-items {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      & .card {
        border-radius: 0;
        border: 1px solid transparent;
        flex-grow: 0;
        padding: 0px;
        flex-basis: max(49%, 251px);
        @media (max-width: 1919px) {
          flex-basis: min(48.72%, 157px);
        }
        @media (max-width: 1919px) {
          flex-basis: min(49.72%, 260px);
        }
        @media (max-width: 812px) {
          flex-basis: max(49%, 336px);
        }
        @media (max-width: 767px) {
          flex-basis: min(48.72%, 166px);
        }
        aspect-ratio: 1;
        height: auto;
        & .thumb-size {
          margin-bottom: 7px;
        }
      }
    }
    .button-groups {
      margin-left: 30px;
    }
    .thumbnail-items.full-screens {
      & .card {
        & .buttons {
          visibility: hidden;
        }
        &:hover {
          cursor: pointer;

          & .buttons {
            visibility: visible;
          }
        }
      }
    }

    .list-items .border-active {
      border-left: none;
      border-right: none;
      border-bottom: 1px solid $silver-white;
    }

    .card.border-active:first-child {
      border-top: 1px solid $silver-white;
    }
    .thumb-size {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      padding: 0px;
      & img {
        object-fit: contain;
      }
    }
    .thumb-list {
      max-width: 64px;
      width: 100%;
      height: 64px;
      margin-right: 12px;
      background: rgba(204, 204, 204, 0.19);
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      & img {
        filter: contrast(0.92);
        width: 100%;
      }
    }
    p.model-name {
      color: $dark-gray;
    }
    p.model-name-list {
      color: $slate;
      font-size: 18px;
    }
    p.model-name.list-items {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 47px;
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: -0.5px;
      text-align: left;
      color: $slate;
    }
    .list-items {
      width: 100%;
    }
    .button-groups {
      margin-left: 30px;
    }
    .thumbnail-items.full-screens {
      & .card {
        & .buttons {
          visibility: hidden;
        }
        &:hover {
          & .buttons {
            visibility: visible;
          }
        }
      }
    }

    .thumb-list {
      max-width: 64px;
      width: 100%;
      height: 64px;
      margin-right: 12px;
      background: rgba(204, 204, 204, 0.19);
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      & img {
        filter: contrast(0.92);
        width: 100%;
      }
    }
    .thumbnail-items p.model-name {
      color: $dark-gray;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 47px;
    }
    .list-items {
      width: 100%;
    }

    .fade.bg-color.show.tooltip.bs-tooltip-start {
      opacity: 1;
      div.tooltip-inner {
        background-color: blue !important;
        margin-right: 8px;
        color: $white;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 19px;
        border-color: $color-slate;
        opacity: 1;
      }
      .bottom {
        margin-top: 8px;
      }
    }

    .color-variants {
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      & .variant-buttons {
        overflow: hidden;
        margin-right: 10px;
        @media (max-width: 768px) {
          margin-right: 5.21px;
        }

        margin-top: 4px;
        border-radius: 4px;
        border: 1px solid $white;
        cursor: pointer;
        & img {
          width: 78px;

          height: 35px;
          border-radius: 4px;
          margin: 0px;
        }
      }
      & .active {
        border: 1px solid $dark-gray;
        border-radius: 4px;
        & img {
          padding: 3px;
        }
      }
    }

    .add-counter {
      border: 1px solid $dark-gray;
      border-radius: 8px;
      width: 109px;
      overflow: hidden;
      margin-right: 15px;
      height: 51px;
      & .btn-group {
        display: flex;
        & button {
          height: 50px;
          min-width: auto;
          border-radius: 0;
          background: $white;
          border: none;
          color: $dark-gray;
          svg path {
            fill: $dark-gray;
          }
          &:hover {
            background: $color-background;
          }
        }
        & .disabled:hover {
          background: transparent;
        }
        & .text-counter {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 148%;
          width: 60px;
          padding: 0px;
          color: $black;
          &:hover {
            background: transparent;
          }
        }
      }
    }
    .button-carts {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      & button {
        width: 100%;
        height: 50px;
        font-size: 18px;
        background: $primary-blue;
        border: none;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: -0.35px;
        padding: 0;
        border-radius: 0px;
      }
      & .processing-div {
        width: 100%;

        & .processing-text {
          display: flex;
          align-items: center;
          justify-content: center;
          visibility: hidden;
          margin-bottom: 10px;
          & .text-part {
            color: $dark-gray;
            text-align: justify;
          }
        }
      }
      & .processing {
        & .processing-text {
          visibility: visible;
        }
      }
    }
    .detailpop-up {
      & .stock-button {
        height: 50px;
        background: $silver-white;
        color: $spanish-gray;
        border: none;
        width: 100%;
        padding: 11.5px 30px;
      }
    }

    .viewdetails {
      cursor: pointer;
      &:active:focus {
        box-shadow: none;
        border-color: $teal;
      }
      .add-to-cart {
        cursor: pointer;
        background-color: $teal;
        border-color: $teal;
        color: $white;
      }
    }
    .text-color {
      color: $dark-gray;
      & span {
        color: $black;
      }
    }

    .loading-popup {
      & .loading-animation {
        height: 24px;
      }
      & .loading-thumb {
        max-width: 100%;
        width: 100%;
        min-height: 520px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      & .loading-buttons {
        margin-right: 11px;
        margin-top: 12px;
        width: 78px;
        height: 35px;
        background: $silver-white;
        border-radius: 4px;
        float: left;
      }
      & .loading-details {
        width: 100%;
        height: 50px;
        border-radius: 581px;
        overflow: hidden;
        margin-top: 13px;
      }
      & .h-16 {
        height: 16px;
      }
    }

    .product-description {
      color: $spanish-gray;
    }
    .modal-container {
      @media (min-width: 1023px) {
        position: sticky;
        top: 0px;
        z-index: 1;
        background: white;
      }
    }
    .button-carts {
      & .add-to-cart.disabled,
      & .viewdetails.disabled {
        cursor: no-drop;
      }
    }

    .tag-discontinue {
      padding: 0 6px;
      display: inline-block;
      font-size: 13px;
    }
    .error-message {
      text-align: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      height: 100%;
      & .line-first {
        color: $slate;
        font-size: 45px;
        line-height: 56px;
        letter-spacing: -3.1875px;
      }
      & .questions {
        font-family: $font-regular;
        color: $dark-gray;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.55px;
        & a {
          color: $dark-gray;
          text-decoration-line: underline;
        }
      }
    }
    .icon-api-error {
      width: 189px;
      height: 190px;

      @media (max-width: 1280px) {
        width: 167px;
        height: 168px;
      }

      @media (max-width: 768px) {
        width: 155px;
        height: 155px;
      }
    }
    .text-section {
      width: 545px;
      margin-top: 120px;
    }
    .line-first {
      color: var(--text-black, $black);
      font-size: 62px;
      font-style: normal;
      font-weight: 325;
      line-height: 68px;
    }
    .questions {
      color: $black;
      font-size: 16px;
      font-style: normal;
      font-weight: 325;
      line-height: normal;
      padding-top: 15px;
    }

    .questions a:hover {
      color: $black;
    }

    @media (max-width: 1280px) {
      .error-message {
        width: 100%;
        height: 100%;
        background: $white;
      }
      .line-first {
        font-size: 40px;
        font-weight: 300;
        line-height: 42px;
      }
      .text-section {
        width: 550px;
        margin-top: 120px;
      }
      .questions {
        padding-top: 30px;
      }
    }

    @media (max-width: 812.5px) {
      .error-message {
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        background: $white;
        & .line-first {
          font-size: 28px;
          font-weight: 325;
          line-height: 36px;
          letter-spacing: -1.98px;
        }
      }
      .text-section {
        width: 340px;
        margin-top: 40px;
        text-align: center;
      }
      .questions {
        padding-top: 20px;
      }
    }

    @media (max-width: 480px) {
      .error-message {
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding-top: 30px;
        background: $white;
      }

      .line-first {
        font-size: 26px;
        font-weight: 300;
        line-height: 32px;
      }
      .text-section {
        width: 339px;
        max-width: 100%;
        text-align: center;
      }
      .questions {
        padding-top: 16px;
      }
    }
    .mobile-viewer .parts-modal-wrapper h6.fallback-heading {
      margin-bottom: 64px;
    }

    .fallback-wrapper {
      @media (max-width: 1280px) {
        height: 100vh;
        height: 100dvh;
      }
    }

    .viewer-controls {
      bottom: 75px;
      right: 30px;
      @media (max-width: 1025px) {
        bottom: 50px;
        right: 31px;
      }
      @media (max-width: 812px) {
        bottom: 40px;
        right: 32px;
      }
      @media (max-width: 765px) {
        bottom: 40px;
        right: 18px;
      }

      gap: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      & .tool-tip-custom {
        right: 40px;
        margin-right: 13px;
        height: auto;
        transform: none;
        & span {
          border-radius: 4px;
          color: $white;
          font-size: 13px;
          font-weight: 400;
          height: 27px;
          display: flex;
          align-items: center;
          padding: 0 8px;
        }
      }

      button.acitve:hover svg path {
        fill: $teal;
      }
      button.acitve {
        &:focus,
        &:active {
          background: $gray-3;
        }
      }

      button {
        width: 40px;
        height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: 0px solid transparent;
        background: $eigen-blue;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 4px;

        &:active {
          background: $gray-3;
          & svg path {
            fill: $robin-blue;
          }
        }
        &:hover svg path {
          fill: $white;
        }
      }

      & .groups-buttons {
        background: $eigen-blue;
        border-radius: 4px;
        & button:first-child {
          border-bottom: 1px solid $gray-4c;
        }
      }
    }

    .image-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
    }
    .message-box {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 10px 10px 0px 14px;
    }
    #messageBox {
      position: fixed;
      right: 50%;
      bottom: 50%;
    }
    .part-not-found {
      background: $gray-4c;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 4px 8px;
      gap: 8px;
    }
    .part {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      text-align: center;
      text-transform: uppercase;
    }
    .contact {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 19px;
      text-align: left;
      color: $dark-gray;
      padding: 8px 24px;
    }

    @media (max-width: 767px) {
      .contact {
        font-size: 16px;
        line-height: 24px;
        padding: 8px 23px;
      }
    }
    .svg-section {
      position: relative;
    }
    svg {
      text {
        font-family: Helvetica, Arial, Helvetica, sans-serif;
      }
      g[kohler_illustration\:hotspot_list] text {
        fill: $pale-blue;
        font-weight: 700;
      }
      text[kohler_illustration\:hotspot_list] {
        fill: $pale-blue;
        font-weight: 700;
      }

      g.active,
      g.active *[stroke]:not([stroke="none"]) {
        stroke: $light-teal;
      }
      g.active {
        text {
          fill: $light-teal;
        }
      }
      text.active {
        fill: $light-teal;
      }
    }

    .mobile-controls {
      & button.acitve {
        & svg path {
          fill: $robin-blue;
        }
      }
      button {
        width: 40px;
        height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: 0px solid transparent;
        background: $black;
        box-shadow: -1px 3px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        &:active {
          background: $resene-gray;
        }
        & svg {
          width: auto;
          height: auto;
          & path {
            fill: $white;
          }
        }
      }
    }

    .btn-expand,
    .color-variants {
      position: relative;
      &:hover {
        .tool-tip-custom {
          display: flex;
        }
      }
      & .bottom {
        top: 30px;
        right: -19px;
      }
      & .tool-tip-custom {
        z-index: 1;
        position: absolute;
        width: 95px;
        height: 52px;
        display: none;
        align-items: center;
        justify-content: center;
        text-align: center;
        box-shadow: 0px 0px 20px 0px $shadow-gray;
        background: $white;
        &.grid-list-view {
          transform: translate(3px, 10px);
        }
        transform: translate(-20px, 10px);
        & span {
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: -0.36px;
          text-align: center;
          color: $dark-gray;
          margin: auto;
        }
      }
    }
    .react-transform-component {
      position: relative;
    }
    .popup-window {
      position: absolute;
      background: $white;
      width: 237px;
      flex-direction: column;
      display: flex;
      z-index: 99;
      padding: 18px 15px 15px 15px;
      border-radius: 5px;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
      & .header-popups {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & .title-notfound {
          font-family: $font-medium;
          background: $slate;
          color: $white;
          font-size: 10px;
          line-height: 10px;
          letter-spacing: 0.5px;
          text-align: left;
          font-weight: 500;
          padding: 7px 8px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        & .button-close {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: end;
          border: none;
          background: transparent;
          cursor: pointer;
          & svg {
            width: 17px;
            height: 17px;
            & path {
              fill: $dark-gray;
            }
          }
        }
      }
      & .content-popups {
        color: $dark-gray;
        margin-top: 13px;
        font-family: $font-regular;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.3px;
        text-align: left;
      }
      @media (max-width: 768px) {
        & .content-popups {
          font-size: 16px;
          max-width: 180px;
        }
      }
    }

    .selection {
      & .tabs-title {
        font-style: normal;
        font-weight: 300;
        color: $white;
        font-size: 16px;
        line-height: normal;
        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
      & .active {
        & .tabs-title {
          font-weight: 400;
          color: $black;
        }
      }
    }

    .mobile-viewer {
      & .modal-header {
        & .title {
          @media (max-width: 1023px) {
            height: 150px;
            position: relative;
            & button {
              position: absolute;
              right: 23px;
              top: 63px;
            }
          }
        }
        & .part-name {
          display: flex;
          @media (max-width: 767px) {
            flex-direction: column;
          }
          & .part-sku {
            padding: 4px 0;
          }
        }
      }
      & .parts-modal-wrapper h6 {
        @media (max-width: 768px) {
          min-height: 120px;
        }
      }

      & .mobile-tabs {
        & .nav-tabs a {
          margin-bottom: 0px;
        }
        & .nav-tabs a.active {
          height: 44px;
        }
      }
    }

    div.header-content {
      @media (min-width: 1025px) {
        margin-right: 5px;
      }
      @media (min-width: 813px) and (max-width: 1025px) {
        margin-right: 0px;
        margin-top: 15px;
      }
      @media (min-width: 376px) and (max-width: 812px) {
        margin-top: 15px;
        margin-left: 0px;
      }
      @media (max-width: 376px) {
        margin-top: 15px;
      }
      & .coach-mark {
        background: $slate;
        width: 183px;
        height: 86px;
        border-radius: 4px;
        color: $white;
        position: absolute;
        white-space: pre-wrap;
        display: flex;
        @media (max-width: 376px) {
          top: 130px;
          left: 86px;
        }
        & span {
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: -0.3px;
          white-space: pre-wrap;
          padding: 10px 0px 10px 10px;
          text-align: left;
        }
        & span::before {
          content: "Can't find what \A  you're looking for? \A  Try another diagram";
          white-space: pre-line;
        }
      }
      button.close-button-coach {
        background: transparent;
        border: none;
        padding: 0;
        min-width: auto;
        color: $white;
        box-shadow: none;
        width: 30px;
        height: 30px;
        padding-left: 15px;

        & svg path {
          fill: $white;
        }
      }

      button.close-button-coach:hover {
        background: transparent;
      }

      button.close-button-coach:after {
        content: "";
        position: absolute;
        border-top: 10px solid $transparent;
        border-bottom: 10px solid $transparent;

        @media (min-width: 1025px) {
          border-left: 10px solid $slate;
          left: 84px;
          top: -11px;
          transform: rotate(-90deg);
        }

        @media (max-width: 1025px) {
          border-right: 10px solid $slate;
          border-left: none;
          left: 84px;
          top: -11px;
          transform: rotate(90deg);
        }
        @media (max-width: 813px) {
          left: 84px;
          top: -11px;
          transform: rotate(90deg);
        }
      }
    }
    .parts-modal-wrapper.fullscreen-viewer {
      z-index: 9999;
      position: fixed;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      .mobile-tabs {
        height: calc(
          100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom)
        );
      }
    }
    .parts-modal-wrapper {
      flex: 1;
      flex-basis: auto;
      .fullscreen-extend.fullscreen-right {
        width: 100%;
        max-width: 100%;
        position: relative;
        @media (min-width: 1919px) {
          max-width: 100%;
        }
        .product-list-viewer {
          & .expand-button {
            & svg path {
              fill: $dark-blue;
            }
          }
        }
        .thumbnail-items {
          & .card {
            @media (min-width: 1920px) {
              flex-basis: min(30%, 443px);
            }
            @media (min-width: 1025px) and (max-width: 1920px) {
              flex-basis: min(30%, 375px);
            }

            @media (min-width: 813px) and (max-width: 1025px) {
              flex-basis: min(24.3%, 339px);
            }
            flex-basis: min(30%, 339px);
            border-radius: 0;
            border: 1px solid transparent;
          }
        }
      }
    }

    div.fullscreen-viewer div.left.fullscreen-extend {
      position: fixed;
      width: 100%;
      left: 0;
      right: 0;
      top: 0;
      background: $white;
      bottom: 0;
      z-index: 1001;
      border-right: 1px solid #e9e6e6;
    }

    div.partviewer-modal-container {
      .close-button {
        position: absolute;
        width: 17px;
        height: 30px;
        background-image: url("data:image/svg+xml,%3Csvg width='17' height='30' viewBox='0 0 17 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.79376 15.4999L0.369141 22.9245L1.07625 23.6316L8.50087 16.207L15.9255 23.6316L16.6326 22.9245L9.20798 15.4999L16.6326 8.07527L15.9255 7.36816L8.50087 14.7928L1.07625 7.36816L0.369141 8.07527L7.79376 15.4999Z' fill='%23494949'/%3E%3Cpath d='M7.79376 15.4999L0.369141 22.9245L1.07625 23.6316L8.50087 16.207L15.9255 23.6316L16.6326 22.9245L9.20798 15.4999L16.6326 8.07527L15.9255 7.36816L8.50087 14.7928L1.07625 7.36816L0.369141 8.07527L7.79376 15.4999Z' fill='%23494949'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat no-repeat;
        background-position: center center;
        background-size: cover;
        img {
          opacity: 0;
          @media (min-width: 1023px) {
            position: fixed;
          }
        }
      }
    }
    .tag-discontinue {
      color: $white;
      display: flex;
      align-items: center;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      height: 24px;
    }
    .tag-discontinue.discontinued {
      width: 97px;
      background: $slate;
    }
    .tag-discontinue.outof-stoke {
      width: 98px;
      background: $white;
      color: $charcoal;
      border: 1px solid $medium-gray;
      padding: 0px;
      justify-content: center;
      display: flex;
      align-items: center;
    }

    .mavenoid-suppress {
      z-index: 1000 !important;
    }

    .partviewer-modal-container.loading-text .modal-popup-body {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .spinner-img {
      img {
        width: 100px;
        height: 100px;
        @media (max-width: 1024px) {
          width: 80px;
          height: 80px;
        }

        @media (max-width: 380px) {
          width: 70px;
          height: 70px;
        }
      }
    }

    div.add-coach {
      overflow: auto;
      scrollbar-width: none;
      white-space: nowrap;
      padding: 0 0px;
      @media (max-width: 1024px) {
        padding: 0 25px;
      }
    }
    div.sku-part {
      text-overflow: ellipsis;
      overflow: hidden;
      scrollbar-width: none;
      white-space: nowrap;
    }

    div.section-title-file {
      overflow: hidden;
    }

    .h-80 {
      @media (min-width: 1025px) {
        height: 80px;
        & img.image-part {
          height: 80px;
          width: 80px;
        }
        div.modal_header {
          position: relative;
          top: 7px;
          .sku-title {
            font-size: 24px;
          }
          & span.sku {
            vertical-align: middle;
          }
          div.sku-part {
            margin-left: 30px;
          }
        }
      }
    }
    .h-115 {
      @media (min-width: 1025px) {
        height: 115px;
        div.section-title-file {
          padding: 14px 0px 0px;
        }
        & div.header-content {
          margin: 15px 25px;
        }
        div.sku-part {
          margin-left: 25px;
          & span.sku-title {
            font-size: 22px;
            margin-right: 5px;
          }
        }
        & img.image-part {
          height: 113px;
          width: 113px;
        }
      }
    }

    .check-modal {
      overflow: hidden;
    }

    div.add-coach {
      & span.file-tab {
        margin-right: 10px;
      }
      & span.file-tab:last-child {
        margin-right: 0px;
      }
    }
    .partviewer-modal-container {
      .modal-popup-body {
        display: flex;
        flex-direction: column;
      }
      .parts-modal-wrapper {
        width: 100%;
      }
    }

    .load-widgets {
      & .shimmer-loading__image {
        margin-bottom: 10px;
      }
      .width-full {
        .loading-part {
          height: 14px;
          border-radius: 20px;
        }
        .width-20 {
          width: 20px;
          border-radius: 4px;
          margin-right: 5px;
        }
        .width-16 {
          width: 16px;
        }
        .width-191 {
          width: 191px;
        }
        .width-102 {
          width: 102px;
        }
        .width-15 {
          width: 25px;
          border-radius: 10px;
        }
      }
    }

    div.fullscreen-right {
      width: 100%;
      overflow-y: auto;
      border-left: 1px solid $light-white;
      position: relative;
      ::-webkit-scrollbar {
        display: none;
      }

      @media (min-width: 1025px) and (max-width: 1600px) {
        max-width: 40%;
      }
      @media (min-width: 1601px) {
        max-width: 604px;
      }
      @media (max-width: 540px) {
        max-width: 100%;
      }
      @media (max-width: 1024px) {
        max-width: 363px;
      }
      @media (max-width: 812px) {
        max-width: 100%;
        border-left: none;
      }
      .product-list-viewer {
        padding: 20px;
        @media (min-width: 1025px) {
          padding: 10px 50px 50px 30px;
        }
        @media (max-width: 1024px) {
          padding: 10px 26px 40px 18px;
        }
        @media (max-width: 812px) {
          padding: 0px 32px 60px 32px;
        }
        @media (max-width: 376px) {
          padding: 0px 18px 60px 18px;
        }
        background: $white;
        overflow: auto;
      }

      .heading-grid {
        color: $charcoal;
        font-size: 40px;
        font-weight: 325;
        line-height: 46px;
        letter-spacing: -2.5px;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (min-width: 1025px) {
          margin: 30px 0px;
        }

        @media (max-width: 1025px) {
          font-size: 30px;
          line-height: 36px;
          letter-spacing: -1.77px;
          margin: 25px 0px 20px;
        }
      }
      .list-view-icons {
        background-color: $white;
        & svg {
          & path {
            fill: $dark-gray;
          }
        }
      }

      & .total-count {
        color: $slate;
        font-size: 20px;
        font-weight: 325;
        line-height: 26px;
        letter-spacing: -1.15px;
        text-align: left;
        padding: 20px 0px 15px;
      }
      & .full-screen-view {
        padding: 40px 0px 30px;
      }
      & .small-screen-view {
        padding: 20px 0px 15px;
      }

      & .dropdown {
        margin: 0px;
        z-index: 9;
      }
      & .sorting-btn {
        border: none;
        font-style: normal;
        font-weight: 400;
        font-size: 19px;
        line-height: 26px;
        letter-spacing: -0.5px;
        text-align: left;
        padding: 20px 0px 15px;
        pointer-events: none;
        display: flex;
        cursor: default;
        & span {
          vertical-align: middle;
        }
        & span,
        & svg,
        .list-btn,
        .grid-btn {
          cursor: pointer;
          pointer-events: auto;
        }
        & img {
          margin-left: 17px;
        }
        & svg {
          margin-left: 10px;
        }
      }
      & .sorting-btn:focus {
        box-shadow: none;
        outline: none;
      }
      & .sorting-btn:hover {
        background: transparent;
        color: $black;
      }
      & .sorting-btn:after {
        display: none;
      }
      .list-items {
        width: 100%;
      }
      .thumb-list {
        max-width: 64px;
        width: 100%;
        height: 64px;
        margin-right: 15px;
        background: rgba(204, 204, 204, 0.19);
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        & img {
          filter: contrast(0.92);
          width: 100%;
        }
      }
      .card-product-title {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.4px;
        text-align: left;
        overflow: hidden;
        @media (max-width: 812px) and (min-width: 376px) {
          font-size: 18px;
        }
      }
      .sku_product-description {
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.3px;
        text-align: left;
        color: $dark-gray;
        @media (max-width: 812px) and (min-width: 376px) {
          font-size: 14px;
        }
      }
      .sku-price {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: -0.3px;
        text-align: left;
        color: $slate;
        @media (max-width: 812px) and (min-width: 376px) {
          font-size: 16px;
        }
      }
      .discount-price {
        color: $dark-gray;
        text-decoration: line-through;
      }

      .sku_product {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.3px;
        text-align: right;
        color: $dark-gray;
      }
    }

    div.fullscreen-extend.fullscreen-right {
      & .product-list-viewer {
        & .card-bodys.grid {
          & .model-number {
            font-size: 18px;
          }
          & .sku_product-description {
            font-size: 14px;
          }

          & .sku-price {
            font-size: 16px;
          }
          @media (min-width: 1025px) {
            & .total-count-sort {
              padding: 40px 0px 30px 0px;
            }
            & .heading-grid {
              margin: 30px 0px 20px;
            }

            & .total-count {
              font-size: 26px;
            }
          }
        }
        @media (min-width: 1025px) {
          padding: 20px 50px 50px 50px;
          & .thumbnail-items {
            & .card {
              margin-bottom: 60px;
            }
          }
        }

        @media (max-width: 1024px) {
          padding: 20px 26px 60px 26px;
          & .total-count-sort {
            padding: 40px 0px 30px 0px;
          }
          & .total-count {
            font-size: 26px;
          }
        }
      }
    }
    .parts-modal-wrapper {
      padding: 0px;
      display: flex;
      flex-direction: row;
      gap: 0px;
      align-items: center;
      justify-content: center;
      @media (min-width: 1025px) {
        height: calc(100vh - 102px);
      }
      @media (min-width: 813px) and (max-width: 1024px) {
        height: calc(100vh - 100px);
      }
      overflow: hidden;

      @media (min-width: 1919px) {
        & .popup-default {
          max-width: 603px;
        }
      }

      .left {
        width: 0%;
      }

      .left.fullscreen-extend {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        background-color: $white;

        & > div.react-transform-wrapper,
        & > div > div.react-transform-component {
          width: 100%;
          height: 100%;
        }
      }
    }
    .menu-dropdown {
      margin: -23px 0px auto auto;
      @media (min-width: 1025px) {
        margin: -23px -75px auto auto;
      }
    }

    div.fullscreen-right {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
    div.back-to-top {
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: -0.5px;
      text-align: left;
      align-self: self-end;
      color: $charcoal;
      @media (max-width: 812px) {
        margin-bottom: 75px;
      }
      cursor: pointer;
      & button {
        border: none;
        background-color: transparent;
      }
    }

    .list-fullwidth {
      @media (min-width: 1025px) {
        width: 35%;
        justify-content: end;
      }
      @media (max-width: 1025px) {
        width: 40%;
        justify-content: space-between;
      }
    }
    div.model-prices {
      & .span {
        font-size: 14px;
      }
    }
    div.tablet-view-padding {
      height: 90px;
    }

    @media (min-width: 767px) and (max-width: 813px) {
      div.tablet-view-padding {
        padding-top: 17px;
      }
      .model-name-price {
        display: flex;
        justify-content: end;
        width: 50%;
      }
      .model-prices {
        display: flex;
        justify-content: end;
      }
    }

    .product-information {
      height: 160px;
    }

    .thumbnail-items {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      & .card {
        display: block;
        border-radius: 0;
        border: 1px solid transparent;
        flex-grow: 0;
        padding: 0px;
        margin-bottom: 40px;
        flex-basis: min(48.72%, 157px);

        @media (min-width: 1281px) {
          flex-basis: min(49.72%, 257px);
        }
        @media (min-width: 2000px) {
          flex-basis: min(48.72%, 250px);
        }
        @media (min-width: 768px) and (max-width: 812px) {
          flex-basis: min(48%, 350px);
        }
        @media (min-width: 560px) and (max-width: 768px) {
          flex-basis: min(49%, 280px);
        }
        @media (max-width: 560px) {
          flex-basis: min(48.72%, 166px);
        }

        aspect-ratio: 1;
        height: auto;
        & .thumb-size {
          overflow: hidden;
          height: 254px;
          max-width: 254px;
          margin-bottom: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          white-space: no-wrap;
          padding: 0px;
          @media (max-width: 1281px) {
            overflow: hidden;
            height: 210px;
            width: 210px;
          }

          @media (max-width: 1280px) {
            overflow: hidden;
            height: 152px;
            width: 152px;
          }
          @media (min-width: 768px) and (max-width: 812px) {
            height: 366px;
            width: 366px;
            overflow: hidden;
          }

          @media (min-width: 560px) and (max-width: 768px) {
            height: 254px;
            width: 254px;
            overflow: hidden;
          }

          @media (max-width: 560px) {
            overflow: hidden;
            height: 161px;
            width: 161px;
          }
        }
        & .card-bodys {
          min-height: 100px;
          padding: 0px;
        }

        & img.card-img-top {
          width: 100%;
          height: 100%;
          max-width: 100%;
          flex-shrink: 1;
        }
      }
      div.card.empty-card {
        max-height: 0px;
        margin: 0px;
        padding: 0px;
        height: 0px;
        width: 100%;
      }
    }
    .button-groups {
      margin-left: 30px;
    }
    .thumbnail-items.full-screens {
      & .card {
        & .buttons {
          visibility: hidden;
        }
        &:hover {
          & .buttons {
            visibility: visible;
          }
        }
      }
    }

    .fullscreen-extend.fullscreen-right {
      .thumbnail-items {
        & .card {
          flex-basis: min(24.3%, 339px);
          border-radius: 0;
          border: 1px solid transparent;
          & .thumb-size.grid-fullwidth {
            overflow: hidden;

            @media (min-width: 1919px) {
              height: 443px;
              width: 443px;
            }
            @media (max-width: 1281px) {
              height: 355px;
              width: 355px;
            }

            @media (min-width: 1023px) and (max-width: 1281px) {
              width: 231px;
              height: 231px;
            }
            @media (min-width: 812px) and (max-width: 1023px) {
              width: 157px;
              height: 157px;
            }
          }
        }
      }
    }

    div.color-variants.list-color {
      margin: 0px;
      @media (max-width: 376px) {
        display: block;
      }

      & label {
        margin-right: 0px;
        display: inline-block;
        margin-bottom: 5px;
      }
      & .label-discontinued {
        display: block;
        padding: 5px 7px;
      }
    }
    div.color-variants {
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &.color-parts {
        @media (min-width: 812px) {
          margin: 0px 0px 5px;
        }
      }
      & .active {
        border: 1px solid $dark-gray;
        border-radius: 4px;
        & img {
          padding: 3px;
        }
      }
      & .other_color img {
        border-radius: 4px;
        margin-right: 5px;
        cursor: pointer;
      }
      & .other_color img:hover {
        padding: 3px;
        border: 1px solid $medium-gray;
        transition: all 0.3s ease-in-out;
      }
      & .extra-color {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.4px;
        text-align: left;
        color: $dark-gray;
      }
    }

    div.list-section .thumb-list {
      max-width: 60px;
      width: 100%;
      height: 60px;
      margin-right: 15px;
      background: rgba(204, 204, 204, 0.19);
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      & img {
        object-fit: contain;
        filter: contrast(0.92);
        width: 100%;
      }
    }
    div.list-section .load-more {
      margin: 40px 0px 30px;
    }
    div.list-section button.load-more-button {
      width: 524px;
      height: 40px;
      padding: 9px 30px 9px 30px;
      gap: 10px;
      background: $white;
      border-top: 1px solid $slate;
    }

    .list-items {
      width: 100%;
    }
    .product-info-details {
      padding-bottom: 20px;
      white-space: nowrap;
    }
    .sku_product-title {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -0.25px;
      text-align: left;
      color: $dark-gray;
    }
    .sku_info {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -0.25px;
      text-align: left;
      color: $dark-gray;
    }
    .sku-price {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: -0.3px;
      text-align: left;
    }
    .loading-spinner {
      width: auto;
      height: auto;
      border: none;
    }

    .loading-spinner::after {
      content: "";
      position: absolute;
      z-index: 1;
      background-image: url("data:image/svg+xml,%3Csvg width='56' height='56' viewBox='0 0 60 60' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Ccircle cx='30' cy='30' r='28' stroke='url(%23paint0_linear_952_180)' strokeWidth='4' /%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_952_180' x1='54.1411' y1='49.3794' x2='9.49243' y2='8.51453' gradientUnits='userSpaceOnUse' %3E%3Cstop stopColor='%231AAA89' /%3E%3Cstop offset='0.237209' stopColor='%2308A1A9' /%3E%3Cstop offset='0.508047' stopColor='%231E80B7' /%3E%3Cstop offset='0.784094' stopColor='%23693A92' /%3E%3Cstop offset='1' stopColor='%23D81081' /%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E ");
      background-repeat: no-repeat no-repeat;
      background-position: center center;
      background-size: cover;
    }
    .swatch-list.changeInfo {
      .color-swatch {
        position: relative;
      }
      .color-swatch input[type="radio"] {
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        cursor: pointer;
      }
    }

    .swatch-list
      div[aria-checked="true"]
      .color-swatch
      input[type="radio"]
      + label {
      display: inline-block;
      background-clip: content-box;
      padding: 3px;
      width: 100%;
      border-radius: 4px;
      cursor: pointer;
      border: solid 1px $dark-gray;
      margin-bottom: 0px;
    }
    div.viewer-controls {
      button.close-button-coach:after {
        content: "";
        position: absolute;
        border-top: 10px solid $transparent;
        border-bottom: 10px solid $transparent;
        border-left: 10px solid $slate;
        right: -6px;
        top: 12px;
      }
      .coach-mark {
        background: $slate;
        border-radius: 5px;
        width: 163px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 5px 0px 12px;
        color: $white;
        position: absolute;
        right: 52px;
        & span {
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: -0.36px;
          text-align: left;
        }
      }
      button.close-button-coach {
        background: transparent;
        border: none;
        padding: 0;
        min-width: auto;
        color: $white;
        box-shadow: none;
        width: 30px;
        height: 30px;
        padding-left: 15px;

        & svg path {
          fill: $white;
        }
      }
    }

    button.list.add-to-cart {
      width: 67px;
      height: 40px;
      gap: 10px;
      background: $white;
      border: 1px solid $primary-blue;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: -0.3px;
      text-align: center;
      color: $primary-blue;
      padding: 0px 19px;
    }
    button.add-to-cart {
      display: none;
    }
    div.list-details-column {
      @media (max-width: 1024px) {
        flex-direction: column;
      }
    }
    .sku-name {
      color: $dark-gray;
      font-size: 14px;
      line-height: 22px;
    }
    .model-name-price {
      color: $slate;
      font-size: 14px;
      line-height: 22px;
    }

    div.list-details {
      @media (min-width: 1025px) {
        & .model-prices {
          margin-left: 10px;
          font-size: 14px;
        }
      }
      @media (max-width: 1024px) {
        & .model-name {
          flex-direction: row;
        }
      }
      @media (max-width: 812px) {
        & .model-name {
          flex-direction: row;
        }
        & .model-prices {
          margin-left: 20px;
        }
      }
      @media (max-width: 376px) {
        & .model-name {
          flex-direction: column;
        }
        & .model-prices {
          margin-top: 5px;
          padding: 0px;
        }
      }
    }

    .label-discontinued {
      display: block;
      padding: 0px 7px;
    }

    .title-center-align {
      @media (min-width: 1025px) {
        margin-top: 10px;
      }
    }

    .content-dialogs {
      position: relative;

      .header-fixed {
        position: sticky;
        margin-left: 18px;
        top: 18px;
        z-index: 1;
        height: 0px;
        @media (min-width: 1025px) {
          margin-left: 30px;
          top: 30px;
        }

        @media (min-width: 812px) {
          margin-left: 32px;
          top: 32px;
        }
        @media (max-width: 1025px) {
          margin-left: 18px;
          top: 18px;
        }
      }

      .counter-widgets {
        width: 109px;
        height: 50px;
        display: flex;
        padding: 10px 18px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        border: 1px solid $dark-gray;
        background: $white;
        margin-right: 20px;
        input {
          color: $slate;
          text-align: center;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: -0.5px;
          width: 45px;
          display: flex;
          align-items: center;
          border: none;
          outline: none;
          justify-content: center;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type="number"] {
          -moz-appearance: textfield;
        }
        button.increment-widgets,
        button.decrement-widgets {
          background: transparent;
          width: 14px;
          min-width: auto;
          border: none;
        }
      }

      h2.label-name {
        font-size: 28px;
        font-style: normal;
        font-weight: 325;
        line-height: 36px;
        letter-spacing: -1.983px;
      }

      .varient-text {
        margin: 10px 0 30px;
        color: $slate;
        font-size: 14px;
        font-style: normal;
        line-height: 22px;
        letter-spacing: -0.3px;
      }
      .price-text {
        color: $slate;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: -0.9px;
      }
      .price-text.discount-price {
        color: $dark-gray;
        text-decoration: line-through;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.25px;
        padding-top: 5px;
      }
      .pricelist-text {
        color: $dark-gray;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.25px;
        padding-top: 5px;
        padding-bottom: 30px;
        span.list-text {
          position: relative;
          span {
            text-decoration: underline;
            cursor: pointer;
          }

          .list-description {
            display: none;
            border-radius: 5px;
            background: $white;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
            width: 243px;
            padding: 15px 20px;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            color: $dark-gray;
            line-height: 22px;
            text-decoration: none;
            position: absolute;
            left: 5px;
            top: 24px;
            z-index: 99;
          }
          span:hover + span.list-description {
            display: block;
          }
        }
      }

      .text-color {
        span {
          color: $slate;
        }
        color: $dark-gray;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.35px;
        margin-bottom: 15px;
      }
      .swatch-list.d-flex {
        width: 100%;
        margin-bottom: 30px;
        display: grid;
        grid-template-columns: repeat(auto-fit, 47px);
        grid-gap: 10px;
        flex-wrap: wrap;
        div[role="radio"] {
          width: 47px;
          height: 27px;
          overflow: hidden;
        }
        .color-swatch {
          label {
            height: 25px;
          }
        }
      }
      .product-flag {
        display: flex;
        width: 41px;
        height: 24px;
        padding: 5px 8px;
        justify-content: center;
        align-items: center;
        color: $charcoal;
        border: 1px solid $medium-gray;
        background: $white;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 10px; /* 100% */
        letter-spacing: 0.5px;
      }
    }
    .inventory-warning {
      color: red;
      position: absolute;
      top: -26px;
      font-size: 13px;
    }

    .notify-heading {
      color: $slate;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.35px;
      margin-bottom: 10px;
    }

    .notify-details {
      color: $dark-gray;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.45px;
    }

    .email-inputbox {
      // border-radius: 4px;
      // border: 1px solid $dark-gray;
      // background: $white;

      // overflow: hidden;
      margin: 20px 0 10px;
      .input-field__border {
        border: 1px solid $dark-gray;
        height: 50px;
      }
    }
    .email-inputbox {
      input::placeholder,
      label {
        color: $dark-gray;
        font-weight: 400;
        font-size: 14px;
        background: $white;
        line-height: 16px;
      }

      .Input_inputFieldWrapper__nt6o0
        .input-field__value
        input:placeholder-shown
        + label {
        transform: translate(0, 70%) scale(1);
        font-size: 14px;
      }
      .Input_inputFieldWrapper__nt6o0
        .input-field__value
        input:not(:placeholder-shown)
        + label,
      .Input_inputFieldWrapper__nt6o0 .input-field__value input:focus + label {
        font-size: 10px;
        transform: translate(5px, 8px) scale(1);
        max-width: max-content;
        padding: 0px 10px 0;
      }

      input {
        color: $slate;
        font-size: 14px;
        font-style: normal;

        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.3px;
        width: 100%;
        border: none;
        padding: 4px 15px 8px;
      }
    }
    .notify-button.disabled-btn {
      background: $light-gray;
      cursor: no-drop;
    }

    .cart-toast-text {
      background: $primary-blue;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 15px 18px;
      @media (max-width: 813px) {
        position: fixed;
      }
      max-height: 82px;
      p {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: -0.5px;
        color: $white;
        @media (min-width: 1024px) {
          width: auto;
        }
        width: 80%;
      }

      button {
        background: transparent;
        border: none;
        color: $white;
        min-width: 50px;
        height: auto;
      }
    }
    .flag-container {
      min-height: 25px;
    }
    .text-container {
      padding: 0px 50px 0px 30px;
      @media (max-width: 1024px) {
        padding: 0px 25px 0px 18px;
      }
      @media (max-width: 812px) {
        padding: 0px 32px 30px;
      }
      @media (max-width: 812px) {
        padding: 0px 18px 30px;
      }
    }
    .fw-500 {
      font-weight: 500;
    }
    .mb-30 {
      margin-bottom: 30px;
    }
    .counter-details,
    .addtocart-details {
      height: 60px;
      width: 100%;
      margin-top: 30px;
    }
    .counter-details {
      max-width: 150px;
      margin-right: 10px;
    }
    .popup-default.loading-popup {
      padding: 32px;
      @media (max-width: 1024px) {
        padding: 18px;
      }
    }
    .addtocart-widgets {
      margin-bottom: 30px;
    }
    .notify-contianer {
      margin-bottom: 30px;
    }

    .cart-indicator.v2 img {
      font-size: 15px;
      line-height: 15px;
    }
    .cart-indicator.v2 span {
      margin-left: 9px;
      top: 0px;
      width: fit-content;
      min-width: 1em;
      left: 0px;
      text-align: center;
      padding: 0px 10px 2px 0px;
    }

    .cart-indicator.v2 .cart-indicator__count {
      font-size: 16px;
    }
    .cart-indicator.v2.light {
      .cart-indicator__count.count-single span {
        padding: 0px 1px 2px 0px;
      }
      .cart-indicator__count span {
        color: $white;
        background: $black;
      }
    }
    .cart-indicator.v2.dark {
      .cart-indicator__count.count-single span {
        padding: 0px 1px 2px 0px;
      }
      .cart-indicator__count span {
        color: $slate;
        background: transparent;
      }
    }
    div.fullscreen-viewer {
      div.fullscreen-extend.left {
        .viewer-controls {
          right: 50px;
          bottom: 30px;
          @media (max-width: 1025px) {
            right: 30px;
            bottom: 40px;
          }
          @media (max-width: 812px) {
            right: 32px;
            bottom: 40px;
          }
          @media (max-width: 768px) {
            right: 19px;
            bottom: 40px;
          }
        }
      }
    }

    div.fullscreen-extend.left {
      div.viewer-controls {
        div.tool-tip-custom {
          background: transparent;
          box-shadow: none;
          transform: none;
          margin-right: 0px;
          width: 90px;
          span {
            color: $white;
            background: $color-slate;
            font-size: 13px;
          }
        }
      }
    }

    div.partviewer-modal-container.part-h-80
      .Modal_modalWrapper__asWMh
      .modal-popup
      .modal-popup-content {
      & span.close-button {
        @media (min-width: 1025px) {
          position: fixed;
          top: 26px;
          right: 50px;
          z-index: 2;
        }
        @media (min-width: 813px) and (max-width: 1024px) {
          position: fixed;
          top: 16px;
          z-index: 2;
        }
        @media (min-width: 376px) and (max-width: 812px) {
          top: 15px;
        }
        @media (max-width: 376px) {
          top: 10px;
          right: 23px;
        }
        & img {
          width: 17px;
          height: 30px;
        }
      }
      .parts-cart-wrapper {
        position: absolute;
        top: 26px;
        right: 85px;
        @media (max-width: 1024.5px) {
          top: 16px;
          right: 60px;
        }
        @media (max-width: 812px) {
          top: 15px;
          right: 67px;
        }
        @media (max-width: 375.5px) {
          top: 11px;
          right: 40px;
          margin: 0px 15px;
        }
      }
    }
    div.partviewer-modal-container.part-h-115
      .Modal_modalWrapper__asWMh
      .modal-popup
      .modal-popup-content {
      & span.close-button {
        @media (min-width: 1025px) {
          position: fixed;
          top: 16px;
          right: 50px;
        }
        z-index: 2;
        @media (min-width: 813px) and (max-width: 1024px) {
          position: fixed;
          top: 16px;
          z-index: 2;
        }
        @media (min-width: 376px) and (max-width: 812px) {
          top: 15px;
        }
        @media (max-width: 376px) {
          top: 10px;
        }
        & img {
          width: 17px;
          height: 30px;
        }
      }
      .parts-cart-wrapper {
        position: absolute;
        top: 16px;
        right: 85px;
        @media (max-width: 1024.5px) {
          top: 16px;
          right: 60px;
        }
        @media (max-width: 812px) {
          top: 15px;
          right: 67px;
        }
        @media (max-width: 375.5px) {
          top: 10px;
          right: 54px;
          margin: 0px 15px;
        }
      }
    }
    .sort-label {
      cursor: pointer;
      & svg {
        margin-left: 10px;
      }
    }
    button.grid-btn,
    button.list-btn {
      background-color: transparent;
    }
    button.close-menu {
      border: none;
      background-color: transparent;
      padding: 10px 18px 20px 18px;
    }
    div.drop-down {
      position: relative;
      display: inline-block;
      & .mobile-dropdown {
        width: 100%;
        position: fixed;
        transition: all 0.5s ease-in-out;
        inset: auto 0px 0px;
        @media (max-width: 813px) {
          .radio__input:checked + .radio__custom::after {
            background: $charcoal;
            transform: translate(3%, 3%);
          }
        }
        @media (max-width: 767px) {
          & span:nth-child(odd) > * {
            .radio__input:checked + .radio__custom::after {
              background: $charcoal;
              transform: translate(3%, 3%);
            }
          }
        }
        @media (min-width: 767px) and (max-width: 812px) {
          & span:nth-child(even) > * {
            .radio__input:checked + .radio__custom::after {
              background: $charcoal;
              transform: translate(3%, -3%);
            }
          }
        }
      }
    }
    .dropdown-toggle {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.5px;
      text-align: left;
      padding: 20px 0px 15px;
    }
    .drop-down-menu {
      margin-top: 15px;
      padding-top: 17px;
      padding-bottom: 20px;
      z-index: 100;
      position: absolute;
      width: 190px;
      text-align: right;
      font-size: 16px;
      inset: 45px 0px auto auto;
      border: none;
      background-color: $white;
      box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1),
        0px 0px 35px rgba(0, 0, 0, 0.15);
      & span {
        display: inline-block;
      }
      & .drop-link {
        display: block;
        width: 100%;
        padding: 5px 25px 3px;
        clear: both;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.5px;
        text-align: right;
        white-space: nowrap;
        cursor: pointer;
        border: 0;
        @media (max-width: 813px) {
          text-align: left;
          font-size: 16px;
          padding: 0px 11px 15px 18px;
        }
      }
    }
    .dropdown-item:active {
      background: $white;
      color: $black;
    }
    & .dropdown-item {
      color: $dark-gray;
    }
    .mobile-overlay {
      position: fixed;
      background: rgb(0 0 0 / 75%);
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
    }
    .tag-discontinue-position {
      position: relative;
      top: 3px;
    }
  }
}
