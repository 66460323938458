@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";

.inPageSearchWrapper {
  :global {
    button.kf-react-button.large {
      font-size: 18px;
      letter-spacing: -0.35px;
      height: 60px;
    }
    button.kf-react-button.ghost {
      background-color: transparent;
      border: 1px solid #494949;
      color: #494949;
    }
    button.kf-react-button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      text-decoration: none;
      border: none;
      white-space: nowrap;
      padding: 0 30px;
      font-size: 18px;
      height: 57.56px;
      -webkit-transition: all 0.5s ease, background-color 1.5s ease;
      -moz-transition: all 0.5s ease, background-color 1.5s ease;
      -o-transition: all 0.5s ease, background-color 1.5s ease;
      transition: all 0.5s ease, background-color 1.5s ease;
      font-family: "Helvetica Now Text W05 Medium";
      line-height: 1.33;
      letter-spacing: -0.35px;
    }
    button.kf-react-button:hover {
      -webkit-transform: scale(0.95);
      transform: scale(0.95);
    }

    //   .inpage-search__search-control:placeholder-shown {
    //     text-overflow: ellipsis;
    //  }
    .show-visualsearch-wrapper {
      z-index: 1002;
    }
  }
}
