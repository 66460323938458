@import "@/styles/base/_fonts";
@import "@/styles/base/_colors";
@import "@/styles/mixins/_breakpoint";

.ChangePassword {
  width: 100%;
  height: 100vh;
  @media screen and (max-width: $ipadTab) {
    height: 100%;
    overflow-y: scroll;
  }
  display: flex;
  background-color: $color-white;
  @include breakpoint(tab-max) {
    padding-bottom: 50px;
  }
  :global {
    .wrapper {
      width: 454px;
      margin: 55px auto;
      padding: unset;
      @include breakpoint(mobile-potrait) {
        width: 339px;
      }
      .input-field {
        margin-bottom: 10px;
      }
      .header {
        margin-bottom: 30px;
        text-align: center;
        padding: 0;
        h2 {
          font-family: $helvetica-bold;
          text-transform: uppercase;
          text-align: center;
          font-size: 20px;
          letter-spacing: 1.818px;
          line-height: 22px;
          font-weight: 700;
          color: $slate;
          margin-bottom: 10px;
        }
        span {
          font-family: $helvetica-regular;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.45px;
          color: $slate;
          font-weight: 400;
        }
      }
      div {
        &.reset-button {
          line-height: 26px;

          button {
            height: 50px;
            width: 100%;
            margin-top: 20px;
          }
        }
      }
      .password-requirements {
        display: inline-block;
        margin-bottom: 20px;
        cursor: pointer;
        text-decoration: underline;
        font-family: "Helvetica Now Text W05 Regular";
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.45px;
        color: $color-slate;
        p {
          display: inline-block;
          cursor: pointer;
          text-decoration: underline;
          font-family: "Helvetica Now Text W05 Regular";
          font-size: 14px;
          line-height: 1.57;
          letter-spacing: -0.65px;
          color: $color-dark-gray;
        }

        &__tooltip--show {
          display: block;
          position: relative;
        }

        &__tooltip--hide {
          display: none;
        }

        &__tooltip-wrapper {
          .tooltip__container {
            @include breakpoint(mobile) {
              width: 200px;
            }
          }
          position: absolute;
          z-index: 9;
          @include breakpoint(mobile) {
            top: -15px;
            left: 130px;
          }
          top: -40px;
          left: 165px;
        }
      }
    }
  }
}
.ChangePassword_ChangePassword__EXQIE {
  .wrapper {
    .Button_buttonWrapper__9Vvy_ {
      .button.large {
        .kf-react-button.large {
          font-size: 18px;
          letter-spacing: -0.35px;
          height: 60px;
          margin-bottom: 100px;
        }
      }
    }
    .Input_inputFieldWrapper__nt6o0 {
      .input-field__value {
        input:placeholder-shown + label {
          transform: translateY(56%) scale(1);
        }
      }
    }
  }
}
div#kf-change-re-password-wrapper {
  margin-bottom: 30px;
}
