@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";
@import "@/styles/mixins/_breakpoint";

.CameraView {
  margin: 0;
  padding: 0;
  :global {
    background: $black;
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: min(100%, 100vh);
    z-index: 100;
    animation-name: fade;
    animation-duration: 1s;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    .cameraview-container {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $black;
      padding-top: 40px;
      @include breakpoint(mobile) {
        top: 12px;
      }
      @include breakpoint(desktop-small) {
        background: $white;
      }
    }
    video.video-container {
      max-width: 100%;
      height: 100%;
    }
    .back-button {
      position: fixed;
      top: 18.37px;
      left: 18.37px;
      z-index: 101;
      background: $transparent;
      outline: none;
      border: none;
    }
    .guide {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: calc(100% - 140px);
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 90%;
        height: 100%;
      }
    }
    .guide-text-wrapper {
      position: fixed;
      bottom: 160px;
      width: 100%;
      display: flex;
      left: 0;
      right: 0;
      justify-content: center;
    }
    .guide-text {
      width: max-content;
      color: $white;
      padding: 5px 10px;
      border-radius: 5px;
      background: #000000b2;
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: -0.5px;
      text-align: left;
    }
    .button-group {
      padding: 18px 32px;
      display: flex;
      justify-content: center;
      justify-content: space-between;
      position: fixed;
      width: 100%;
      height: 140px;
      bottom: 0px;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.7);
      button {
        border: none;
        background: $transparent;
      }
      .upload-input {
        width: 0px;
        height: 0px;
      }
      @include breakpoint(mobile) {
        padding: 0px 32px;
      }
    }
  }
}

@keyframes fade {
  from {
    background-color: $white;
  }
  to {
    background-color: $black;
  }
}
