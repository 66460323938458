@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";
@import "@/styles/mixins/_breakpoint";

.LoadingImage {
  margin: 0;
  padding: 0;
  :global {
    .fs-34 {
      font-size: 34px;
    }
    .image-loading-container {
      background: $half-white;
      min-width: 100%;
      position: fixed;
      height: 100%;
      z-index: 1000;
      top: 0;
      @include breakpoint(desktop-small) {
        min-width: auto;
        position: relative;
        height: 572px;
        z-index: 1;
        top: 0;
        margin: 30px auto 0px;
        border: 1px dashed $medium-gray;
      }
      @include breakpoint(tab-max) {
        margin: 30px 32px;
      }
      @include breakpoint(desktop-mid) {
        margin: 30px 32px;
      }
      @include breakpoint(desktop-mimi-max) {
        margin: auto;
      }
      .upload-image {
        .icon-upload {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 197px;
            height: 197px;
            margin: 65px;
            @include breakpoint(desktop-mimi-max) {
              width: 145px;
              height: 145px;
              margin: 48px;
            }
          }
          svg {
            animation: mymove 5s infinite;
            animation-timing-function: linear;
            position: relative;
          }
        }
        .loading-text {
          margin-top: 33px;
          color: $charcoal;
          font-style: normal;
          width: 382px;
          font-weight: 325;
          line-height: 42px;
          text-align: center;
          letter-spacing: -1.6px;
          @include breakpoint(mobile-potrait) {
            margin-top: 0px;
          }
        }
      }
    }
    @include breakpoint(desktop-mimi-max) {
      & .image-loading-container {
        height: 100%;
        border: none;
      }
    }
    .qrWrapper {
      padding: 50px 0px;
    }
  }
}
