.technical-specs {
  .product-listing__tile {
    padding-right: 0px !important;
    margin-bottom: 60px;
  }
  margin-top: 30px;
  .product-listing__controls {
    height: auto;
  }
  .sticky {
    .product-listing__controls {
      height: 34px;
    }
  }
  .product-listing__total {
    min-width: 266px;
  }
  &__list {
    &__title {
      font-family: $font-light;
      font-size: 60px;
      line-height: 68px;
      letter-spacing: -3.1px;
      color: $color-slate;
      margin-top: 10px;
      word-break: break-word;
      * {
        font-family: $font-light;
        font-size: 60px;
        line-height: 68px;
        letter-spacing: -3.1px;
        color: $color-slate;
        word-break: break-word;
      }
    }
  }
  &__search--mobile {
    margin: 20px 0px;
  }
  .lazyload-wrapper {
    min-height: auto;
  }
  .technical-specs__desc {
    border-left: 1px solid $color-dark-gray;
    padding-left: 30px;
  }
  .product-list {
    &__description {
      font-family: $font-light;
      font-size: 26px;
      line-height: 34px;
      letter-spacing: -1.5px;
      color: $color-slate;
      margin-top: 20px;
    }
    &__back-to-top {
      border-top: none;
      padding-top: 0px;
    }
  }
  &__search-wrap-parent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  &__search-wrap {
    display: flex;
    bottom: 0px;
    align-items: center;
    margin-top: 0;
    .product-list__total {
      position: initial;
      padding: 0px;
    }
  }
  &__search {
    width: 525px;
  }
  .filter__buying-guide {
    margin-bottom: 0px;
    padding-bottom: 0px;
    border-bottom: none;
  }

  .technical-tile__files {
    section {
      padding: 15px 0px;
      &:first-child {
        padding-top: 0px;
      }

      .checkbox-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        .checkbox__marker img {
          margin: 0px;
        }
        .checkbox {
          margin: 0px 30px 0px 0px;
          height: 20px;
          line-height: 1.5;
        }
        & > div {
          padding-top: 8px;
          padding-bottom: 8px;
          &:last-child {
            .checkbox {
              margin-right: 0px;
            }
          }
        }
      }
    }
  }
  .product-list__description {
    margin-top: 10px;
  }
  .product-list__header {
    margin-bottom: 20px;
  }
  .result-section {
    display: flex;
    padding-left: 0;
    padding-right: 0;
    .result {
      min-width: 237px;
    }
  }
  .filter-section {
    .product-list__controls {
      justify-content: flex-end;
    }
  }
  .roduct-list__sticky-nav-wrap {
    padding-bottom: 30px;
  }
  .product-list__controls .sort {
    margin-left: 30px;
    margin-right: 15px;
  }

  @media all and (min-width: 1024px) {
    &__banner {
      padding-right: 90px !important;
    }
  }

  .mobile-landscape-only {
    display: none;
  }
  .mobile-portrait-only {
    display: none;
  }
}

.search-dropdown {
  position: relative;
  &__field {
    height: 60px;
    display: flex;
    padding: 18px 15px;
    border-radius: 4px;
    border: solid 1px $color-dark-gray;
    &:focus-within {
      border: solid 1px $color-slate;
    }
  }
  .field-nav-sticky {
    background-color: $color-off-white;
    border: none;
    &:focus-within {
      border: solid 1px $color-slate;
    }
  }
  &__input {
    width: 100%;
    border: none;
    font-family: $font-regular;
    font-size: 18px;
    line-height: 1.44;
    letter-spacing: -0.7px;
    color: $color-slate;
    padding: 0px 10px;
  }
  .input-nav-sticky {
    background-color: $color-off-white;
    color: $color-charcoal;
  }
  &__search-icon {
    width: 18px;
    height: 18px;
  }
  &__close-icon {
    width: 16px;
    height: 16px;
  }
  &__dropdown {
    width: 100%;
    overflow: hidden;
    padding: 15px 0px;
    margin: 0px;
    position: absolute;
    z-index: 3;
    background-color: #fff;
    list-style: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
    transition: height 0.5s ease-in-out;
  }
  &__list-item {
    padding: 10px 20px;
    color: $color-slate;
    cursor: pointer;
    &:hover,
    &.--hover {
      background-color: $color-charcoal;
      color: $color-white;
    }
  }
  &__list-text {
    font-family: $font-regular;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.55px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.no-result {
  &__title {
    padding-top: 30px;
    font-family: $font-light;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -1.6px;
    color: $color-slate;
  }
}

@media (max-width: 1279px) {
  .technical-specs {
    .product-listing__total {
      min-width: 253px;
    }
    &__search {
      width: 339px;
    }
    .product-listing__sticky-nav {
      padding-top: 30px;
    }
  }
}

@media all and (max-width: 1024px) and (orientation: portrait) {
  .technical-specs {
    .mobile-portrait-only {
      display: block;
    }
    .mobile-landscape-only {
      display: none !important;
    }
  }
}

@media all and (max-width: 1024px) and (orientation: landscape) {
  .technical-tile {
    .mobile-landscape-only {
      display: flex !important;
    }
    &__brand-name {
      font-family: $font-regular;
    }
    &__specs {
      h5 {
        margin-bottom: 4px;
      }
    }
  }
  .technical-specs {
    .product-list__title {
      margin-top: 0px;
      font-size: 46px;
    }
    &__search--mobile {
      width: 300px;
    }
    &__search-wrap {
      margin-top: 0 !important;
    }
  }
}

@media all and (max-width: 1024px) {
  .technical-specs {
    &__search-wrap--mobile {
      border-bottom: 1px solid $color-dark-gray;
    }
    &__list {
      &__title {
        font-size: 40px;
        line-height: 46px;
        margin-top: 0px;
        * {
          font-size: 40px;
          line-height: 46px;
        }
      }
    }
    &__search {
      width: 296px;
    }
  }
  .product-listing {
    &__row {
      &.d-block {
        margin-top: 0px;
      }
    }
  }
}

@media only screen and (max-width: 1024px) and (orientation: landscape) {
  .technical-specs {
    &__search {
      width: 296px;
    }
    .technical-tile {
      display: grid;
      grid-column-gap: 30px;
    }
    .product-list {
      &__tile {
        margin-bottom: 60px;
        &:last-child {
          margin-bottom: 40px;
        }
      }
    }
  }
  .no-result {
    &__title {
      padding-top: 50px;
      font-family: $font-light;
      font-size: 26px;
      line-height: 34px;
      letter-spacing: -1.5px;
      color: $color-slate;
    }
  }
}

@media all and (max-width: 992px) {
  .technical-specs {
    .product-listing__sticky-nav {
      padding-top: 20px;
      padding-bottom: 15px;
    }
    .technical-specs__desc {
      border-left: none;
      padding: 0px 18px;
    }
    .result-section {
      .result {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    &__search {
      width: 296px;
    }
    &__search-wrap {
      margin-top: 0;
    }
    .product-list__description {
      font-size: 20px;
      line-height: 26px;
      letter-spacing: -1.15px;
      padding-top: 0px;
      margin-top: 10px;
      margin-bottom: 0px;
    }
    .product-listing__total {
      width: 204px;
    }
    .product-list__header {
      margin-bottom: 10px;
    }
  }
}

@media all and (max-width: 812px) {
  .technical-specs {
    .sticky {
      .product-listing__sticky-nav {
        padding: 0 !important;
      }
    }
    &__search-wrap-parent {
      max-width: 612px;
    }
    &__search {
      width: 339px;
    }
    &__search-flex {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
    &__sticky-search-pd {
      margin-top: 13px;
      margin-bottom: 6px;
    }
  }
  .search-dropdown__field-ml {
    height: 60px;
    display: flex;
    padding: 16px 3.94%;
    border-radius: 4px;
    border: solid 1px #757575;
  }
  .product-listing__total {
    width: 204px;
  }
}

@media only screen and (max-width: 812px) and (orientation: landscape) {
  .technical-specs {
    &__search-wrap-parent {
      max-width: 612px;
    }
    &__search-ml {
      margin-left: 0;
      width: 100%;
    }
    &__search {
      width: 339px;
    }
    &__list {
      &__title {
        * {
          font-size: 40px;
          line-height: 46px;
          letter-spacing: -2.5px;
        }
      }
    }
    .no-result {
      &__title {
        padding-top: 40px;
      }
    }
    margin-top: 0;
    .product-list__description {
      font-size: 20px;
      line-height: 26px;
      letter-spacing: -1.15px;
    }
    &__sticky-search-pd {
      margin: 0;
    }
    .--sticky-pd-nav {
      margin: 13px 0;
    }
    .sticky {
      .product-listing__sticky-nav {
        padding: 30px;
      }
    }
  }
  .search-dropdown__field-ml {
    height: 60px;
    display: flex;
    padding: 18px 32px;
    border-radius: 4px;
    border: solid 1px #757575;
  }
  .product-listing__total {
    width: 204px;
  }
}

@media only screen and (max-width: 767px) {
  .technical-specs {
    .technical-tile__files {
      section {
        padding: 10px 0px;
        .checkbox-list {
          justify-content: flex-start;
          .checkbox__marker img {
            margin: 0px;
          }
          .checkbox {
            margin: 0px 30px 0px 0px;
          }
        }
      }
    }

    &__search--mobile {
      margin: 20px 0px;
      width: 100%;
    }
    &__search {
      width: 100%;
    }
    &__search-flex {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
    &__sticky-search-pd {
      margin-top: 13px;
      margin-bottom: 6px;
    }
    &__search-ml {
      margin-left: 0;
      width: 100%;
    }
    .product-list {
      &__tile {
        margin-bottom: 60px;
        &:last-child {
          margin-bottom: 40px;
        }
      }
    }

    .result-section {
      display: block;
      justify-content: center;
    }

    .product-listing__total {
      min-width: 204px;
    }
  }
  .sticky {
    .result-section {
      .result {
        padding-left: 18px;
        padding-right: 18px;
        padding-top: 20px;
      }
    }
  }
  .no-result {
    &__title {
      padding-top: 48px;
      font-family: $font-light;
      font-size: 26px;
      line-height: 34px;
      letter-spacing: -1.5px;
      color: $color-slate;
    }
  }

  .technical-tile {
    &__brand-name {
      font-family: $font-regular;
      color: $color-dark-gray;
    }
  }
  .search-dropdown__field-ml {
    height: 60px;
    display: flex;
    padding: 16px 3.94%;
    border-radius: 4px;
    border: solid 1px #757575;
  }
}

@media only screen and (max-width: 480px) {
  .search-dropdown__field-ml {
    height: 60px;
    display: flex;
    padding: 16px 4.8%;
    border-radius: 4px;
    border: solid 1px #757575;
  }
}

// iphone SE
@media only screen and (min-width: 568px) and (max-width: 640px) {
  .product-listing__total {
    width: auto !important;
  }
}

@media print {
  div.nav-up,
  div.nav-down {
    position: static !important;
  }
  a:not(.btn) {
    text-decoration: none !important;
  }
}
