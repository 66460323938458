@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";
@import "@/styles/mixins/_breakpoint";

.VisualSearchContainer {
  margin: 0;
  padding: 0;
  :global {
    .fs-34 {
      font-size: 34px;
    }
    .visualContentwarpper {
      max-width: 612px;
      margin: 0 auto;
      @include breakpoint(desktop-mid) {
        margin: 30px 32px;
      }
      @include breakpoint(desktop-small) {
        max-width: 1504px;
      }
    }
    .error-section,
    .para-section {
      display: flex;
      justify-content: flex-start;
      max-width: 1286px;
      flex-direction: column;
      width: 100%;
      margin: 0 auto;
    }
    .error-section {
      padding: 146px 0px 436px;
      @include breakpoint(desktop-mid) {
        padding: 146px 60px 436px;
      }
      @include breakpoint(tab-max) {
        padding: 110px 60px 472px;
      }
      @include breakpoint(desktop-mimi-max) {
        padding: 102px 31px 151px 32px;
      }
      @include breakpoint(mobile) {
        padding: 112px 18px 161px;
      }
    }
    .para-section {
      padding: 134px 0px 405px;

      @include breakpoint(desktop-mid) {
        padding: 134px 60px 405px;
      }
      @include breakpoint(tab-max) {
        padding: 110px 60px 429px;
      }
      @include breakpoint(desktop-mimi-max) {
        padding: 31px 33px 79px 32px;
      }
      @include breakpoint(mobile) {
        padding: 54px 18px 117px;
      }
    }
    .mobile-container {
      background: $white;
      @include breakpoint(desktop-mimi-max) {
        background: $black;
      }
    }
  }
}
