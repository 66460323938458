.sticky-header {
  position: fixed;
  top: 0;
  z-index: 99;
  left: 0;
  right: 0;
  background-color: $color-white;
}

.product-details {
  font-family: $font-regular;
  font-stretch: normal;
  font-style: normal;
  margin-bottom: 120px;

  &__print-note {
    display: none;
    h6 {
      font-family: $font-bold;
      margin-bottom: 15px;
    }
    p {
      font-family: $font-regular;
      font-size: 16px;
      margin-bottom: 15px;
    }
  }

  .container {
    max-width: 1380px;
  }
  &__breadcrumb {
    margin-top: 30px;
    margin-bottom: 40px;
    nav ol .adaopacity {
      opacity: 0;
    }
    nav {
      color: $color-slate;
      font-size: 14px;
      line-height: 1.71;
      ol {
        margin: 0;
        list-style: none;

        li {
          display: inline;
          a {
            cursor: pointer;
            font-family: $font-regular;
            font-size: 14px;
            line-height: 1.71;
            letter-spacing: -0.3px;
            color: $color-slate;
            text-decoration: none;
            &:hover {
              color: $color-slate;
            }
          }
        }
      }
    }
  }

  &__sticky-wrapper {
    position: relative;
    z-index: 10;
  }

  &__toaster {
    position: sticky;
    z-index: 99;
    top: 0;
    &.--sticky {
      top: 140px;
    }
    &.--mobile {
      display: none;
    }
  }

  &__image-gallery {
    padding-right: 40px;
  }
  .image-reel {
    overflow: hidden;
    &__image-thumb-view {
      padding-right: 17px;
    }
  }

  &__order-messaging {
    padding-top: 15px;
  }
  &__content-section {
    .product-details__sku-id {
      display: block;
      font-family: $font-regular;
      font-size: 20px;
      @media (max-width: 480px) {
        font-size: 16px;
      }
      line-height: 28px;
      letter-spacing: -0.65px;
      color: $color-dark-gray;
      margin-bottom: 20px;
    }
    .product-details__title {
      font-family: $font-light;
      font-size: 60px;
      font-weight: bold;
      line-height: 68px;
      letter-spacing: -3.1px;
      color: $color-slate;
      padding-bottom: 20px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .product-details__description {
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.45px;
      color: $color-dark-gray;
      margin-top: 15px;
      margin-bottom: 60px;
    }
    h6 {
      font-family: $font-medium;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.35px;
      color: $color-charcoal;
    }
  }
  .price-retail-exclusive {
    display: none;
  }
  &__price {
    display: flex;
    justify-content: flex-end;
    padding: 45px 0 0;
    border-top: 1px solid $color-dark-gray;
    &-value {
      flex-grow: 1;
      text-align: right;
      font-family: $font-light;
      font-size: 52px;
      line-height: 1.04;
      letter-spacing: -3.5px;
      color: $color-slate;
    }
    .product-details__without-price-section {
      font-family: $font-light;
      font-size: 22px;
      line-height: 32px;
      letter-spacing: -1.2px;
      color: $color-charcoal;
    }
  }

  &__discount {
    text-align: right;
  }
  &__discount-price {
    text-decoration: line-through;
    color: $color-dark-gray;
    font-family: $font-regular;
    font-size: 22px;
    letter-spacing: -0.4px;
    line-height: 30px;
  }
  &__percent-off {
    margin-right: 15px;
    color: $color-slate;
    font-family: $font-medium;
    font-size: 20px;
    letter-spacing: 0.5px;
    line-height: 30px;
  }

  &__list-price--consumer {
    padding-top: 40px;
    border-top: 1px solid $color-dark-gray;
    padding-bottom: 40px;
    border-bottom: 1px solid $color-dark-gray;
  }

  &__list-price--associate {
    margin-top: 30px;
  }

  &__list-view {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__list-text {
    display: flex;
    align-items: center;
    color: $color-dark-gray;
    font-family: $font-regular;
    font-size: 16px;
    letter-spacing: -0.35px;
    line-height: 24px;
    text-decoration: underline;
    margin-right: 10px;
  }

  &__list-value {
    text-align: right;
    color: $color-dark-gray;
    font-family: $font-regular;
    font-size: 22px;
    letter-spacing: -0.4px;
    line-height: 30px;
    color: $color-slate;
  }

  &__list-tooltip-wrapper {
    position: absolute;
    z-index: 3;
    bottom: 10px;
    right: 40px;
    @media screen and (max-width: 480px) {
      right: -30px;
    }
  }

  &__list-tooltip--show {
    display: block;
    position: relative;
  }

  &__list-tooltip--hide {
    display: none;
  }

  &__customer-group {
    color: $color-slate;
    font-family: $font-medium;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.35px;
    line-height: 24px;
  }

  &__finish-sample {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 33px;
    .product-details__finish {
      .product-details__finish_text {
        font-family: $font-medium;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.35px;
        color: $color-charcoal;
        margin-right: 10px;
      }
      .product-details__finish_value {
        font-family: $font-medium;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.35px;
        color: $color-dark-gray;
      }
    }
    .product-details__discontinued {
      .discontinued-text {
        font-family: $font-medium;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.35px;
        color: $color-charcoal;
        margin-right: 10px;
      }
      .discontinued-color-value {
        font-family: $font-medium;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.35px;
        color: $color-dark-gray;
      }
    }
  }

  &__color-swatch {
    width: 100%;
    margin-bottom: 47px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 5px;
  }

  .install-requirements {
    display: inline-block;
    margin-bottom: 20px;
    cursor: pointer;
    text-decoration: underline;
    font-family: $font-regular;
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: -0.65px;
    color: $color-dark-gray;
  }
  &__install-item-details {
    padding-bottom: 40px;
    &:last-child {
      padding-bottom: 0px;
    }
  }
  &__install-item {
    padding-top: 50px;
    border-top: 1px solid $color-light-gray;
    padding-bottom: 50px;
  }
  &__install-item-btn {
    font-family: $font-medium;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.35px;
    text-align: center;
    color: $color-dark-gray;
    display: flex;
    .button {
      color: $color-slate;
    }
  }
  &__tooltip-icon {
    padding-left: 10px;
    padding-top: 15px;
    position: relative;
    img {
      cursor: pointer;
    }
    .tooltip__container {
      position: absolute;
      z-index: 9;
      right: 12px;
      bottom: 95%;
      top: auto;
      text-align: initial;
    }
  }
  &__install-items-container {
    border-bottom: 1px solid $color-light-gray;
    padding-bottom: 40px;
    .item-details {
      font-family: $font-medium;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.4px;
      padding-right: 10px;
      color: $color-charcoal;
      margin-bottom: 5px;
    }
    .item-price {
      text-align: right;
      font-family: $font-light;
      font-size: 26px;
      line-height: 34px;
      letter-spacing: -1.5px;
      color: $color-slate;
    }
    .discount-price {
      margin: 10px 0 5px;
      text-align: right;
      color: $color-dark-gray;
      font-family: $font-regular;
      font-size: 22px;
      letter-spacing: -0.4px;
      line-height: 30px;
      text-decoration: line-through;
    }
    .discount-percent {
      text-align: right;
      color: $color-slate;
      font-family: $font-medium;
      font-size: 20px;
      letter-spacing: 0.5px;
      line-height: 30px;
    }
    .item-color-details {
      font-family: $font-regular;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.55px;
      color: $color-slate;
    }
    .remove-link {
      font-family: $font-regular;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.55px;
      color: $color-dark-gray;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__price_spider_title {
    font-family: $font-medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.35px;
    color: $color-charcoal;
    padding-bottom: 15px;
  }

  .options-selector {
    padding-bottom: 2.5rem;
    &__heading {
      margin-bottom: 0.625rem;
      display: flex;
      align-items: center;
      span {
        font-family: $font-medium;
        font-size: 1rem;
        color: #212121;
        letter-spacing: -0.022rem;
        line-height: 1.5rem;
        margin-right: 5px;
      }
    }
    .collapsible-accordion {
      .Collapsible {
        border-bottom: none;
      }
      .Collapsible__trigger {
        padding: 0px;
      }
      .collapsible__header {
        span {
          color: $color-charcoal;
        }
      }
      .Collapsible__contentInner {
        padding-bottom: 0px;
      }
    }
    .select-dropdown {
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  .more-sec {
    padding-top: 10px;
  }

  &__oversized-msg-section {
    background-color: $color-off-white;
    padding: 25px;
    margin-bottom: 25px;

    .oversized-msg-tooltip {
      display: grid;

      .oversized-msg-primary-txt {
        font-family: $font-medium;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.35px;
        color: $color-charcoal;
        padding-bottom: 5px;
      }
      .oversized-msg-secondary-txt {
        font-family: $font-regular;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.55px;
        color: $color-dark-gray;
      }
    }
    .oversized-msg-info-icon {
      padding: 10px 0 0 15px;
    }
    @media (max-width: 480px) {
      padding: 15px;
      .oversized-msg-info-icon {
        padding: 10px 0 0 9px;
      }
    }
  }

  .quantity-selector-container {
    padding-right: 0px;
  }

  .add-to-cart-container {
    padding-left: 20px;
    padding-bottom: 40px;
  }

  .add-to-compare {
    position: relative;
    .checkbox {
      margin-bottom: 0px;
    }
    .tooltip__container {
      position: absolute;
      z-index: 9;
      right: 0;
    }
    @media (max-width: 480px) {
      display: none;
    }
  }

  .print-container {
    padding: 40px 15px 30px;
  }

  &__details-dimensions {
    border-top: 1px solid $color-light-gray;
    // border-bottom: 1px solid $color-light-gray;
    margin-bottom: 40px;
  }

  &__more-from-collection {
    display: flex;
    justify-content: space-between;
    margin-bottom: 29px;
    a {
      text-decoration: underline;
      font-family: $font-regular;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.35px;
      color: $color-dark-gray;
    }
  }

  &__need-professional {
    padding-bottom: 20px;
    border-bottom: 1px solid $color-light-gray;
    margin-top: 100px;
    h6 {
      color: $color-charcoal;
      margin-bottom: 0;
    }
  }

  &__professional-service-heading {
    font-family: $font-medium;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.35px;
    color: $color-charcoal;
    margin-bottom: 0px;
    margin-top: 100px;
    border-bottom: 1px solid $color-dark-gray;
    padding-bottom: 20px;
  }

  .professional-services,
  .details-container {
    border-bottom: 1px solid $color-light-gray;
  }

  &__need-installation {
    margin-top: 25px;
    h6,
    p {
      font-family: $font-medium;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.35px;
      color: $color-charcoal;
    }
    a {
      font-family: $font-medium;
      color: $color-dark-gray;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.35px;
      &,
      &:hover {
        text-decoration: underline;
      }
      @media (min-width: 992px) {
        text-align: right;
        display: block;
      }
    }
  }
  &__discontinued-txt {
    font-family: $font-light;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -1.2px;
    color: $color-charcoal;
    padding-bottom: 25px;
  }
  &__available-color-section {
    padding-bottom: 40px;
  }
  &__available-color-btn {
    width: 100%;
  }
  &__discontinue-image {
    position: absolute;
    background-color: $color-dark-gray;
    top: 12px;
    left: 35px;
    font-size: 16px;
    color: $color-off-white;
    padding: 10px;
  }
  &__discontinue-image-view {
    width: 100%;
    height: auto;
  }

  .header-section {
    display: flex;
    justify-content: space-between;
    padding: 0;
  }

  &__print-share-container {
    font-size: 16px;
    font-family: $font-regular;
    color: $color-dark-gray;
    padding-top: 30px;

    .print-and-share {
      display: flex;
      justify-content: space-between;
      & > * + * {
        margin-left: 30px;
      }
      button {
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        img {
          margin-right: 10px;
        }
      }
    }
    .social-share__box {
      right: 0;
    }
    .social-share {
      &__cta {
        display: flex;
        align-items: center;
      }
    }
    .social-share__sites {
      a:focus-visible {
        outline: none !important;
      }
    }

    @media (max-width: 480px) {
      display: none;
    }
  }

  .modal-popup {
    top: 0;
    padding: 0;
    height: 100vh;
    .modal-popup-content {
      padding: 80px 50px 0 0;
      height: 100vh;
      .close-button {
        right: 110px;
        top: 24px;
        color: #757575;
      }
    }
  }
}

.add-to-cart {
  font-size: 16px;
  font-family: $font-regular;
  color: $color-dark-gray;

  .product-details__stock-alert {
    display: flex;
    padding-bottom: 40px;
    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    span {
      color: $color-error-red;
      font-family: $font-medium;
      font-size: 16px;
      letter-spacing: -0.35px;
      line-height: 24px;
    }
  }

  .checkbox-wrapper {
    input:checked ~ .checkbox-label {
      color: $color-dark-gray;
    }
  }

  .category-tooltip-hidden,
  .max-product-tooltip-hidden {
    display: none;
  }
  .category-tooltip-shown,
  .max-product-tooltip-shown {
    margin-top: 5px;
    display: block;
    position: absolute;
    z-index: 99;
  }

  .print-and-share {
    display: flex;
    justify-content: space-between;
    span {
      cursor: pointer;

      img {
        margin-left: 5px;
      }
    }
  }

  .available-store-link {
    font-family: Helvetica Now Text W05 Medium;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.35px;
    text-decoration: underline;
    color: $color-dark-gray;
    display: block;
    cursor: pointer;
  }

  .out-of-stock-container {
    display: grid;
    margin-bottom: 40px;

    .out-of-stock {
      font-family: $font-light;
      color: $color-charcoal;
      font-size: 22px;
      line-height: 32px;
      letter-spacing: -1.2px;
      padding-bottom: 10px;
    }

    .out-of-stock-description {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.55px;
      padding-bottom: 40px;
      color: $color-dark-gray;
      font-family: $font-regular;

      a {
        color: $color-dark-gray;
        text-decoration: underline;
      }
    }

    .email-address-field {
      .email_input {
        border-radius: 4px;
        background: $color-white;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 18px;
        height: 60px;
        border: 1px solid $color-medium-gray;
        display: block;
        width: 100%;
        clear: both;
        outline: none;
        line-height: 26px;
        letter-spacing: -0.7px;
      }
    }
    .out-of-stock-success-popup {
      .modal-popup .modal-popup-content .close-button {
        display: block;
        color: $color-charcoal;
        right: 0;
        top: 22px;
      }
      .modal-popup {
        .modal-popup-content {
          .modal-popup-body {
            padding: 10px 0px 20px;
          }
          height: 65vh;
          .close-button {
            right: 40px;
          }
          .modal-popup-body {
            .success-popup {
              display: inline-grid;
              &__title {
                font-family: $font-regular;
                text-align: center;
                padding-top: 100px;
                font-size: 26px;
                line-height: 32px;
                color: $color-slate;
              }
              &__message {
                font-family: $font-regular;
                padding: 5% 20%;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: normal;
                color: $color-slate;
                text-align: center;
              }
              &__done-cta {
                margin-left: 25%;
                padding-top: 40px;
                .doneCTA {
                  background: transparent;
                  width: 65%;
                  font-size: 16px;
                  font-family: $font-regular;
                  height: 55px;
                }
              }
            }
          }
        }
      }
    }
  }
  .bottom-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 0 40px 0;
    @media (max-width: 480px) {
      padding-top: 20px;
    }

    .add-to-compare {
      margin-right: 34px;
    }

    .favorites {
      cursor: pointer;
      padding-bottom: 0;

      img {
        width: 20px;
      }

      @media (max-width: 480px) {
        position: absolute;
        right: 0px;
      }
      &--discontinued {
        pointer-events: none;
      }
    }

    .text {
      font-family: "Helvetica Now Text W05 Regular";
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.55px;
      color: $color-dark-gray;
      padding-left: 10px;
    }
  }

  .promoTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $color-dark-gray;
    cursor: pointer;
    padding: 43px 0;

    p {
      color: $color-kohler-blue;
      font-family: $font-medium;
      letter-spacing: -0.35px;
    }

    img {
      transition: all 0.3s ease;
      height: 10px;
      width: 15px;
    }

    &.show {
      padding-bottom: 10px;
      img {
        transform: rotate(180deg);
      }
    }
  }
  .promoDescription {
    max-height: 0;
    overflow: hidden;
    p {
      letter-spacing: -0.55px;
      color: $color-dark-gray;
    }

    &.show {
      padding-bottom: 40px;
      max-height: 200px;
    }
  }
}

.quantity-selector {
  border-radius: 5px;
  border: 1px solid $color-medium-gray;
  text-align: center;
  display: flex;
  height: 60px;

  button {
    border: none;
    background: transparent;
    width: 30%;
    flex: 1;
    padding: 0px;
    &:disabled {
      .plus {
        .line {
          background-color: $color-light-gray;
        }
      }
    }
  }
  // hide inc/dec arrows for number field
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }

  &__count {
    font-family: $font-light;
    width: 30%;
    text-align: center;
    border: none;
    font-size: 26px;
    line-height: 1;
    letter-spacing: -1px;
    padding: 0px;
    color: $color-charcoal;
  }

  .plus {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    .line {
      background: $color-dark-gray;
      &.line-1 {
        width: 100%;
        height: 1px;
      }
      &.line-2 {
        width: 1px;
        height: 100%;
        transform: translateX(-10px);
      }
    }
  }
}

.review {
  .review-container {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 0;
    @media only screen and (max-width: 1023px) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .product-details {
    .image-reel__image-thumb-view {
      padding-right: 1px;
    }
    .print-overview {
      margin-left: 0;
      margin-right: 0;
    }
    .print-overview-left {
      padding-right: 0;
    }
    .print-overview-right {
      padding-right: 0;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1599px) {
  .product-details {
    .kf-react-container {
      padding: 0 36px;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .product-details .product-overview {
    display: grid;
    grid-template-columns: 1fr 480px;
    gap: 40px;
  }
  .kf-react-plp-container {
    padding: 0px;
    @media (max-width: 1920px) {
      max-width: 94.8%;
      margin-left: 2.6%;
      margin-right: 2.6%;
    }
  }
  @media (max-width: 1279px) {
    .kf-react-plp-container {
      min-width: 971px;
    }
  }
}

@media only screen and (min-width: 1921px) {
  .kf-react-plp-container {
    max-width: 1820px;
    margin: auto;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1919px) {
  .kf-react-plp-container {
    min-width: 1517px;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1599px) {
  .kf-react-plp-container {
    min-width: 1366px;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1439px) {
  .kf-react-plp-container {
    min-width: 1214px;
  }
}

@media only screen and (min-width: 480px) {
  .kf-react-plp-container {
    @media (max-width: 1023px) {
      padding: 0px;
      max-width: 92.12%;
      margin-left: 3.94%;
      margin-right: 3.94%;
    }
  }
}

@media only screen and (min-width: 1300px) {
  .product-details .product-overview {
    gap: 120px;
  }
}

@media only screen and (min-width: 1600px) {
  .product-details {
    .kf-react-container {
      padding: 0;
    }
    .print-overview-left {
      padding-right: 0;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .product-details {
    margin-bottom: 80px;
  }
  .product-details__toaster {
    &.--sticky {
      .toast-msg {
        top: 60px;
      }
    }
  }
  .product-details__image-gallery .modal-popup .modal-popup-content {
    padding: 80px 0 0 0 !important;
  }
}

@media only screen and (max-width: 839px) {
  .product-details__image-gallery {
    min-height: 50vh;
  }
}

@media screen and (max-width: 480px) {
  .kf-react-plp-container {
    padding: 0;
    .row {
      .search-result {
        &__description,
        &__search-field {
          padding-right: 18px;
          padding-left: 18px;
        }
      }
    }
  }
  .image-gallery__image-stack-list {
    &:first-of-type {
      img {
        //object-position: 0% 50%
      }
    }
    width: 100%;
  }
}

@media only screen and (max-width: 990px) {
  .product-details .product-overview {
    display: grid;
    grid-template-columns: 100%;
    gap: 50px;
  }
  .product-details__toaster {
    &.--mobile {
      display: block;
    }
    &.--sticky {
      .toast-msg {
        padding-left: 18px;
        padding-right: 18px;
        top: 60px;
      }
    }
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .product-details {
    padding: 0;
    &__breadcrumb {
      margin-bottom: 20px;
      margin-top: 20px;
    }
    &__image-gallery {
      padding-right: 0;
    }
    &__print-container {
      font-size: 14px;
      padding-top: 18px;
    }
    &__content-section {
      padding-top: 22px;
    }
    &__install-items-modal {
      .modal-popup .modal-popup-content .close-button {
        display: block;
        color: $color-charcoal;
        right: 0;
        top: 22px;
      }
    }
    &__install-item-details {
      padding-bottom: 38px;
      &:last-child {
        padding-bottom: 0px;
      }
    }
    .print-container {
      padding: 40px 15px 34px;
    }
    .add-to-cart {
      margin: 0;
    }
    .quantity-selector-container {
      padding-right: 10px;
    }
  }
}
@media only screen and (max-width: 767px) {
  .product-details {
    &__breadcrumb {
      margin-bottom: 20px;
      margin-top: 20px;
    }

    &__image-gallery {
      padding-right: 15px;
    }

    &__content-section {
      padding-top: 50px;
      .product-details__title {
        font-size: 40px;
        line-height: 46px;
        letter-spacing: -2.5px;
      }
    }

    &__color-swatch {
      margin-bottom: 40px;
    }

    &__price {
      .product-details__price-value {
        font-size: 39px;
      }
      .product-details__without-price-section {
        font-size: 22px;
      }
    }

    &__need-installation {
      h6,
      a {
        display: block;
      }
    }

    &__install-item {
      padding-top: 45px;
      padding-bottom: 45px;
    }

    &__install-items-modal {
      .modal-popup .modal-popup-content .close-button {
        display: block;
        color: $color-charcoal;
        right: 0;
        top: 22px;
      }
      .modal-popup {
        .modal-popup-content {
          .modal-popup-body {
            padding: 10px 0px 20px;
          }
          .close-button {
            right: 25px;
          }
        }
      }
    }
    &__install-item-details {
      padding-bottom: 38px;
      &:last-child {
        padding-bottom: 0px;
      }
    }

    &__finish-sample {
      .product-details__finish {
        flex-direction: column;
      }
      .product-details__discontinued {
        display: flex;
        flex-direction: row;
      }
    }

    .modal-popup .modal-popup-content .close-button {
      right: 30px;
    }
  }

  .add-to-cart {
    padding-bottom: 60px;
    &__discontinued {
      padding-bottom: 0;
    }
    .add-to-compare-label {
      margin: 0;
    }
    .print-and-share {
      span {
        img {
          width: 16px;
          display: inline-block;
        }
      }
    }
  }

  .add-to-cart-container {
    padding-left: 10px;
  }
}

@media screen and (max-width: 480px) {
  .services-container .services-title h1 {
    font-size: 29px;
  }
  .product-details__color-swatch {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(64px, 1fr));
    grid-column-gap: 5px;
    margin-bottom: 40px;
  }

  .product-details {
    .print-overview {
      margin-left: 0;
      margin-right: 0;
    }
    .print-overview-left,
    .print-overview-right {
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 30px;
    }
    .header-section {
      padding: 0 18px;
    }
    &__need-installation {
      padding-bottom: 20px;
    }
    &__professional-service-heading {
      margin-top: 100px;
    }
  }

  .add-to-cart {
    padding-bottom: 10px;
  }
}

@media print {
  .product-details {
    &__print-note {
      display: block;
      h6 {
        font-family: $font-bold;
        margin-bottom: 15px;
      }
      p {
        font-family: $font-regular;
        font-size: 16px;
        margin-bottom: 15px;
      }
    }

    &__installation,
    &__design-services {
      display: none;
    }

    .print-overview {
      .print-overview-left,
      .print-overview-right {
        flex: inherit !important;
        float: left;
        width: 50%;
      }
    }

    &__breadcrumb,
    &__order-sample,
    &__more-from-collection,
    &__collection-carousel,
    &__list-price--consumer,
    &__oversized-msg-section,
    &__need-installation,
    .sticky-header,
    .professional-services,
    .options-selector,
    .quantity-selector,
    .add-to-cart,
    .image-reel__image-thumb-view,
    .image-reel__link-more,
    .carousel-collection,
    .details-dimensions__section-view-more {
      display: none !important;
    }

    &__print-share-container {
      .print-and-share {
        display: none;
      }
    }

    &__main,
    &__finish,
    &__color-swatch-main,
    &__price-main,
    &__sticky-wrapper,
    .details-dimensions__section-title,
    .details-dimensions__list,
    .image-reel__image-list {
      display: block;
    }
  }

  .features-technologies__description {
    background-color: #ffffff;
    color: #212121 !important;
  }

  .features-technologies__list-item {
    color: #494949 !important;
  }

  .product-details__image-gallery {
    display: block !important;
    float: left !important;
  }
  .product-details__content-section {
    display: block !important;
    float: right !important;
  }

  .search-button {
    display: none !important;
  }

  .features-technologies {
    background-color: #ffffff !important;
    color: #212121 !important;
    padding-top: 0 !important;
  }

  .features-technologies .collapsible-accordion:first-child {
    border-top: none !important;
  }

  .features-technologies .collapsible-accordion:last-child {
    border-bottom: none !important;
  }

  .features-technologies__content {
    padding-bottom: 0 !important;
  }

  .features-technologies
    .collapsible-accordion
    .Collapsible
    .Collapsible__trigger
    .collapsible__header
    span {
    color: #212121 !important;
  }

  .collapsible-accordion .Collapsible__trigger .collapsible__header .plus {
    display: none !important;
  }

  .more-from-collection,
  .icon-Hamburger,
  .product-specification,
  .xfpdp,
  .review,
  .embed,
  .mainfooter,
  .pairs-well-with,
  .newslettersignin,
  .additionalfooter,
  .product-details__list-price--consumer,
  .product-details__design-services,
  .product-details__need-installation,
  .product-details__installation {
    display: none !important;
  }
}
