@import "@/styles/base/_colors";
@import "@/styles/mixins/_breakpoint";
@import "@/styles/base/_fonts";
.headerNavV3Wrapper {
  :global {
    width: 100%;

    .cmp-tabs {
      @include breakpoint(desktop-extra-lite-sm) {
        padding-bottom: 50px;
      }
      &.light-theme {
        .gbh-close {
          &.icon-Close-Medium {
            @include breakpoint(desktop-extra-lite-sm) {
              background-color: initial;
            }
          }
        }
      }
      &.light-theme,
      &.dark-theme {
        @include breakpoint(desktop-extra-lite-sm) {
          background-color: $white;
        }
        .gbh-close {
          @include breakpoint(desktop-extra-lite-sm) {
            background-color: $white;
            padding: 17px 0;
            width: auto;
            height: auto;
            margin-left: 0;
            margin: 0;
            position: fixed;
            z-index: 999;
          }
        }
        .cmp-tabs__tablist {
          @include breakpoint(desktop-extra-lite-sm) {
            background-color: $white;
            height: 100%;
            width: 100%;
            display: block;
            padding: 65px 49px 250px;
          }
          @media screen and (min-width: 639px) and (max-width: 1200px) {
            height: auto;
            padding-left: 100px;
            padding-right: 100px;
          }
          @include breakpoint(desktop-extra-lite-sm) {
            padding-bottom: 200px;
          }
          .cmp-tabs__tab {
            @include breakpoint(desktop-extra-lite-sm) {
              color: $color-charcoal !important;
            }
            @include breakpoint(desktop-extra-lite-sm) {
              height: unset;
              position: relative;
            }
            &::before {
              @include breakpoint(desktop-extra-lite-sm) {
                content: url(/icons/arrow-right-bold.svg);
                display: block;
                position: absolute;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                pointer-events: none;
              }
            }
          }
        }
      }
      &.dark-theme {
        @include breakpoint(desktop-extra-lite-sm) {
          background-color: $color-black;
        }
        .gbh-close {
          @include breakpoint(desktop-extra-lite-sm) {
            background-color: $color-black;
          }
          &::before {
            @include breakpoint(desktop-extra-lite-sm) {
              content: url(/icons/white-close-medium.svg);
            }
          }
          img {
            display: none;
          }
        }
        .cmp-tabs__tablist {
          @include breakpoint(desktop-extra-lite-sm) {
            background-color: $color-black;
          }
          .cmp-tabs__tab {
            color: $color-white !important;
            &::before {
              @include breakpoint(desktop-extra-lite-sm) {
                content: url(/icons/white-right-bold.svg);
              }
            }
          }
        }
      }
    }
    .gbh-global-tab {
      &.open {
        .cmp-tabs {
          @include breakpoint(desktop-extra-lite-sm) {
            padding-bottom: 50px;
          }
        }
      }
    }
    .globalnavigation {
      .gbh-full-w-menu {
        .gbh-full-w-menu {
          overflow: scroll;
          &__inner {
            padding: 0;
            min-height: auto;
            border: none;
            @include breakpoint(desktop-extra-lite-sm) {
              background-color: $white;
            }
            ul {
              &.gbh-full-w-menu {
                overflow: scroll;
                &__left-cont {
                  &__menu-list {
                    width: auto;
                    @include breakpoint(desktop-extra-lite-sm) {
                      padding: 0;
                      max-height: 100%;
                    }
                    @include breakpoint(mobile-portrait-giant) {
                      padding: 0;
                    }
                    li {
                      @include breakpoint(desktop-extra-lite-sm) {
                        margin-bottom: 0;
                      }
                      a {
                        font-size: 15px;
                        letter-spacing: -0.4px;
                        line-height: 22px;
                        @include breakpoint(desktop-extra-lite-sm) {
                          font-size: 16px;
                          line-height: 24px;
                          font-family: $helvetica-regular;
                          color: $charcoal;
                          letter-spacing: -0.4px;
                        }
                        p {
                          line-height: 22px;
                          font-size: 15px;
                          @include breakpoint(desktop-extra-lite-sm) {
                            font-size: 16px;
                            line-height: 24px;
                            font-family: $helvetica-regular;
                            color: $charcoal;
                            letter-spacing: -0.4px;
                          }
                        }
                      }
                      .navigation-img {
                        img {
                          @include breakpoint(desktop-extra-lite-lg) {
                            width: 100%;
                          }
                        }
                      }
                      img {
                        @include breakpoint(desktop-extra-lite-lg) {
                          display: none;
                          width: auto;
                        }
                      }
                    }
                    li:hover {
                      outline: none;
                    }
                  }
                }
              }
            }
            .gbh-full-w-menu {
              &__right-img {
                position: relative;
                padding-left: 60px;
                width: auto;
                height: auto;
                .block-images {
                  p {
                    font-size: 14px;
                    line-height: 22px;
                    text-align: left;
                    letter-spacing: -0.45px;
                    color: $color-slate;
                  }
                }
              }
            }
            .gbh-full-max-width {
              transition: all 0.2s linear;
            }
          }
        }

        .two-landscape-images:first-child {
          margin-left: 0;
        }
        .two-landscape-images {
          width: 440px;
          margin-left: 60px;
          display: flex;
          flex-direction: column;
          @media screen and (min-width: 1600px) and (max-width: 1919px) {
            width: 415px;
          }
          @media screen and (min-width: 1201px) and (max-width: 1599px) {
            width: 345px;
          }
          @include breakpoint(desktop-extra-lite-sm) {
            margin-left: 0;
            margin: 0 0 30px 0;
            width: 47.55%;
          }
          @media screen and (min-width: 1035px) and (max-width: 1040px) {
            width: 44.729%;
          }
          @media screen and (min-width: 840px) and (max-width: 850px) {
            width: 47.206%;
          }
          @include breakpoint(mobile-potrait) {
            width: 100%;
          }
          &:last-child {
            @include breakpoint(desktop-extra-lite-sm) {
              margin-bottom: 0;
            }
          }
          &.business {
            width: 440px;
            @media screen and (min-width: 1600px) and (max-width: 1919px) {
              width: 415px;
            }
            @media screen and (min-width: 1440px) and (max-width: 1599px) {
              width: 335px;
            }
            @media screen and (min-width: 1201px) and (max-width: 1439px) {
              width: 303px;
            }
            @include breakpoint(desktop-extra-lite-sm) {
              width: 47.55%;
              margin-left: 0;
            }
            @media screen and (min-width: 1035px) and (max-width: 1040px) {
              width: 44.729%;
            }
            @media screen and (min-width: 840px) and (max-width: 850px) {
              width: 47.206%;
            }
            @include breakpoint(mobile-potrait) {
              width: 100%;
            }
          }
          a {
            @include breakpoint(desktop-extra-lite-sm) {
              margin-bottom: 15px;
            }
            img {
              padding-bottom: 10px;
              @include breakpoint(desktop-extra-lite-sm) {
                margin-bottom: 0px;
                padding-bottom: 0;
                zoom: 1.03;
              }
              @include breakpoint(mobile-portrait-giant) {
                zoom: normal;
              }
              @media only screen and (min-width: 810px) and (max-width: 850px) {
                zoom: normal;
              }
              @media only screen and (min-width: 375px) and (max-width: 385px) {
                zoom: 1.0009;
              }
            }
          }
        }

        .one-portrait-image {
          width: 340px;
          display: flex;
          flex-direction: column;
          @include breakpoint(desktop-extra-lite-sm) {
            width: 47.55%;
          }
          @media screen and (min-width: 1035px) and (max-width: 1040px) {
            width: 46.111%;
          }
          @include breakpoint(mobile-potrait) {
            width: 100%;
          }
          a {
            @include breakpoint(desktop-extra-lite-sm) {
              margin-bottom: 15px;
            }
            img {
              padding-bottom: 10px;
              @include breakpoint(desktop-extra-lite-sm) {
                zoom: 1.03;
                margin-bottom: 0px;
                padding-bottom: 0px;
              }
              @include breakpoint(mobile-portrait-giant) {
                zoom: normal;
              }
              @media only screen and (min-width: 810px) and (max-width: 820px) {
                zoom: normal;
              }
              @media only screen and (min-width: 375px) and (max-width: 385px) {
                zoom: 1.0009;
              }
            }
          }
        }

        .one-landscape-image {
          width: 340px;
          display: flex;
          flex-direction: column;
          @include breakpoint(desktop-extra-lite-sm) {
            width: 47.55%;
          }
          @media screen and (min-width: 1035px) and (max-width: 1040px) {
            width: 46.111%;
          }
          @include breakpoint(mobile-potrait) {
            width: 100%;
          }
          a {
            @include breakpoint(desktop-extra-lite-sm) {
              margin-bottom: 15px;
            }
            img {
              padding-bottom: 10px;
              @include breakpoint(desktop-extra-lite-sm) {
                zoom: 1.03;
                margin-bottom: 0;
                padding-bottom: 0;
              }
              @include breakpoint(mobile-portrait-giant) {
                zoom: normal;
              }
              @media only screen and (min-width: 810px) and (max-width: 820px) {
                zoom: normal;
              }
              @media only screen and (min-width: 375px) and (max-width: 385px) {
                zoom: 1.0009;
              }
            }
          }
        }
        .two-portrait-images:first-child {
          margin-left: 0;
        }
        .two-portrait-images {
          width: 340px;
          margin-left: 60px;
          display: flex;
          flex-direction: column;
          @media screen and (min-width: 1440px) and (max-width: 1599px) {
            width: 305px;
          }
          @media screen and (min-width: 1201px) and (max-width: 1439px) {
            width: 250px;
          }
          @include breakpoint(desktop-extra-lite-sm) {
            width: 47.55%;
            margin-left: 0;
            margin-bottom: 30px;
          }
          @media screen and (min-width: 1035px) and (max-width: 1040px) {
            width: 46.111%;
          }
          @include breakpoint(mobile-potrait) {
            width: 100%;
          }
          &:last-child {
            @include breakpoint(desktop-extra-lite-sm) {
              margin-bottom: 0;
            }
          }
          &.business {
            @media screen and (min-width: 1600px) {
              width: 340px;
            }
            @media screen and (min-width: 1440px) and (max-width: 1599px) {
              width: 280px;
            }
            @media screen and (min-width: 1201px) and (max-width: 1439px) {
              width: 240px;
            }
            @include breakpoint(desktop-extra-lite-sm) {
              width: 47.55%;
              margin-left: 0;
            }
            @include breakpoint(mobile-potrait) {
              width: 100%;
            }
          }
          a {
            @include breakpoint(desktop-extra-lite-sm) {
              margin-bottom: 15px;
            }
            img {
              padding-bottom: 10px;
              @include breakpoint(desktop-extra-lite-sm) {
                zoom: 1.03;
                padding-bottom: 0;
                margin-bottom: 0;
              }
              @include breakpoint(mobile-portrait-giant) {
                zoom: normal;
              }
              @media only screen and (min-width: 810px) and (max-width: 820px) {
                zoom: normal;
              }
              @media only screen and (min-width: 375px) and (max-width: 385px) {
                zoom: 1.0009;
              }
            }
          }
        }

        .gbh-full-w-menu__inner {
          .carot-title {
            margin-bottom: -20px;
            img.toggleArrow {
              @include breakpoint(desktop-extra-lite-sm) {
                display: none;
              }
            }
          }
          .menu-item-tiles {
            display: flex;
            width: 100%;
            align-items: center;
            @include breakpoint(desktop-extra-lite-sm) {
              position: relative;
              padding-bottom: 0;
            }
            .toggleArrow {
              @include breakpoint(desktop-extra-lite-sm) {
                position: absolute;
                right: 0;
                width: auto;
                display: block;
              }
            }
            .menuItems {
              p {
                @include breakpoint(desktop-extra-lite-lg) {
                  font-size: 18px !important;
                  line-height: 26px !important;
                  letter-spacing: -0.5px !important;
                }
                @include breakpoint(desktop-extra-lite-sm) {
                  line-height: 26px;
                  font-size: 18px;
                  letter-spacing: -0.5px;
                  font-family: $helvetica-regular;
                  color: $charcoal;
                }
                b {
                  @include breakpoint(desktop-extra-lite-sm) {
                    font-weight: normal;
                  }
                }
              }
              p {
                b {
                  letter-spacing: -0.4px;
                }
              }
            }
            &::before {
              @include breakpoint(desktop-extra-lite-sm) {
                display: block;
                position: absolute;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                pointer-events: none;
              }
            }
          }
          .menuItems {
            color: $color-charcoal;
            padding-right: 5px;
            padding-bottom: 5px;
            @include breakpoint(desktop-extra-lite-sm) {
              padding-right: 5px;
              padding-bottom: 0px;
              text-align: left;
              color: unset;
              letter-spacing: unset;
            }
            &.business {
              line-height: 26px;
              font-size: 18px;
              letter-spacing: -0.35px;
            }
            &.carot-icon {
              letter-spacing: -0.3px;
              font-size: 14px;
              .carrot-icon {
                @include breakpoint(desktop-extra-lite-lg) {
                  line-height: 22px !important;
                  letter-spacing: -0.3px !important;
                  font-size: 14px !important;
                }
                @include breakpoint(desktop-extra-lite-sm) {
                  line-height: 22px;
                  letter-spacing: -0.3px;
                  font-size: 14px;
                  font-weight: 700;
                }
                b {
                  @include breakpoint(desktop-extra-lite-sm) {
                    font-weight: 700;
                  }
                }
              }
            }
          }
          p.group-title {
            padding-bottom: 0px;
          }
          .group-title {
            line-height: 22px;
            letter-spacing: -0.3px;
            color: $color-slate;
            padding-bottom: 20px;
            font-size: 14px !important;
            justify-self: left;
            @include breakpoint(desktop-extra-lite-sm) {
              padding: 0px 100px;
              width: 100%;
              text-align: initial;
              margin-bottom: 15px;
              display: none;
            }
            @include breakpoint(mobile-potrait) {
              padding: 0px 49px;
            }
            @include breakpoint(mobile-portrait-giant) {
              padding: 0px 5rem;
            }
          }
          .navigation-img {
            display: block;
            padding-bottom: 5px;
            @include breakpoint(desktop-extra-lite-sm) {
              padding-bottom: 0;
            }
            img {
              display: block !important;
              @include breakpoint(desktop-extra-lite-sm) {
                width: auto;
              }
            }
          }
          .navigation-external-img {
            img {
              display: block !important;
              padding-left: 5px;
              @include breakpoint(desktop-extra-lite-sm) {
                height: 2rem;
              }
            }
          }
          .menuItems {
            &:hover {
              text-decoration: underline;
              font-family: $helvetica-regular;
              cursor: pointer;
              @include breakpoint(desktop-extra-lite-sm) {
                text-decoration: none;
              }
            }
            .empty {
              &:hover {
                text-decoration: none;
                cursor: default;
              }
            }
          }
          .levelTwoItems {
            color: $color-charcoal !important;
          }

          .menu-container {
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 20px 2.6% 40px;
            @include breakpoint(desktop-extra-lite-sm) {
              display: block;
              padding: 0;
            }
          }
          .menu-container {
            &.dark-theme {
              li,
              ul,
              ol,
              li span,
              ul span,
              p,
              p b,
              b,
              a {
                color: $color-white;
              }
              background-color: $color-black;
              .gbh-full-w-menu__right-img {
                .block-images {
                  span p {
                    color: $color-light-gray;
                    @include breakpoint(desktop-extra-lite-sm) {
                      color: $color-extra-light-gray;
                    }
                  }
                }
              }
              .group-title {
                color: $color-light-gray;
              }
              .gbh-full-w-menu__left-cont__top {
                .level-one-container {
                  li {
                    .menu-labels {
                      color: $color-white;
                    }
                  }
                }
                .level-one-container {
                  .levelTwoMapping {
                    > li {
                      &.tile-bg {
                        @include breakpoint(desktop-extra-lite-sm) {
                          background-color: $color-charcoal !important;
                        }
                      }
                    }
                  }
                }
              }
              button {
                &.gbh-mob-back-btn {
                  @include breakpoint(desktop-extra-lite-sm) {
                    background: $color-black;
                    color: $color-white;
                  }
                  &:before {
                    @include breakpoint(desktop-extra-lite-sm) {
                      content: url(/icons/white-left-bold.svg);
                    }
                  }
                }
              }
              .menu-item-tiles {
                .toggleArrow {
                  @include breakpoint(desktop-extra-lite-sm) {
                    -webkit-filter: invert(100%) sepia(100%) saturate(0)
                      hue-rotate(346deg) brightness(104%) contrast(102%);
                    filter: invert(100%) sepia(100%) saturate(0)
                      hue-rotate(346deg) brightness(104%) contrast(102%);
                  }
                }
              }
              .gbh-full-w-menu__right-img {
                ol {
                  &.multicta {
                    .ctalable {
                      p {
                        @include breakpoint(desktop-extra-lite-sm) {
                          color: $color-white;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .gbh-full-w-menu__left-cont {
            padding: 0;
            flex: 1;
            width: auto;
            &.products-heavy {
              @media screen and (min-width: 1920px) {
                flex: none;
              }
            }
            &.products-light {
              flex: none;
            }
            &.business {
              flex: none;
            }
            .gbh-full-w-menu__left-cont__top {
              width: 100%;
              .nav .flex-column .gbh-full-w-menu__left-cont__menu-lis {
                width: 100%;
              }
              .levelZero {
                width: 100%;
              }
              .level-one-nav-multi {
                display: flex;
                column-gap: 60px;
                @include breakpoint(desktop-extra-lite-sm) {
                  display: block;
                }
              }
              .level-one-container {
                display: flex;
                column-gap: 60px;
                @include breakpoint(desktop-extra-lite-sm) {
                  margin-bottom: 30px;
                }
                .level-one-sub-container {
                  display: grid;
                  width: 100%;
                  @include breakpoint(desktop-extra-lite-sm) {
                    padding-top: 85px;
                  }
                }
                .levelTwoMapping {
                  row-gap: 40px;
                  display: flex;
                  flex-direction: column;
                  outline: 0;
                  text-align: left;
                  width: 100%;
                  @include breakpoint(desktop-extra-lite-sm) {
                    row-gap: initial;
                  }
                  &:first-child {
                    > li {
                      &:first-child {
                        @include breakpoint(desktop-extra-lite-sm) {
                          margin-top: 0;
                        }
                      }
                    }
                  }
                  > li {
                    @include breakpoint(desktop-extra-lite-sm) {
                      padding: 0px 100px;
                      margin: 15px 0 0 0;
                    }
                    @include breakpoint(mobile-potrait) {
                      padding: 0px 49px;
                    }
                    @include breakpoint(mobile-portrait-giant) {
                      padding: 0 5rem;
                    }
                    &.tile-bg {
                      @include breakpoint(desktop-extra-lite-sm) {
                        background-color: $color-extra-light-gray !important;
                        padding-bottom: 30px;
                        padding-top: 5px;
                      }
                    }
                  }
                  .nav-item {
                    padding-bottom: 5px;
                    @include breakpoint(desktop-extra-lite-sm) {
                      padding-top: 16px;
                      margin: 0;
                      padding-bottom: 0;
                    }
                  }

                  &.products-light {
                    @media screen and (min-width: 1201px) and (max-width: 1439px) {
                      max-width: 197px;
                    }
                  }
                  &.business {
                    row-gap: 5px;
                    @media screen and (min-width: 1201px) and (max-width: 1439px) {
                      max-width: 197px;
                    }
                  }
                  &.business-one-col {
                    row-gap: 5px;
                  }
                }
                .menu-labels {
                  color: $color-charcoal;
                  display: flex;
                  align-items: center;
                  line-height: 22px;
                  font-size: 15px;
                  letter-spacing: -0.4px;
                  p {
                    letter-spacing: -0.4px;
                    @include breakpoint(desktop-extra-lite-lg) {
                      font-size: 16px !important;
                      line-height: 24px !important;
                      letter-spacing: -0.4px !important;
                    }
                  }
                  @include breakpoint(desktop-extra-lite-sm) {
                    display: block;
                    padding: 0;
                    line-height: 24px;
                    font-size: 16px;
                    letter-spacing: -0.4px;
                  }
                }
                .menu-labels:hover {
                  text-decoration: underline;
                  cursor: pointer;
                  padding: 0;
                  font-family: $helvetica-regular;
                  @include breakpoint(desktop-extra-lite-sm) {
                    text-decoration: underline;
                    font-family: $helvetica-regular;
                  }
                }
                .empty:hover {
                  text-decoration: none;
                  cursor: default;
                  font-family: $helvetica-regular;
                }
              }
            }
          }
          .gbh-full-w-menu__right-img {
            flex-direction: column;
            @include breakpoint(desktop-extra-lite-sm) {
              display: block;
              padding: 0 100px;
            }
            @include breakpoint(mobile-potrait) {
              display: block;
              padding: 0 3.0625rem;
            }
            @include breakpoint(mobile-portrait-giant) {
              padding: 0 5rem;
            }
            .right-img-cta {
              margin-bottom: 20px;
              @include breakpoint(desktop-extra-lite-sm) {
                margin-bottom: 30px;
              }
            }
            ol.multicta {
              margin-left: 0px;
              margin-bottom: 20px;
              .navigationcta {
                display: flex;
                flex-direction: row;
                height: 23px;
                align-items: center;
              }
              .ctalable {
                padding-right: 5px;
                p {
                  font-family: $helvetica-regular;
                  font-size: 14px;
                  line-height: 22px;
                  letter-spacing: -0.3px;
                  color: $color-charcoal;
                  @include breakpoint(desktop-extra-lite-sm) {
                    font-family: $helvetica-regular;
                    font-weight: 700;
                  }
                  b {
                    @include breakpoint(desktop-extra-lite-sm) {
                      font-weight: 700;
                    }
                  }
                }
              }
              .ctalable {
                &:hover {
                  text-decoration: underline;
                }
              }
              .empty {
                &:hover {
                  text-decoration: none;
                  cursor: default;
                }
              }
            }

            .cta-img {
              margin-bottom: 4px;
            }
            .block-images {
              display: flex;
              flex-direction: row;
              @include breakpoint(desktop-extra-lite-sm) {
                justify-content: space-between;
              }
              @include breakpoint(mobile-potrait) {
                flex-direction: column;
              }
            }
          }
        }
        .image-cta {
          display: flex;
          color: $color-charcoal !important;
          line-height: 22px;
          align-items: center;
          margin-top: 10px !important;
          font-size: 14px;
          font-family: $font-medium;
          padding-right: 5px;
          .div-of-cta {
            text-align: left;
            padding-right: 5px;
            letter-spacing: -0.3px;
            a {
              color: $color-charcoal;
              font-family: $helvetica-regular;
              font-size: 14px;
              line-height: 22px;
              letter-spacing: -0.3px;
              font-weight: 700;
              @include breakpoint(desktop-extra-lite-sm) {
                font-family: $helvetica-regular;
                font-weight: 700;
              }
            }
          }
          .div-of-cta {
            a {
              &:hover {
                text-decoration: underline;
              }
            }
            .empty {
              &:hover {
                cursor: default;
                text-decoration: none;
              }
            }
          }
          .cta-link-caret {
            padding-bottom: 4px;
            @include breakpoint(desktop-extra-lite-sm) {
              padding-bottom: 0;
            }
          }
        }
      }
      button {
        &.gbh-mob-back-btn {
          @include breakpoint(desktop-extra-lite-sm) {
            display: block;
            text-align: center;
            color: $charcoal;
            font-size: 18px;
            line-height: 26px;
            letter-spacing: -0.35px;
            padding: 20px 35px 20px 25px;
            position: fixed;
            background: $color-white;
            z-index: 9;
          }
          &:before {
            @include breakpoint(desktop-extra-lite-sm) {
              left: 32px;
              position: absolute;
              content: url(/icons/left-arrow-bold.svg);
              display: block;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              pointer-events: none;
            }
            @include breakpoint(mobile-potrait) {
              left: 18px;
            }
          }
          &:first-child:not(.af-icon-button):hover {
            @include breakpoint(desktop-extra-lite-sm) {
              background: $color-white;
            }
          }
        }
      }
    }

    .gbh-global-tab {
      .cmp-tabs {
        &__tabpanel {
          .globalnavigation {
            .gbh-full-w-menu {
              height: auto;
              transition: height 0.2s linear;
            }
          }
        }
        &__tabpanel--active {
          .globalnavigation {
            .gbh-full-w-menu {
              overflow-y: auto;
              max-height: 75vh;
              transition: height 0.2s linear;
              @include breakpoint(desktop-extra-lite-sm) {
                overflow-y: unset;
                height: 100%;
                max-height: 100%;
                background-color: $white;
              }
              .bg-dim {
                height: inherit;
              }
            }
          }
        }
        .cmp-tabs {
          &__tablist {
            .cmp-tabs {
              &__tab:hover {
                text-decoration: underline;
                font-family: $helvetica-bold;
                @include breakpoint(desktop-extra-lite-sm) {
                  text-decoration: none;
                  font-family: $helvetica-regular;
                }
              }
              &__tab--active {
                text-decoration: underline;
                font-family: $helvetica-bold;
                letter-spacing: -0.3px !important;
                @include breakpoint(desktop-extra-lite-sm) {
                  text-decoration: none;
                }
              }
              &__tab:first-child {
                margin-right: 25px;
              }
              &__tab:first-child:after {
                border-right: 0;
                position: absolute;
                height: 2pc;
                content: "";
                right: -25px;
                top: 1px;
              }
              &__tab {
                text-transform: none;
                line-height: 22px;
                font-size: 15px;
                letter-spacing: -0.4px;
                padding: 19px 0px;
                @include breakpoint(desktop-extra-lite-sm) {
                  padding-top: 15px;
                  padding-bottom: 0;
                  margin: 0;
                  line-height: 30px;
                  font-size: 22px;
                  letter-spacing: -0.75px;
                  font-family: $helvetica-regular;
                }
                @include breakpoint(desktop-extra-lite-lg) {
                  font-size: 22px !important;
                  line-height: 30px !important;
                  letter-spacing: -0.75px !important;
                }
              }
            }
            .tab-panel-container {
              @include breakpoint(desktop-extra-lite-sm) {
                padding-top: 15px;
                padding-bottom: 0;
                margin: 0;
                line-height: 30px;
                font-size: 22px;
                letter-spacing: -0.75px;
                font-family: $helvetica-regular;
              }
            }
          }
        }
      }
      .cmp-tabs {
        &.dark-theme {
          .cmp-tabs__tabpanel--active {
            .globalnavigation {
              .gbh-full-w-menu {
                @include breakpoint(desktop-extra-lite-sm) {
                  background-color: $color-black;
                }
              }
            }
          }
        }
      }
    }
    .gbh-global-tab {
      .cmp-tabs {
        .cmp-tabs {
          &__tablist {
            .cmp-tabs {
              &__tab:hover {
                text-decoration: underline;
                font-family: $helvetica-bold;
                @include breakpoint(desktop-extra-lite-sm) {
                  text-decoration: none;
                  font-family: $helvetica-regular;
                }
              }

              &__tab:first-child {
                margin-right: 25px;
                @include breakpoint(desktop-extra-lite-sm) {
                  margin-right: 0;
                  padding-top: 0;
                }
              }
              &__tab:first-child:after {
                border-right: 0;
                position: absolute;
                height: 2pc;
                content: "";
                right: -25px;
                top: 1px;
              }
              &__tab {
                .cmp-tabs {
                  &__tab-active {
                    text-decoration: underline;
                    font-family: $helvetica-bold;
                    @include breakpoint(desktop-extra-lite-sm) {
                      text-decoration: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
