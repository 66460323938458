@import "@/styles/mixins/_breakpoint";
@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";

.favorites {
  padding-bottom: 60px;

  @media (max-width: $XSMobile) {
    padding-bottom: 30px;
  }
  .masonry {
    -webkit-column-gap: 3em;
    -moz-column-gap: 3em;
    column-gap: 3em;
    margin-bottom: 3em;
    padding: 0;

    .item {
      display: inline-block;
      padding: 0;
      margin: 0 0 3em 0;
      width: 100%;
      transition: 1s ease all;
      -webkit-transition: 1s ease all;
      -moz-transition: 1s ease all;

      img {
        max-width: 100%;
      }
    }
  }

  &__close {
    cursor: pointer;
  }

  &__container {
    max-width: 86.25rem;
  }

  &__header {
    background: $color-white;
  }

  &__title {
    font-family: $font-light;
    color: $color-slate;
    font-size: 100px;
    letter-spacing: -7.5px;
    line-height: 110px;
    margin: 0;
  }

  &__tab-header {
    justify-content: center;
    border-bottom: 1px solid $color-dark-gray;
    margin-top: 60px;
  }

  &__tab-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__tab-button {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom-color: transparent;
    border-bottom-width: 4px;
    background-color: transparent;
    padding: 0px 20px 15px 20px;
    text-align: center;
    font-family: $font-regular;
    font-size: 18px;
    font-style: normal;
    line-height: 26px;
    letter-spacing: -0.5px;
    color: $color-dark-gray;

    &.--active {
      border-bottom: 4px solid $color-charcoal;
    }
  }

  &__favorite-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 18px;
    margin-bottom: -20px;
    min-height: 74px;

    .sort {
      margin-left: 0;

      &__drop-down {
        top: 96px;
        right: 14px;
      }
    }
  }

  &__manage-tools {
    color: $color-dark-gray;
    font-family: $font-regular;
    font-size: 16px;
    letter-spacing: -0.55px;
    line-height: 24px;
    position: relative;
    background: transparent;
    border: none;
    padding-left: 20px;

    .manage-tools-icon {
      position: absolute;
      left: 0;
      top: 23%;
      margin: 0;
    }
  }

  &__sticky-nav-wrap {
    opacity: 0;
    z-index: 4;
    display: none;

    &.show-nav-wrap {
      position: absolute;
      display: block;
      width: 100%;
      left: 0;
      top: 20px;
      background: #fff;
      opacity: 1;
      transition: opacity 0.5s ease-in-out;
    }
  }

  &__sticky-nav {
    background: $color-white;
    transition: all 0.3s ease;
    padding-right: 15px !important;
    padding-left: 15px !important;

    &--shadow {
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
    }

    .manage-strip-controls {
      display: flex;
      justify-content: space-between;
      padding: 20px;
      position: relative;
      align-items: center;

      .manage-selected-items {
        color: $color-slate;
        font-family: $font-light;
        font-size: 26px;
        letter-spacing: -1.5px;
        line-height: 34px;
      }

      .close-icon {
        cursor: pointer;

        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  &__controls {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .manage-menu-item {
      display: inline-block;
      margin-right: 20px;
      color: $color-charcoal;
      font-family: $font-regular;
      font-size: 18px;
      letter-spacing: -0.45px;
      line-height: 24px;
      margin-right: 30px;
      cursor: pointer;

      &:last-child {
        margin-right: 40px;
      }

      &--disable-remove {
        cursor: default;
        color: $color-dark-gray;
      }
    }
  }

  &__tab-content {
    margin-top: 53px;
    margin-bottom: 68px;

    .products-tab {
      .product-tile {
        width: auto;
        margin: 10px;
      }
    }

    .image-tile {
      cursor: pointer;

      .product-tile__price,
      .product-tile__discount-price {
        padding-right: 0;
        text-align: right;

        @media screen and (max-width: 767px) {
          padding-right: 0;
          padding-left: 0;
          text-align: left;
        }
      }

      img {
        width: 100%;
      }

      .product-tile__image {
        padding-bottom: 133.4%;
      }
    }

    .fav-image-selected {
      img {
        border: 1px solid $slate;
        border-radius: 4px;
        margin: 2px;
        padding: 1px;
      }
    }

    .product-tile__color-swatch {
      img {
        width: 20px;
      }
    }

    .video-tile {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .play-icon {
        background: rgba(0, 0, 0, 0.7);
        position: absolute;
        display: flex;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        justify-content: center;
        align-items: center;
      }

      video {
        width: 100%;
      }
    }

    .article-tile {
      display: block;
      background: $color-extra-light-gray;
      text-decoration: none;
      cursor: pointer;

      .content-section {
        padding: 20px;

        .category-name {
          color: $color-slate;
          font-family: $font-medium;
          font-size: 16px;
          letter-spacing: -0.35px;
          line-height: 22px;
        }

        .article-title {
          color: $color-slate;
          font-family: $font-light;
          font-size: 26px;
          letter-spacing: -1.5px;
          line-height: 34px;
          margin-bottom: 10px;

          @media screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.55px;
            font-family: $font-regular;
          }
        }

        .sub-title {
          color: $color-slate;
          font-family: $font-regular;
          font-size: 20px;
          letter-spacing: -0.65px;
          line-height: 28px;
          margin-bottom: 10px;

          @media screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.45px;
          }
        }

        .description {
          color: $color-dark-gray;
          font-family: $font-regular;
          font-size: 16px;
          letter-spacing: -0.55px;
          line-height: 24px;

          @media screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 22px;
            letter-spacing: -0.45px;
          }
        }
      }

      .image-section {
        max-height: 300px;

        img {
          height: 300px;
          object-fit: cover;
          width: 100%;
          display: block;
        }
      }
    }
  }

  &__modal {
    max-width: 420px;
  }

  &__modal-title {
    font-family: $font-light;
    font-size: 36px;
    letter-spacing: -1.7px;
    line-height: 44px;
    color: $color-slate;
    padding-top: 35px;
  }

  &__modal-desc {
    margin: 20px 0 40px;
    font-family: $font-regular;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.65px;
    color: $color-dark-gray;
  }

  &__modal-cta {
    display: flex;
    column-gap: 20px;

    .button {
      width: 100%;
    }
  }

  &__load-more {
    width: 100%;
    height: 40px;
    background: $color-white;
    border: solid 1px $color-slate;
    font-family: $font-medium;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: -0.25px;
    text-align: center;
    color: $color-slate;
    transition: all 0.3s ease-in-out;
    margin-bottom: 43px;

    &:hover {
      background: $color-slate;
      color: $color-white;
    }
  }

  &__back-to-top {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  &__go-back {
    display: flex;
    font-family: $font-regular;
    font-size: 18px;
    line-height: 1.33;
    letter-spacing: -0.45px;
    color: $color-charcoal;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    outline: none;

    img {
      height: 20px;
      margin-left: 15px;
    }
  }

  .edit-wrapper {
    position: relative;
    margin-bottom: 50px;
    max-width: 300px;

    &.--selected {
      border-radius: 4px;
      padding: 2px;
      border: 1px solid $color-extra-light-gray;
    }

    .icon-select {
      position: absolute;
      top: 10px;
      left: 10px;
      width: 36px;
      height: 36px;
      cursor: pointer;
      background: $color-white;
      border: 1px solid $color-extra-light-gray;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  @media screen and (max-width: 576px) {
    .modal-popup {
      .modal-popup-content {
        min-height: auto;
        width: 90%;

        .modal-popup-body {
          padding: 50px 0 0 0;
        }
      }
    }

    .kf-react-container {
      @media screen and (max-device-width: 991px) and (orientation: landscape) {
        .kf-react-button.large {
          padding: 0;
        }
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 896px) {
    .modal-popup {
      .modal-popup-content {
        .modal-popup-body {
          padding: 50px 30px 30px 30px;
        }
      }
    }
  }

  @media screen and (max-width: 810px),
    only screen and (max-width: 1024px) and (orientation: landscape) {
    &__modal-desc {
      margin: 15px 0 40px;
    }
  }
}

.favorites-lightbox {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1004;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  overflow-y: auto;

  &__modal {
    background: $color-white;
    margin: auto;
    position: relative;
    width: 50%;
    padding: 10px;
    text-align: center;

    @include breakpoint(desktop-small) {
      width: 501px !important;
    }

    @include breakpoint(mobile-landscape) {
      width: 476px;
    }

    @include breakpoint(mobile-potrait) {
      padding: 5px;
      width: 339px;
    }

    &__scroll {
      width: 100%;
    }

    video {
      width: 100%;
    }

    &-img {
      width: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: 50% 50%;
      object-position: 50% 50%;
      mix-blend-mode: multiply;
      height: 100%;

      @include breakpoint(desktop-small) {
        width: 481px;
        height: 361px;
      }

      @include breakpoint(mobile-landscape) {
        width: 456px;
        height: 342px;
      }

      @include breakpoint(mobile-potrait) {
        width: 329px;
        height: 247px;
      }
    }
  }

  &__close {
    display: flex;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    background: $white;
    width: 30px;
    height: 30px;
    align-items: center;
    z-index: 1;

    img {
      cursor: pointer;
      width: 16px;
      height: 16px;
    }
  }
}

.favorite-no-results {
  padding: 0 65px 65px 110px;
  width: 599px;

  .no-result-description {
    p {
      color: $color-slate;
      font-family: $font-light;
      font-size: 60px;
      letter-spacing: -3.1px;
      line-height: 68px;
      margin-top: 60px;
    }
  }

  .redirect-to-shopping {
    color: $color-slate;
    font-family: $font-regular;
    font-size: 20px;
    letter-spacing: -0.65px;
    line-height: 28px;
    margin-top: 40px;

    p {
      color: $color-slate;
      font-family: $font-regular;
      font-size: 20px;
      letter-spacing: -0.65px;

      a {
        color: $color-slate;
        font-family: $font-regular;
        font-size: 20px;
        letter-spacing: -0.65px;
        text-decoration: none;
        border-bottom: 1px solid $color-slate;
      }
    }
  }
}

.my-masonry-grid {
  display: flex;
  margin-left: -60px;
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 60px;
  background-clip: padding-box;
}

@media screen and (min-width: 1280px) {
  .favorites-lightbox {
    &__modal {
      width: 30%;
    }
  }
}

@media only screen and (max-width: 320px) {
  .favorites {
    .masonry {
      -moz-column-count: 1;
      -webkit-column-count: 1;
      column-count: 1;
    }
  }
}

@media only screen and (max-width: 810px),
  only screen and (max-width: 990px) and (orientation: landscape) {
  .favorites {
    &__back-to-top {
      border-top: solid 1px $color-light-gray;
      padding-top: 20px;
      justify-content: flex-end;
    }

    .edit-wrapper {
      width: 100%;
      max-width: 100%;
    }
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .favorites {
    .masonry {
      -moz-column-count: 2;
      -webkit-column-count: 2;
      column-count: 2;
    }
  }
}

@media only screen and (min-width: 768px) {
  .favorites {
    .masonry {
      -moz-column-count: 3;
      -webkit-column-count: 3;
      column-count: 3;
    }
  }
}

@media only screen and (max-width: 414px) {
  .favorites {
    .product-tile {
      &__description {
        display: none;
      }

      &__details {
        display: block;
      }

      &__price {
        padding-left: 0;
      }
    }
  }
}

@media only screen and (max-width: 860px) and (orientation: landscape),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .favorites {
    .product-tile {
      &__colors--swatch {
        display: none;
      }

      &__compare-main {
        height: 0;
      }
    }

    &__tab-content {
      margin-top: 31px;
    }

    .edit-wrapper {
      margin-bottom: 20px;
    }

    .article-tile {
      .content-section {
        padding: 15px 10px;
      }

      .image-section {
        max-height: 360px;

        img {
          height: 360px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .favorites {
    .product-tile {
      &__colors--swatch {
        display: none;
      }

      &__compare-main {
        height: 0;
      }
    }

    &__tab-content {
      margin-top: 43px;
    }

    .edit-wrapper {
      margin-bottom: 20px;
    }

    .article-tile {
      .content-section {
        padding: 15px 10px;
      }

      .image-section {
        max-height: 160px;

        img {
          height: 160px;
        }
      }
    }

    &__close {
      float: right;
    }
  }
}

@media screen and (max-width: 1024px) {
  .my-masonry-grid {
    display: flex;
    margin-left: -20px;
    width: auto;
  }

  .my-masonry-grid_column {
    padding-left: 20px;
    background-clip: padding-box;
  }

  .edit-wrapper {
    margin-bottom: 20px;
  }

  .favorites {
    &__title {
      font-size: 68px;
      letter-spacing: -4.5px;
      line-height: 60px;
    }

    &__sticky-nav {
      .manage-strip-controls {
        .close-icon {
          right: 20px;
        }
      }
    }

    &__controls {
      .manage-menu-item {
        font-size: 16px;
      }
    }

    &__tab-button {
      font-size: 20px;
      display: flex;
      gap: 5px;
    }

    &__load-more {
      margin: 0 18px 40px 18px;
    }

    &__back-to-top {
      margin: 0 18px;
    }

    .modal-popup {
      .modal-popup-content {
        .modal-popup-body {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .favorites {
    .modal-popup {
      .modal-popup-content {
        .modal-popup-body {
          padding: 50px 0 10px 0;
        }
      }
    }

    .masonry {
      .item {
        img {
          width: 100%;
        }
      }
    }

    &__title {
      font-size: 46px;
      letter-spacing: -2px;
      line-height: 50px;
    }

    &__tab-buttons {
      justify-content: flex-start;
    }

    &__tab-content {
      margin-top: 43px;
      margin-bottom: 68px;

      .video-tile {
        .play-icon {
          img {
            width: auto !important;
          }
        }
      }
    }

    &__tab-header {
      margin-top: 0;
      overflow-x: scroll;
    }

    &__controls {
      display: block;
    }

    &__sticky-nav {
      .manage-strip-controls {
        display: block;
        justify-content: space-between;
        padding: 20px;
        position: relative;
        align-items: center;
      }
    }

    &__sticky-nav-wrap {
      z-index: 4;
      display: none;
    }

    &__modal {
      padding: 0 30px 10px 30px;
    }

    &__modal-title {
      font-size: 32px;
      letter-spacing: -2.2px;
      line-height: 40px;
    }

    &__modal-desc {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.55px;
    }

    &__modal-cta {
      display: block;

      a {
        margin-bottom: 20px;
      }
    }
  }

  .favorites-lightbox {
    &__modal {
      width: 90%;
    }
  }

  .favorite-no-results {
    padding: 0;
    width: 100%;

    .no-result-description {
      p {
        font-size: 40px;
        letter-spacing: -2.5px;
        line-height: 60px;
        margin-top: 40px;
      }
    }

    .redirect-to-shopping {
      margin-top: 35px;
    }
  }
}

@media screen and (max-width: 767px) {
  .favorites__tab-content {
    margin-top: 43px;

    .product-tile__description {
      display: none;
    }

    .product-tile__price {
      padding-left: 0px;
    }

    .product-tile__details {
      flex-direction: column;
    }

    .article-tile {
      .content-section {
        padding: 15px 10px;
      }
    }
  }
}

.fav-selcted-color-name {
  display: none;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.4px;
  color: $dark-gray;
  font-family: $helvetica-regular;
  margin: 5px 0 0 0;

  @media all and (max-width: 990px) {
    display: none;
  }
}

.product-tile {
  @media all and (min-width: 990px) {
    &:hover {
      .fav-selcted-color-name {
        display: block;
      }
    }
  }
}

@import "@/styles/mixins/_breakpoint";

.favorites-container {
  @include breakpoint(tab-max) {
    margin-bottom: 0 !important;
  }

  .kf-react-container {
    @include breakpoint(tab-max) {
      padding: 0 !important;
    }

    .col-lg-3 {
      @include breakpoint(tab-max) {
        padding: 0 32px !important;
      }
      @media (max-width: $XSMobile) {
        padding: 0 18px !important;
      }
    }
  }

  .myListsWrapper {
    width: 100%;

    .myListHeader {
      display: flex;
      flex-direction: row;

      @include breakpoint(tab-max) {
        display: none;
      }

      .myListHeaderText {
        flex: 1;
        font-family: $font-regular;
        font-size: 45px;
        font-style: normal;
        line-height: 56px;
        letter-spacing: -3.187px;
        color: $color-slate;
      }
      .myListCreateNew {
        font-family: $font-regular;
        font-size: 20px;
        font-style: normal;
        line-height: 28px;
        letter-spacing: -0.4px;
        color: $color-slate;
        text-decoration: underline;

        &:hover {
          cursor: pointer;
        }
      }
    }
    .myListTabNamesContainer {
      width: 100%;
      height: 60px;
      display: flex;
      border-bottom: 1px solid $color-dark-gray;
      .myListTabNames {
        display: flex;
        margin: 0 auto;
        margin-top: 15px;
        flex-direction: row;

        @media (max-width: $XSMobile) {
          margin-left: 18px;
        }

        .tabItem {
          display: flex;
          padding: 0px 20px 15px 20px;
          justify-content: center;
          align-items: center;
          align-self: stretch;
          .tabNameText {
            text-align: center;
            font-family: $font-regular;
            font-size: 18px;
            font-style: normal;
            line-height: 26px;
            letter-spacing: -0.5px;
            color: $color-dark-gray;

            &:hover {
              cursor: pointer;
            }
          }
        }
        .tabItemActive {
          border-bottom: 4px solid $color-slate;

          .tabNameText {
            color: $color-slate;
          }
        }
      }
    }
    .createNewAndSort {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 60px;
      padding: 30px 0px 30px 0px;

      @include breakpoint(tab-landscape) {
        margin-top: 30px;
        padding: 30px 27px;
      }
      @include breakpoint(tab-small) {
        margin-top: 30px;
        padding: 30px 32px;
      }
      @media (max-width: $XSMobile) {
        margin-top: 0px;
        padding: 30px 18px;
      }
      .createNewList {
        color: $color-slate;
        font-family: $font-regular;
        font-size: 16px;
        font-style: normal;
        line-height: 24px;
        letter-spacing: -0.35px;
        text-decoration: underline;
        &:hover {
          cursor: pointer;
        }
      }
      .sortItem {
        display: flex;
        flex-direction: row;
        gap: 10px;
        .sort__drop-down {
          top: 268px;
        }
      }
    }
    .sortContainer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 60px;

      @include breakpoint(tab-landscape) {
        padding: 0px 27px 0px 27px;
      }

      @media (max-width: $cartToster) {
        padding: 0px 32px 0px 32px;
      }

      @include breakpoint(tab-max) {
        margin-top: 30px;
      }

      @media (max-width: $XSMobile) {
        padding: 0px 18px;
      }

      .sortItem {
        display: flex;
        flex-direction: row;
        gap: 10px;
        .sort__drop-down {
          top: 135px;
          @include breakpoint(desktop-small) {
            top: 245px;
          }
        }
      }
    }
    .userListEmptyContainer {
      display: flex;
      flex-direction: column;
      gap: 30px;

      @include breakpoint(tab-max) {
        padding: 0px 27px;
      }

      @media (max-width: $cartToster) {
        padding: 0px 32px;
      }

      @media (max-width: $XSMobile) {
        padding: 0px 18px;
      }

      .userListEmptyText {
        color: $color-slate;
        font-family: $font-regular;
        font-size: 26px;
        font-style: normal;
        line-height: 34px;
        letter-spacing: -1.5px;
        margin-top: 30px;
        @media (max-width: $XSMobile) {
          font-size: 20px;
          line-height: 26px;
          letter-spacing: -1.54px;
          margin-top: 20px;
        }
      }
      .bestSellersBtn {
        display: flex;
        padding: 17px 60px;
        justify-content: center;
        width: fit-content;
        align-items: center;
        gap: 10px;
        border: 1px solid $color-slate;
        &:hover {
          cursor: pointer;
        }

        .bestSellersBtnTxt {
          color: $color-slate;
          text-align: center;
          font-family: $font-regular;
          font-size: 18px;
          font-style: normal;
          line-height: 26px;
          letter-spacing: -0.35px;
        }
      }
    }
    .userListesConatiner {
      display: flex;
      margin-right: 0;
      width: auto;
      gap: 30px;

      @media (min-width: 813px) and (max-width: 1024px) {
        padding: 0px 27px;
      }

      @include breakpoint(tab-max) {
        gap: 12px;
      }

      @media (max-width: $cartToster) {
        padding: 0px 32px;
      }

      @media (max-width: $XSMobile) {
        margin-left: 0px;
        padding: 0px 18px;
      }

      .userListesConatinerColumn {
        flex: 1;

        background-clip: padding-box;
        max-width: calc(33.333% - 20px);

        @include breakpoint(tab-max) {
          max-width: calc(50% - 6px);
        }

        @media (max-width: $XSMobile) {
          max-width: 100%;
          padding-left: 0px;
        }

        .userListTile {
          width: 100%;
          display: flex;
          flex-direction: column;
          margin-top: 30px;
          position: relative;
          .listImage {
            cursor: pointer;
            width: 100%;
            height: 398.13px;
            position: relative;

            @include breakpoint(desktop-xxxlg) {
              height: 398.13px;
            }
            @include breakpoint(desktop-mid-max) {
              height: 270.13px;
            }
            @include breakpoint(tab-max) {
              height: 383.2px;
            }
            @media (max-width: $cartToster) {
              height: 294.4px;
            }
            @media (max-width: $XSMobile) {
              height: 169.5px;
            }

            .product-image {
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: 50% 50%;
                mix-blend-mode: multiply;
                transition: all 0.5s ease-in-out;

                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
          .listDetailsContainer {
            width: 97%;
            max-width: 100%;
            margin-top: 10px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 10px;
            position: relative;
            .listDetails {
              display: flex;
              flex: 0 0 80%;
              max-width: 80%;
              flex-direction: column;

              .listName {
                max-width: 83%;
                white-space: nowrap;
                color: $color-slate;
                font-family: $font-regular;
                font-size: 20px;
                font-style: normal;
                line-height: 28px;
                letter-spacing: -0.65px;
                text-decoration-line: underline;
                text-decoration-style: solid;
                text-decoration-skip-ink: none;
                text-decoration-thickness: auto;
                text-underline-offset: auto;
                margin-bottom: 5px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;

                cursor: pointer;

                .listNameToolTip {
                  position: absolute;
                  top: -50%;
                  left: 0%;
                  background: rgba(0, 0, 0, 0.8);
                  color: #fff;
                  padding: 5px 10px;
                  border-radius: 6px;
                  white-space: normal;
                  max-width: 200%;
                  z-index: 1000000000000;
                  visibility: hidden;
                  font-family: $font-regular;
                  font-size: 16px;
                  font-style: normal;
                  line-height: 20px;
                  letter-spacing: -0.4px;
                  &::after {
                    content: "";
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    margin-left: -5px;
                    border-width: 5px;
                    border-style: solid;
                    border-color: black transparent transparent transparent;
                  }
                }
                &:hover .listNameToolTip {
                  visibility: visible;
                }

                @media (max-width: $XSMobile) {
                  font-size: 16px;
                  line-height: 24px;
                  letter-spacing: -0.55px;
                }
              }
              .listUpdatedProject {
                height: 22px;
                color: $color-dark-gray;
                font-family: $font-regular;
                font-size: 14px;
                font-style: normal;
                line-height: 22px;
                letter-spacing: -0.45px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                word-wrap: break-word;
                white-space: normal;
                @media (min-width: 376px) and (max-width: 516px) {
                  height: 44px;
                }
              }
            }
            .countAndFavImage {
              flex: 0 0 20%;
              max-width: 20%;
              display: flex;
              flex-direction: row-reverse;
              gap: 10px;
              .lineItemsCount {
                color: $color-dark-gray;
                font-family: $font-regular;
                text-align: center;
                font-size: 16px;
                font-style: normal;
                line-height: 24px;
                letter-spacing: -0.4px;
                margin: 3px 0px 3px 0px;
              }
              .favImage {
                width: 30px;
                height: 30px;
                @media (max-width: $XSMobile) {
                  height: 24px;
                }
                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
          .listDiscription {
            margin-top: 20px;
            max-width: 95%;
            height: 66px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            word-wrap: break-word;
            white-space: normal;
            color: $color-dark-gray;
            text-overflow: ellipsis;
            font-family: $font-regular;
            font-size: 14px;
            font-style: normal;
            line-height: 22px;
            letter-spacing: -0.45px;
          }

          .viewListBtn {
            cursor: pointer;
            margin-top: 20px;
            display: flex;
            padding: 17px 0px 17px 0px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            align-self: stretch;
            border: 1px solid $color-slate;
            .viewListText {
              color: $color-slate;
              text-align: center;
              font-family: $font-regular;
              font-size: 18px;
              font-style: normal;
              line-height: 26px;
              letter-spacing: -0.35px;
              display: inline-block;
              max-width: 90%;
              white-space: nowrap;
              text-overflow: ellipsis;
              height: 28px;
              overflow: hidden;

              &:hover {
                cursor: pointer;
              }
            }
          }
          .deleteList {
            margin: 10px;
            display: flex;
            height: 60px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            align-self: stretch;
            .deleteOuterContainer {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              .deleteListText {
                color: $color-slate;
                font-family: $font-regular;
                font-size: 20px;
                font-style: normal;
                line-height: 28px;
                letter-spacing: -0.4px;
                text-decoration: underline;

                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
          .tileDevider {
            background-color: $color-dark-gray;
            height: 1px;
            width: 100%;
            display: none;
            margin-top: 8px;

            @media (max-width: 375px) {
              display: block;
            }
          }
        }
      }
    }
  }
  .kf-react-container {
    @include breakpoint(desktop-small) {
      max-width: 100% !important;
      margin: 0 auto !important;
      padding-right: 0px !important;
    }

    @media (min-width: 992px) and (max-width: 1024px) {
      padding: 0 2rem;
      flex-direction: column;
    }

    @include breakpoint(mobile-potrait) {
      padding: 0 1.125rem;
    }

    @media (max-width: $XSMobile) {
      padding: 0px !important;
    }

    .account-tabs {
      &__nav-wrapper {
        @include breakpoint(desktop-large) {
          flex: 0 0 13%;
          max-width: 13%;
        }

        @media (min-width: 992px) and (max-width: 1024px) {
          flex: 0 0 100%;
          max-width: 100%;
          padding-bottom: 0;
        }

        @include breakpoint(tab-landscape) {
          z-index: 6;
        }
      }

      &__nav {
        @media screen and (min-width: 1441px) {
          padding-left: 50px !important;
        }

        @include breakpoint(desktop-small) {
          padding: 60px 22px 0px 50px;
        }

        @include breakpoint(tab-landscape) {
          padding: 2.5rem 2rem;
          margin-right: -2rem;
        }

        @include breakpoint(mobile-potrait) {
          padding: 2.5rem 1.125rem 2.5rem;
          margin: 0 -1.125rem;
        }

        .account-tabs {
          &__message {
            @include text-styles($font-light, 28px, 36px, -1.4px);
          }

          &__list {
            margin-top: 2.5rem;

            &-item {
              line-height: -0.025rem;

              &:first-child {
                padding-top: 0;
              }

              &.--active {
                color: $slate;
              }
            }
          }

          &__sign-out {
            margin-top: 3.125rem;
          }
        }

        .select-dropdown__field {
          padding: 0.3125rem 0.9375rem 0.5rem;

          .select-dropdown__placeholder.--label {
            @include text-styles($font-regular, 0.75rem, 1.25rem, -0.0219rem);
            color: $dark-gray;
          }

          .select-dropdown__value {
            bottom: -0.6875rem;
            @include text-styles($font-regular, 1.125rem, 1.625rem, -0.0437rem);
            color: $slate;
          }
        }
      }
    }

    .account-tabs__wrapper-nav {
      @include breakpoint(desktop-large) {
        flex: 0 0 87%;
        max-width: 87%;
      }

      @media (min-width: 992px) and (max-width: 1024px) {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .account-tabs {
        &__content {
          @include breakpoint(desktop-small) {
            padding: 45px 50px 120px 30px;
          }

          @media (min-width: 992px) and (max-width: 1024px) {
            padding: 2.9375rem 0 0 1.875rem;
          }

          @include breakpoint(tab-landscape) {
            padding: 1.25rem 0 0 0 !important;
          }

          .favorites {
            padding-bottom: 3.75;

            &__title {
              font-family: $font-regular;
              font-size: 45px;
              font-style: normal;
              line-height: 56px;
              letter-spacing: -3.187px;

              @include breakpoint(tab-landscape) {
                display: none;
              }
            }

            &__content {
              @include breakpoint(tab-landscape) {
                margin-bottom: 3.75rem;
              }
            }

            &__container {
              .row {
                margin: 0;

                @include breakpoint(tab-max) {
                  padding: 0px 27px;
                }

                @include breakpoint(tab-small) {
                  padding: 0px 32px;
                }

                @media (max-width: $XSMobile) {
                  padding: 0px 18px;
                }

                .col {
                  padding: 0;
                }
              }

              .favorites {
                &__tab-content {
                  margin: 0;

                  .my-masonry-grid {
                    margin-left: 0;

                    &_column {
                      padding-left: 0;
                      margin-left: 1rem;

                      @include breakpoint(mobile-landscape) {
                        margin-left: 20px;
                      }

                      @include breakpoint(mobile-potrait) {
                        margin-left: 20px;
                      }

                      &:first-child {
                        margin-left: 0;
                      }

                      .edit-wrapper {
                        max-width: 100%;
                        margin-bottom: 20px;

                        .icon-select {
                          border: none;
                        }

                        .product-tile {
                          &__details {
                            @include breakpoint(tab-landscape) {
                              flex-direction: column;
                            }
                          }

                          &__image {
                            margin-bottom: 10px;

                            @include breakpoint(desktop-large) {
                              padding-bottom: 100%;
                              min-width: 31.6875rem;
                              min-height: 31.6875rem;
                            }

                            @include breakpoint(tab-landscape) {
                              padding-bottom: 100%;
                            }

                            @include breakpoint(mobile-landscape) {
                              min-width: 364px;
                              min-height: 368px;
                            }

                            @include breakpoint(mobile-potrait) {
                              min-width: 160px;
                              min-height: 166px;
                            }

                            img {
                              @include breakpoint(mobile-landscape) {
                                max-width: 364px;
                                max-height: 368px;
                              }

                              @include breakpoint(mobile-potrait) {
                                max-width: 160px;
                                max-height: 166px;
                              }
                            }
                          }

                          .product-brand_name {
                            text-transform: uppercase;
                            margin-bottom: 5px;
                            @include text-styles(
                              $font-medium,
                              0.875rem,
                              1.375rem,
                              -0.0187rem
                            );
                            font-weight: 500;
                            color: $color-dark-gray;
                          }

                          &__title {
                            @include text-styles(
                              $font-regular,
                              1.125rem,
                              1.625rem,
                              -0.0313rem
                            );
                            font-weight: 400;
                            color: $color-slate;

                            @include breakpoint(tab-landscape) {
                              @include text-styles(
                                $font-regular,
                                1rem,
                                1.5rem,
                                -0.025rem
                              );
                            }
                          }

                          &__price,
                          &__discount-price {
                            @include text-styles(
                              $font-regular,
                              1rem,
                              1.5rem,
                              -0.0219rem
                            );
                            font-weight: 500;
                            color: $color-dark-gray;

                            @include breakpoint(tab-landscape) {
                              @include text-styles(
                                $font-regular,
                                0.875rem,
                                1.375rem,
                                -0.0187rem
                              );
                              text-align: left;
                              margin: 0;
                            }
                          }

                          &__colors {
                            margin-top: 0.9375rem;
                          }

                          &__colors--count {
                            @include text-styles(
                              $font-regular,
                              1rem,
                              1.5rem,
                              -0.025rem
                            );
                          }

                          &__compare-main,
                          &__description {
                            @include breakpoint(tab-landscape) {
                              display: none;
                            }
                          }
                        }
                      }
                    }
                  }

                  .images-tab {
                    &.Videos {
                      .my-masonry-grid {
                        @include breakpoint(mobile-potrait) {
                          flex-direction: column;
                        }

                        &_column {
                          @include breakpoint(mobile-potrait) {
                            width: 100% !important;
                            margin-left: 0;
                          }
                        }

                        .edit-wrapper {
                          @include breakpoint(mobile-potrait) {
                            width: 100%;
                          }
                        }
                      }
                    }
                  }

                  .favorites__tab-buttons {
                    @include breakpoint(tab-landscape) {
                      justify-content: flex-start;
                    }
                  }
                }

                &__favorite-controls {
                  margin: 0;
                  min-height: 100%;
                  padding: 3.75rem 0 1.875rem;

                  @include breakpoint(tab-landscape) {
                    padding: 1.875rem 0;
                  }

                  &.show-nav {
                    @include breakpoint(tab-landscape) {
                      padding-top: 1.5625rem;
                    }

                    @include breakpoint(mobile-potrait) {
                      padding-bottom: 4.9375rem;
                    }
                  }

                  button {
                    height: 30px;
                  }

                  section {
                    height: 30px;

                    .sort-by-text {
                      @include text-styles(
                        $font-regular,
                        1rem,
                        1.5rem,
                        -0.025rem
                      );
                      color: $color-dark-gray;
                    }

                    .sort-options {
                      padding-left: 0.5625rem;
                      @include text-styles(
                        $font-regular,
                        1rem,
                        1.5rem,
                        -0.025rem
                      );
                      color: $color-dark-gray;
                    }

                    .sort__toggle-icon {
                      margin-left: 0.625rem;
                    }
                  }

                  .favorites__sticky-nav-wrap.show-nav-wrap {
                    top: 60px;

                    @include breakpoint(tab-landscape) {
                      top: 25px;
                    }
                  }

                  .favorites__manage-tools {
                    padding-left: 26px;
                  }
                }

                &__sticky-nav-wrap {
                  .favorites__controls {
                    @include breakpoint(mobile-potrait) {
                      margin: 1.25rem 0 1.875rem;
                    }

                    .manage-menu-item {
                      @include text-styles(
                        $font-regular,
                        1rem,
                        1.5rem,
                        -0.0219rem
                      );
                      color: $color-slate;
                      padding-bottom: 5px;
                      border-bottom: 1px solid $color-slate;
                      margin-right: 40px;
                    }

                    .gbh-data-layer {
                      .favorites__close {
                        width: 1.875rem;
                        height: 1.875rem;
                        fill: $color-slate;

                        @include breakpoint(tab-landscape) {
                          width: 17px;
                        }
                      }
                    }
                  }
                }

                .my-masonry-grid_column {
                  .icon-select {
                    width: 30px;
                    height: 30px;
                  }
                }
              }
            }

            .favorite-no-results {
              padding: 0;
              width: 100%;

              .no-result-description p {
                margin-top: 4.6875rem;
                @include text-styles($font-light, 1.75rem, 2.5rem, -0.0875rem);

                @include breakpoint(tab-landscape) {
                  margin-top: 0;
                  @include text-styles(
                    $font-light,
                    1.625rem,
                    2.125rem,
                    -0.0938rem
                  );
                }
              }

              .redirect-to-shopping {
                margin-top: 1.25rem;
                @include text-styles($font-regular, 1rem, 1.5rem, -0.0344rem);

                p {
                  @include text-styles($font-regular, 1rem, 1.5rem, -0.0344rem);
                  color: $color-dark-gray;
                }
              }
            }

            &__tab-header {
              margin-top: 0.9375rem;

              .favorites__tab-button {
                .tab-menu,
                .tab-item-count {
                  text-align: center;
                  font-family: $font-regular;
                  font-size: 18px;
                  font-style: normal;
                  line-height: 26px;
                  letter-spacing: -0.5px;
                }

                &.--active {
                  color: $slate;
                }
              }
            }
          }
        }
      }
    }
  }

  .favorites__back-to-top {
    @include breakpoint(tab-landscape) {
      border: none;
      margin: 0.25rem 0 0;
      padding: 0;
    }

    .favorites__go-back {
      @include text-styles($font-light, 1rem, 1.5rem, -0.025rem);
      color: $slate;

      img {
        margin-left: 1.25rem;
      }
    }
  }

  .product-tile__details.product-tile__compare-main {
    @include breakpoint(tab-landscape) {
      height: 100%;
    }

    .product-tile__colors,
    .favorite-sku-id,
    .favorite-price-info {
      margin-top: 5px;

      .product-tile__price,
      .product-tile__discount-price {
        text-align: left;
      }
    }
  }
}
