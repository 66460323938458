.payment-method {
  &__promo-section,
  &__billing {
    margin: 30px 10px 0px;
  }
  &__checkbox-group {
    height: 40px;
  }
  .promo-code {
    margin: 10px 10px 20px;
    padding: 0;
    &__action-block {
      width: 41.66667%;
    }
    &__input-block {
      width: 58.33333%;
    }
    &__label-block {
      display: none;
    }
  }
  &__accepted-card {
    img {
      vertical-align: middle;
      max-width: 297px;
      width: 100%;
    }
    margin-bottom: 15px;
  }
  &__address {
    margin-top: 30px;
  }
  &__valid-coupons {
    margin-bottom: 20px;
  }
  &__applied-coupons {
    margin-bottom: 30px;
    &--label {
      width: 66.66667%;
      float: left;
    }
    &--value {
      width: 33.33333%;
      float: left;
    }
    &:after {
      content: "";
      display: block;
      clear: both;
    }
  }
  .hidden-state-input {
    z-index: -1;
    position: absolute;
    height: 0;
    width: 0;
    opacity: 0;
  }
}

.payment-down {
  .modal-popup {
    .modal-popup-content {
      width: 500px;
      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }
  &__title {
    color: $color-slate;
    font-family: $font-light;
    font-size: 36px;
    letter-spacing: -1.7px;
    line-height: 44px;
  }
  &__cta {
    margin-top: 40px;
  }
}

@media (min-width: 980px) {
  .payment-method {
    .promo-code {
      &__action-block {
        width: 25%;
      }
      &__input-block {
        width: 41.66667%;
      }
    }
  }
}
