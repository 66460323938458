.dynamicSearchViewWrapper {
  :global {
    .search-button {
      border: none;
      background: none;
      font-size: 14px;

      .search-text {
        display: inline-block;
      }

      .search-icon {
        margin: 0 0 0 18px;
        display: inline-block;
      }
    }

    @media only screen and (max-width: 1200px) and (orientation: landscape) {
      .search-button {
        padding: 0px;

        .search-text {
          display: none;
        }
        .search-icon {
          margin: 0;
        }
      }
    }

    @media screen and (max-width: 1200px) {
      .search-button {
        padding: 0px;

        .search-text {
          display: none;
        }

        .search-icon {
          margin: 0;
        }
      }
    }

    @media print {
      .search-button {
        display: none;
      }
    }
  }
}
