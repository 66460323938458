@import "@/styles/mixins/_breakpoint";
.check-out {
  .radio {
    margin: 0 10px 0 0;
    vertical-align: super;
  }
  .radio__custom {
    border: 1px solid $dark-gray;
    border-radius: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 19px;
    height: 19px;
  }
  .payment-address-info-section {
    .radio__input {
      &:checked {
        + {
          .radio__custom {
            &::after {
              background: $charcoal;
              transform: unset !important;
              width: 15px !important;
              height: 15px !important;
              border-radius: 10px;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
  }

  .active,
  .payment-address-info-section .radio__input:checked,
  .--active {
    .radio__custom {
      &::after {
        background: $charcoal;
        transform: unset;
        width: 13px;
        height: 13px;
        border-radius: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .radio-button__checked {
    top: 2px !important;
    left: 2px !important;
    height: 15px !important;
    width: 15px !important;
  }
  .preview-cart-item {
    &__image {
      background: $color-off-white;
      margin-right: 15px;
      width: 100px;
      height: 100px;
      object-fit: cover;
    }
    &__brand {
      font-size: 14px;
      letter-spacing: -0.35px;
      line-height: 22px;
    }
    &__header {
      margin-bottom: 2px;
    }
    &__finish {
      margin-bottom: 5px !important;
    }
    &__skuId {
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.45px;
      color: $slate;
    }
  }
  .step-nav {
    &__step {
      padding: 15px 0 0 0 !important;
      &--active {
        padding: 0px !important;
        border-top: unset !important;
      }
      &--done {
        .step-nav__review {
          border-bottom: unset;
          @media (min-width: 1023px) {
            padding: 20px 0 0 0 !important;
          }
        }
      }
    }
    &__review {
      padding: 15px 0 0 0 !important;
      border-bottom: 1px solid $dark-gray;
      margin: 0 !important;
      @media (min-width: 1023px) {
        padding: 25px 0 0 0 !important;
      }
      @media (max-width: 767px) {
        padding: 25px 0 0 0 !important;
      }
    }
    &__content {
      padding: 0 !important;
      .step-nav__component {
        .step-nav__review {
          .step-nav__contact-info-title {
            color: $slate;
          }
        }
      }
    }
  }
  margin-top: 55px;
  @media (max-width: 1023px) {
    margin-top: 30px;
  }
  &__grid {
    display: flex;
    @media (min-width: 1023px) {
      justify-content: center;
    }
    &-steps {
      @media screen and (min-width: 1023px) {
        width: 100%;
        max-width: 720px;
        margin-bottom: 60px;
      }
      @media screen and (max-width: 1023px) {
        margin-bottom: 15px;
      }
      @media screen and (max-width: 767px) {
        margin-bottom: 50px;
      }
    }
    &-summary {
      @media (min-width: 1023px) {
        width: 100%;
        max-width: 400px !important;
        margin-left: 79px;
      }
      .order-summary__item {
        margin-bottom: 5px !important;
        @include breakpoint(desktop-large) {
          margin-bottom: 0 !important;
        }
        .order-summary__item--label {
          letter-spacing: -0.35px;
        }
      }
    }
    @media screen and (max-width: 1023px) {
      display: block;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 65px;
  }
  &__back {
    cursor: pointer;
    font-family: $font-regular;
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: -0.65px;
    color: $color-dark-gray;
    display: inline-flex;
    align-items: center;
    img {
      margin-right: 20px;
    }
  }
  &__support {
    margin: 0 40px 40px;
    div {
      margin-bottom: 10px;
      font-family: $font-light;
      font-size: 26px;
      line-height: 1.31;
      letter-spacing: -1.55px;
      color: $color-slate;
    }
    a {
      cursor: pointer;
      font-family: $font-regular;
      font-size: 16px;
      line-height: 1.57;
      letter-spacing: -0.65px;
      color: $color-dark-gray;
    }
    &.--lg-device {
      display: block;
    }
    &.--md-device {
      display: none;
    }
    &.--sm-device {
      display: none;
    }
  }
  &__title {
    display: block;
    font-family: $font-light;
    font-size: 45px;
    line-height: 56px;
    letter-spacing: -3.19px;
    color: $slate;
    margin-bottom: 55px;
    @media (max-width: 1023px) {
      font-size: 28px;
      line-height: 36px;
      letter-spacing: -1.98px;
      margin-bottom: 29px;
    }
    @media (max-width: 1023px) {
      margin-bottom: 30px;
    }
  }
  &__sticky-summary {
    position: sticky;
    top: 0;
    @media screen and (min-width: 1024px) {
      top: 40px;
    }
    .order-summary-wrapper {
      margin: 0px;
    }
    .order-summary {
      &__separator {
        border-top: 1px solid $color-dark-gray;
      }
      &__item {
        color: $color-charcoal;
      }
    }
  }
  &__sticky-content {
    .order-summary-wrapper {
      &.sticky-order {
        position: relative;
        top: unset;
      }
    }
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  @include breakpoint(desktop-large) {
    max-width: unset !important;
    padding: 0 !important;
    margin-right: unset !important;
    margin-left: unset !important;
  }
  .check-out__grid {
    @include breakpoint(desktop-large) {
      max-width: 1188px !important;
      padding: 0 !important;
      margin-right: auto !important;
      margin-left: auto !important;
    }
    &-steps {
      margin-bottom: 0;
      @include breakpoint(desktop-large) {
        min-width: 712px;
      }
      @include breakpoint(tab-max) {
        margin-bottom: 3.125rem;
      }
      .payment-method__promo-error {
        @include breakpoint(desktop-small) {
          max-width: 300px;
        }
        @include breakpoint(mobile) {
          max-width: 222px;
        }
      }
    }
    .Input_inputFieldWrapper__nt6o0 .input-field {
      &__value {
        input {
          line-height: 26px;
          letter-spacing: -0.5px;
        }
      }
    }
    .shipping-address__submit {
      &.kf-react-button.large {
        margin-top: 30px;
        .kf-react-button.large {
          line-height: 26px;
          margin-top: 0;
        }
      }
    }
    .step-nav__edit-btn {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.3px;
      color: $color-slate;
    }
    .step-nav__component {
      .payment-fail-modal {
        .modal-popup-content {
          @include breakpoint(mobile-potrait) {
            max-height: unset !important;
            min-height: unset !important;
            height: fit-content !important;
          }
          @include breakpoint(mobile-potrait) {
            margin: 0 auto;
          }
        }
        .modal-popup-body {
          @include breakpoint(desktop-small) {
            width: 631px !important;
            padding: 55px 50px 50px 50px !important;
          }
          @include breakpoint(tab-max) {
            width: 521px !important;
            padding: 55px 50px 50px 50px !important;
          }
          @include breakpoint(mobile-potrait) {
            width: 100% !important;
            padding: 16px 18px 50px 18px !important;
          }
        }
      }
      .modal-popup-body {
        @include breakpoint(desktop-small) {
          width: 880px !important;
          padding: 25px 50px 50px !important;
        }
        @include breakpoint(mobile-landscape) {
          padding: 15px 32px 50px !important;
        }
        @include breakpoint(mobile-potrait) {
          padding: 15px 18px 50px !important;
        }
        .modal-address-instruction {
          max-width: 540px;
          @include breakpoint(tab-landscape) {
            max-width: 651px;
          }
        }
      }
    }
    .shipping-address__address-list {
      .address-tile {
        &.--new {
          border: solid 1px $color-dark-gray;
          .address-tile__label {
            color: $color-slate;
          }
          .shipping-form__toggle {
            .checkbox_default {
              color: $color-dark-gray !important;
            }
          }
        }
      }
    }
  }
  .checkout-cart-section {
    .delivery-option__row {
      .cart-item {
        @include breakpoint(desktop-large) {
          padding: 30px 0 !important;
        }
        &__image {
          margin-right: 20px;
          width: 150px;
          height: 150px;
          object-fit: cover;
          @include breakpoint(mobile-potrait) {
            width: 100px;
            height: 100px;
          }
        }
        &__color {
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.45px;
          color: $color-slate;
          margin-bottom: 20px;
        }
      }
      .cart-item__brand {
        line-height: 24px;
        margin-bottom: 2px;
      }
      .cart-item__name-desc {
        .cart-item__name,
        .cart-item__desc {
          font-family: $font-regular;
          color: $color-slate;
        }
      }
      .cart-item__quantity-price {
        margin-top: 20px;
        .cart-item__label {
          color: $color-charcoal;
        }
      }
      .cart-item__name {
        color: $color-slate;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.4px;
      }
      .delivery-option__shipping-type-column {
        margin: 0 0 30px 0;
        &:last-child {
          margin: 0;
        }
      }
      .delivery-shipping-type-name,
      .delivery-charges {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.35px;
      }
      .combo-cart-items {
        padding-left: 8.75rem;
        @include breakpoint(desktop-large) {
          padding-bottom: 0;
        }
        @include breakpoint(mobile) {
          padding-left: 81px;
        }
      }
      .combo-cart-items__note {
        padding-bottom: 20px;
        @media only screen and (min-width: 767px) {
          padding-top: 30px;
        }
        @include breakpoint(desktop-large) {
          padding-top: 0;
        }
      }
    }
    &-lists {
      .cart-item__label.--sum {
        @include breakpoint(mobile) {
          display: none;
        }
      }
      .cart-stock-msg.desktop-cart-stock-msg {
        padding-top: 20px;
      }
      .cart-blank-section {
        margin-right: 20px;
      }
    }
  }
  .checkout-need-help {
    @include breakpoint(desktop-small) {
      margin-top: 6.25rem;
    }
    @include breakpoint(desktop-large) {
      margin: 6.25rem 2.6045% 0;
      padding: 1.875rem 0 3.75rem;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.45px;
      color: $color-slate;
    }
    @include breakpoint(tab-max) {
      letter-spacing: -0.4px;
    }
    @include breakpoint(mobile) {
      padding-top: 50px;
    }
    span {
      font-size: 16px;
      font-family: $font-regular;
      line-height: 24px;
      letter-spacing: -0.4px;
      color: $color-slate;
      margin: 0;
    }
    .cart-info {
      &__brand-title {
        @include text-styles($font-regular, 0.875rem, 1.375rem, -0.0281rem);
        color: $color-slate;
        margin: 0;
        padding-top: 0.9375rem;
        .cart-info__link {
          @include text-styles($font-regular, 0.875rem, 1.375rem, -0.0281rem);
          color: $color-charcoal;
          padding: 0;
          margin: 0;
        }
        &:last-child {
          padding-top: 0.625rem;
        }
      }
    }
  }
  &__grid-summary {
    @media screen and (min-width: 1023px) {
      margin-left: 5rem;
    }
    .shipping-accordion {
      @include breakpoint(mobile) {
        margin: 0px 0 0;
      }
    }
    .order-summary {
      @include breakpoint(tab-max) {
        padding-top: 0;
      }
      &__separator {
        &.cart_custom_margin_hr {
          @include breakpoint(tab-max) {
            margin: 5px 0 10px;
          }
        }
      }
      .edit-cart-btn {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.3px;
        color: $slate;
      }
      &__item {
        @include breakpoint(desktop-small) {
          margin-bottom: 10px;
        }
        &--label {
          letter-spacing: -0.4px;
        }
        &--value {
          letter-spacing: -0.4px;
        }
      }
      &-mobile-sticky {
        .estimated-total {
          span {
            @include breakpoint(tab-max) {
              letter-spacing: -0.35px;
            }
          }
        }
      }
      &__title {
        line-height: normal;
      }
    }
  }
  .shipping-form__set-default {
    .checkbox input:checked ~ span {
      color: $color-dark-gray;
    }
  }
}
.sign-in__error-box {
  .error-msg {
    color: $color-error-red;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.55px;
    font-family: "Helvetica Now Text W05 Regular";
  }
}

.review-order {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  &__order-summary {
    display: none;
  }
  &__cta {
    width: 100%;
    margin: 20px 0 10px 0;
    .button {
      height: 50px !important;
      width: 100%;
    }
  }
  &__title {
    display: block;
    font-family: $font-medium;
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: -0.4px;
    color: $color-charcoal;
    margin-bottom: 5px;
  }
  &__desc {
    font-family: $font-regular;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.48px;
    color: $color-dark-gray;
    font-style: italic;
    text-align: center;
    a {
      color: $color-dark-gray;
      text-decoration: underline;
    }
  }
  .shipping-accordion {
    &__separator {
      color: $color-dark-gray;
    }
    .preview-cart-item__name {
      color: $color-slate;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.4px;
    }
  }
}

.taxware-down {
  .modal-popup {
    .modal-popup-content {
      width: 600px !important;
      @media (max-width: 767px) {
        width: 100% !important;
      }
    }
  }
  &__title {
    margin-top: 20px;
    color: $color-slate;
    font-family: $font-light;
    font-size: 36px;
    letter-spacing: -1.7px;
    line-height: 44px;
  }
  &__desc,
  &__desc p {
    margin-top: 20px;
    color: $color-dark-gray;
    font-family: $font-regular;
    font-size: 20px;
    letter-spacing: -0.65px;
    line-height: 28px;
  }
  &__cta {
    margin-top: 40px;
  }
}

.payment-method {
  .chekout-payment-error {
    color: $color-error-red;
    font-family: $font-regular;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.3px;
    margin: 20px 0;
    &:first-letter {
      text-transform: capitalize;
    }
    p {
      display: inline;
    }
    a {
      color: $color-error-red;
      font-family: $font-regular;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.3px;
      text-decoration: underline;
    }
  }
  &__billing-form {
    margin: 30px 0 0 0;
  }
  &--main-title {
    padding: 25px 0 30px 0;
    font-size: 20px;
    line-height: 29px;
    letter-spacing: 1.82px;
    color: $slate;
    text-transform: uppercase;
    font-family: $font-bold;
    @media screen and (max-width: 1023px) {
      padding: 15px 0 30px 0;
    }
  }
  &__title {
    font-family: $font-medium;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.4px;
    line-height: 28px;
    color: $color-charcoal;
    margin-bottom: 30px;
  }
  &__paypal-logo {
    width: 100px;
    height: 25px;
  }
  &__tile {
    border-radius: 5px;
    background-color: $color-white;
    border: solid 1px $dark-gray;
    padding: 20px;
    &-details {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-name {
      font-family: $font-medium;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.35px;
      color: $slate;
      display: flex;
      align-items: center;
      justify-content: center;
      label {
        margin: 0;
        align-items: center;
        justify-content: center;
        display: flex;
      }
    }
    &.--paypal-payment {
      margin-top: 20px;
      &.--active {
        padding-bottom: 25px;
      }
    }
    &.--active {
      border: solid 1px $color-dark-gray;
      padding-bottom: 30px;
      .payment-method__tile-name {
        color: $charcoal;
      }
      @media screen and (max-width: 767px) {
        padding: 15px 15px 30px 15px;
      }
      .payment-method__tile-details {
        cursor: auto;
      }
    }
  }
  &__billing-checkbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
    label {
      margin-bottom: 0 !important;
    }
    .checkbox {
      &__border {
        margin-top: 0 !important;
      }
    }
    .checkbox_default {
      color: $slate !important;
    }
  }
  &__norton-logo {
    img {
      width: 92px;
      height: 47px;
    }
  }
  &__use-different {
    span {
      cursor: pointer;
      text-decoration: underline;
      font-family: $font-regular;
      font-size: 16px;
      letter-spacing: -0.55px;
      line-height: 24px;
      color: $color-slate;
    }
  }
  &__paypal {
    margin-top: 20px;
    @media screen and (max-width: 1023px) {
      margin-top: 18px;
    }
  }
  .paypal-method__info-message {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.45px;
    color: $slate;
    font-family: $font-regular;
    margin: 0 0 20px 0;
  }
  &__billing-form {
    &-title {
      font-family: $font-medium;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.35px;
      color: $color-charcoal;
      margin-bottom: 20px;
    }
  }
  &__card-name {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    margin-left: 10px;
    &--label {
      width: 33.33%;
      margin-top: 10px;
      text-align: right;
      padding-right: 25px;
      font-size: 14px;
      text-align: right;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: 500;
    }
    &--field {
      width: 33.33%;
    }
    &--input {
      border-radius: 4px;
      background-color: $color-white;
      padding-left: 15px;
      padding-right: 15px;
      font-size: 12px;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      height: 40px;
      width: 100%;
      border: solid 1px #ddd;
      box-sizing: border-box;
      &.--error {
        border: solid 1px #e50000;
        margin-bottom: 4px;
      }
      &:focus-visible {
        outline: -webkit-focus-ring-color auto 1px;
      }
    }
    &--error {
      font-size: 12px;
      font-family: $font-regular;
      color: #e50000;
    }
  }
  &__promo {
    margin-bottom: 30px;
    &-add {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      span {
        color: $charcoal;
        font-family: $font-medium;
        font-size: 14px;
        letter-spacing: -0.28px;
        line-height: 16px;
        border-bottom: 1px solid $charcoal;
        padding-bottom: 6px;
      }
    }
    &-field {
      display: flex;
      align-items: center;
      button {
        margin-left: 15px !important;
        width: 97px;
      }
      .input-field {
        margin: 0 !important;
        width: 283px;
        @media screen and (max-width: 767px) {
          width: auto;
        }
        &__border {
          width: 100%;
          margin-right: 15px;
          padding: 0 15px !important;
          border-radius: 4px !important;
          border: solid 1px $dark-gray !important;
          background-color: $color-white !important;
          height: 50px !important;
          input {
            font-family: $font-regular !important;
            font-size: 14px !important;
            line-height: 22px !important;
            letter-spacing: -0.45px !important;
            color: $dark-gray !important;
            text-transform: uppercase;
          }
          &.--error {
            border-color: $color-error-red !important;
          }
        }
      }
    }
    &-error {
      margin-top: 10px;
      font-family: $font-regular;
      font-size: 16px;
      line-height: 1.38;
      letter-spacing: -0.3px;
      color: $color-error-red;
    }
    &-list {
      max-width: 392px;
    }
    &-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      &:first-child {
        margin-top: 20px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      img {
        cursor: pointer;
        width: 10px;
        height: 10px;
        margin-right: 15px;
      }
    }
    &-label {
      font-family: $font-regular;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.55px;
      color: $color-slate;
    }
  }
  &__payment-process-error {
    margin-top: 20px;
    color: $color-error-red;
    font-family: $font-regular;
    font-size: 16px;
    letter-spacing: -0.55px;
    line-height: 22px;
  }
}

.shipping-accordion {
  @media screen and (max-width: 1023px) {
    margin: 5px 0 35px 0;
  }
  @media screen and (max-width: 767px) {
    margin: 39px 0 35px 0;
  }
  .collapsible-accordion {
    .Collapsible {
      border-bottom: 1px solid $color-dark-gray !important;
      .Collapsible__trigger {
        padding: 10px 0;
        @media screen and (max-width: 1023px) {
          padding: 11px 0;
        }
        @media screen and (max-width: 767px) {
          padding: 10px 0;
        }
        .collapsible__header {
          span {
            font-family: $font-regular;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.4px;
            color: $charcoal;
          }
        }
      }
      .Collapsible__contentInner {
        padding: 5px 0 10px;
        @include breakpoint(tab) {
          position: relative;
        }
        .preview-cart-item {
          padding: 20px 0;
          &:first-child {
            padding-top: 0;
            border-top: unset !important;
            .preview-cart-item__price {
              @include breakpoint(tab) {
                top: 0;
              }
            }
          }
          &:last-child {
            @include breakpoint(desktop-small) {
              padding-bottom: 0;
            }
          }
          @include breakpoint(tab-max) {
            position: relative;
          }
          &__name {
            color: $color-slate;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.55px;
            margin-bottom: 15px;
            font-family: $font-regular;
            @include breakpoint(tab) {
              max-width: 422px;
            }
          }
          &__finish {
            color: $color-slate;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: -0.45px;
            margin-bottom: 15px;
          }
          &__qty-label {
            letter-spacing: -0.4px;
            color: $color-charcoal;
          }
          &__price {
            line-height: 24px;
            display: flex;
            flex-direction: column;
            margin: 0;
            @include breakpoint(tab) {
              position: absolute;
              right: 0;
              top: 20px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .check-out {
    &__support {
      margin-bottom: 0;
      &.--lg-device {
        display: none;
      }
      &.--md-device {
        display: block;
      }
      &.--sm-device {
        display: none;
      }
    }
    &__sticky-summary {
      position: static;
    }
  }
}

@media screen and (max-width: 767px) {
  .check-out {
    &__shipping-modal .modal-popup .modal-popup-content {
      padding: 35px 18px 65px !important;
    }
    &__support {
      &.--lg-device {
        display: none;
      }
      &.--md-device {
        display: none;
      }
      &.--sm-device {
        display: block;
        margin-top: 50px;
        text-align: left;
      }
    }
  }
  .payment-method {
    &__card-name {
      display: block;
      &--label {
        width: calc(90% - 10px);
        text-align: left;
        margin-bottom: 10px;
      }
      &--field {
        width: calc(90% - 25px);
      }
    }
  }
}
.check-out {
  .step-nav {
    &__step {
      &--done {
        padding-bottom: 25px !important;
        border-bottom: 1px solid $color-dark-gray !important;
        &:nth-of-type(2) {
          border-top: unset !important;
        }
        &:nth-of-type(3) {
          border: unset !important;
        }
      }
    }
    &__content {
      .option-title {
        padding-bottom: 30px;
      }
    }
  }
}
.cart-promo-code {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  span {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.45px;
    color: $charcoal;
    font-family: $font-regular;
    &.order-summary__close {
      padding: 0;
      text-decoration: underline;
    }
  }
  .active-promo-name {
    font-family: $font-medium;
    text-transform: uppercase;
  }
}
.cart-promo-description {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.45px;
  color: $slate;
  font-family: $font-regular;
  font-style: italic;
  @media screen and (max-width: 767px) {
    width: 58%;
    margin: 3px 0 0 0;
  }
}
.cart-promo-code-section {
  margin: -15px 0 0 0;
  @media screen and (max-width: 767px) {
    margin: 7px 0 0 0;
  }
}
.promo-code-list {
  margin: 0 0 20px 0;
}
.margin-0 {
  margin: 0 !important;
}
.paypal-button {
  height: 50px !important;
}
.payment-list-type {
  margin-bottom: 20px;
}
.checkout-need-help {
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.9px;
  color: $color-charcoal;
  font-family: $font-regular;
  border-top: 1px solid $color-light-gray;
  a {
    font-family: $font-medium;
    color: $color-slate;
  }
  span {
    margin-top: 5px;
    display: block;
  }
  padding: 31px 0 57px 0;
  margin: 10px 0 0 0;
  @media screen and (max-width: 1023px) {
    margin: 0;
    border: unset;
    padding: 15px 0 60px 0;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.8px;
  }
  @media screen and (max-width: 767px) {
    padding: 0 0 60px 0;
  }
}
.shipping-accordion {
  .collapsible-accordion {
    .Collapsible {
      border-color: $dark-gray;
      .preview-cart-item {
        border-top: 1px solid $light-gray;
        &:first-child {
          border-top: none;
        }
        &:last-child {
          border-top: 1px solid $light-gray;
        }
      }
      .preview-cart-item__price {
        font-family: $font-medium;
        letter-spacing: -0.35px;
        margin-bottom: 10px;
        @media screen and (max-width: 1023px) {
          margin-bottom: 0;
        }
      }
      .preview-cart-item__discount {
        margin: 3px 0 10px;
      }
      .preview-cart-item__offPercent {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.48px;
        color: $charcoal;
      }
      .preview-cart-item__discount-container {
        flex-direction: column-reverse;
      }
      &__trigger {
        padding: 10px 0;
        line-height: 24px;
        font-family: $font-regular !important;
      }
      &__contentOuter {
        .Collapsible__contentInner {
          padding: 10px 0 20px 0;
          @media screen and (max-width: 1023px) {
            padding: 10px 0 0 0;
          }
          .kf-react-button,
          .button {
            padding: 0;
            width: 97px;
            float: right;
            margin-left: 15px;
            @media screen and (max-width: 767px) {
              min-width: 97px;
            }
          }
        }
      }
      .is-open {
        .plus {
          &::after {
            transform: rotate(90deg);
          }
        }
      }
      .plus {
        position: relative;
        .line {
          display: none;
        }
        &::after {
          content: "\e92c";
          display: flex;
          font-family: icomoon;
          font-size: 1pc;
          opacity: 1;
          transform: rotate(-90deg);
          transition: all 0.5s ease;
          top: 0px;
          position: absolute;
          color: $charcoal;
        }
      }
    }
  }
}
.modal-open {
  .shipping-alert__modal {
    width: 653px !important;
    padding: 25px 50px 50px !important;
    @include breakpoint(tab-landscape) {
      width: 100% !important;
      padding: 15px 32px 50px !important;
    }
    @include breakpoint(mobile-potrait) {
      padding: 45px 18px 50px !important;
    }
    .shipping-alert {
      &__title {
        text-transform: uppercase;
        @include text-styles(
          $font-bold!important,
          1.25rem!important,
          normal!important,
          .1136rem!important
        );
        padding-top: 30px;
      }
      &__description {
        font-family: $font-medium;
      }
      &__header {
        row-gap: 0;
        @include breakpoint(mobile-potrait) {
          row-gap: 6px;
        }
      }
      &__choices {
        .verify-address {
          &__alert-heading {
            margin-top: 20px;
            .verify-address__alert {
              margin-top: 10px;
            }
          }
          &__alert-header-text {
            letter-spacing: -0.35px;
          }
        }
      }
      &.verify-address {
        .shipping-alert__close-icon {
          top: 25px;
        }
      }
      &__button {
        width: 100%;
      }
    }
  }
  .shipping-alert {
    .cart-items {
      @include breakpoint(mobile-potrait) {
        padding: 30px 0 0 !important;
      }
      .cart-item {
        padding: 20px 0 !important;
        @include breakpoint(mobile-potrait) {
          border-top: 1px solid $color-dark-gray;
        }
        &__image {
          width: 150px;
          margin-right: 15px;
          @include breakpoint(mobile-potrait) {
            width: 100px;
            height: 100px;
          }
        }
        .cart-item__specs {
          max-width: 100%;
          .cart-item {
            &__brand-name {
              @include text-styles(
                $font-medium!important,
                .875rem!important,
                1.375rem!important,
                -0.0187rem!important
              );
            }
            &__sku {
              margin-top: 15px;
              color: $slate;
              @include breakpoint(mobile-potrait) {
                float: unset;
                text-align: left;
              }
            }
            &__qty_mobile {
              display: none;
              @include breakpoint(mobile-potrait) {
                display: block;
                padding-top: 20px;
                @include text-styles(
                  $font-medium!important,
                  14px !important,
                  22px !important,
                  -0.3px !important
                );
              }
            }
            &__name,
            &__desc {
              @include text-styles($font-regular, 1rem, 1.5rem, -0.025rem);
              color: $slate;
            }
          }
          > div {
            div:nth-child(1) {
              @include breakpoint(mobile-potrait) {
                flex: 0 0 100%;
                max-width: 100%;
              }
            }
            div:nth-child(2) {
              .row.px-0.mx-0 {
                justify-content: end;
                @include breakpoint(mobile-potrait) {
                  display: none;
                }
              }
            }
          }
        }
      }
      &__button {
        width: 100% !important;
      }
      .cart-items__td {
        margin-top: 30px !important;
      }
      .cart-items__th {
        display: flex;
        justify-content: space-between;
        .cart-items__td {
          flex: 0 0 50%;
          max-width: 50%;
          div:last-child {
            display: flex;
            align-items: start;
            justify-content: end;
          }
        }
        div:nth-child(2) {
          .row.px-0.mx-0 {
            justify-content: flex-end;
          }
        }
      }
      .cart-item {
        &__cta-wrapper,
        &__stk-massage {
          display: none;
        }
      }
    }
  }
}
.address-tile {
  &.--new {
    .shipping-form__set-default-check {
      height: unset;
      margin-top: 30px;
    }
  }
}
.delivery-option__submit-button button {
  margin-top: 30px !important;
}
// Hide SKU from checkout flow page.
.check-out {
  .check-out {
    &__content {
      .delivery-option {
        .cart-item {
          .cart-item {
            &__sku {
              display: none;
            }
          }
        }
      }
    }
  }
}

.payment-method__promo-field {
  button.kf-react-button {
    @include breakpoint(desktop-large) {
      line-height: 22px;
      letter-spacing: -0.3px !important;
    }
  }
}
.input-field__value {
  @include breakpoint(desktop-large) {
    input::placeholder-shown + label {
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.5px;
    }
  }
}
.shipping-address {
  @include breakpoint(desktop-large) {
    &__checkout-fast-cta {
      color: $slate;
    }
  }
}
.shipping-form__submit-button button {
  @include breakpoint(desktop-large) {
    margin-top: 0px !important;
  }
  @include breakpoint(tab) {
    margin-top: 0px !important;
  }
}
html[page="checkout"] {
  background: $white;
}
.payment-fail-modal {
  .payment-help-msg {
    a {
      height: 50px;
      padding: 0px 40px;
      gap: 10px;
      background: $charcoal;
      color: $white;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.35px;
      font-family: $helvetica-medium;
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: stretch;
    }
  }
  .payment-error-contact {
    letter-spacing: -0.55px;
    margin: 5px 0 40px 0;
    p,
    a {
      font-family: $helvetica-bold;
    }
  }
  .user-cart-key {
    margin-top: -25px;
    font-family: $helvetica-bold;
  }
  .modal-popup-content {
    @include breakpoint(mobile-potrait) {
      max-height: unset !important;
      min-height: unset !important;
      height: fit-content !important;
    }
    @include breakpoint(mobile-potrait) {
      margin: 0 auto;
    }
  }
  .modal-popup-body {
    .review-order {
      &__mssg {
        display: none;
      }
      &__cta {
        margin: 0;
      }
    }
    .error-title {
      color: $color-slate;
      font-family: $helvetica-bold;
      font-size: 20px;
      line-height: normal;
      letter-spacing: 1.818px;
      text-transform: uppercase;
      @include breakpoint(mobile-potrait) {
        margin-top: 30px;
      }
    }
    .fail-message {
      color: $color-slate;
      font-family: $helvetica-regular;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.55px;
      margin: 15px 0 40px 0;
    }
    button {
      height: 50px;
      padding: 0px 40px;
      line-height: 26px;
    }
    @include breakpoint(desktop-small) {
      width: 631px !important;
      padding: 55px 50px 50px 50px !important;
    }
    @include breakpoint(tab-max) {
      width: 521px !important;
      padding: 55px 50px 50px 50px !important;
    }
    @include breakpoint(mobile-potrait) {
      width: 100% !important;
      padding: 16px 18px 50px 18px !important;
    }
  }
}
.paymentDisableMessage {
  margin: 10px 0;
  p {
    font-size: 14px;
    color: $color-error-red;
    line-height: 22px;
    letter-spacing: -0.3px;
    a {
      font-family: $helvetica-medium;
      text-decoration-line: underline;
      color: $color-error-red;
    }
  }
}
