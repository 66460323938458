@import "@/styles/base/_colors";
.shopTheRoomDetailWrapper {
  :global {
    .swipe-image {
      --n: 1;
      display: flex;
      align-items: center;
      width: 100%;
      width: calc(var(--n) * 100%);
      transform: translate(
        calc(var(--tx, 0px) + var(--i, 0) / var(--n) * -100%)
      );
      touch-action: none;
      img {
        width: 100%;
        width: calc(100% / var(--n));
        user-select: none;
        pointer-events: none;
        transform: translate(
          calc(var(--tx, 0px) + var(--i, 0) / var(--n) * -100%)
        );
      }
      .smooth {
        transition: transform 0.5s ease-out;
      }
    }
    @mixin scroll-button-common-css {
      width: 60px;
      height: 60px;
      background: $slate;
      content: "\e92c";
      font-size: 23px;
      justify-content: center;
      align-items: center;
      opacity: 1;
      display: flex;
      font-family: icomoon;
      color: inherit;
      @media only screen and (min-width: 640px) and (max-width: 991px) {
        width: 50px;
        height: 50px;
      }
    }
    .hidden {
      display: none;
    }
    .hotspotsmallimg {
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
      cursor: pointer;
    }
    .card-modal-product-card-container {
      position: relative;
      .product-cards-title {
        color: $slate;
        font-family: $helvetica-light;
        font-size: 2.8125rem;
        font-weight: 300;
        letter-spacing: -0.1994rem;
        line-height: 3.5rem;
        margin-bottom: 40px;
        @media screen and (max-width: 767px) {
          font-size: 1.75rem;
          letter-spacing: -0.1237rem;
          line-height: 2.25rem;
        }
        @media screen and (max-width: 1024px) {
          margin-bottom: 1.25rem;
          color: $slate;
          font-family: $helvetica-regular;
          font-size: 1.75rem;
          font-weight: 300;
          letter-spacing: -0.1237rem;
          line-height: 2.25rem;
        }
      }
      .product-cards-container {
        .product-card {
          @media screen and (max-width: 1038px) {
            padding: 20px 0 !important;
          }
          &__not-display {
            display: none;
          }
          .inspired-selling-module__card-container {
            .--selected {
              border: 4px solid red;
            }

            .shop-the-room-card-details {
              .brand-name {
                font-family: $helvetica-regular;
                font-size: 0.875rem;
                font-weight: 500;
                letter-spacing: -0.0194rem;
                line-height: 1.125rem;
                padding-top: 10px;
              }
              .regional-brand-name {
                font-family: $helvetica-regular;
                font-size: 1.125rem;
                letter-spacing: -0.0512rem;
                line-height: 1.5rem;
                padding-top: 5px;
              }
              .short-description {
                font-family: $helvetica-regular;
                font-size: 16px;
                letter-spacing: -0.0344rem;
                line-height: 22px;
                padding-top: 5px;
                height: 2.75rem;
                width: 85%;
                @media screen and (max-width: 375px) {
                  height: 3.6688rem;
                }
                @media screen and (max-width: 767px) {
                  height: 4.125rem;
                }
                @media screen and (max-width: 1024px) and (orientation: landscape) {
                  height: 4.125rem;
                }
              }
              .price {
                margin-top: 19px;
                font-family: $helvetica-regular;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: -0.05rem;
                line-height: 22px;
              }
            }
          }
        }
      }
      .button-container {
        display: flex;
        .scroll-wrapper {
          position: absolute;
          top: calc(50% - 40px);
          width: 100%;
          .product-card-scroll {
            &::before {
              @include scroll-button-common-css;
            }
            &__button-disable {
              cursor: not-allowed;
              color: $medium-gray;
              left: 50px;
              position: absolute;
              margin: 0;
              &::before {
                @include scroll-button-common-css;
              }
            }
            &__right {
              margin-right: 0;
              right: 50px;
              position: absolute;
              color: $light-gray;
              &::before {
                @include scroll-button-common-css;
                transform: rotate(-180deg);
              }
              &__button-disable {
                cursor: not-allowed;
                margin-right: 0;
                color: $medium-gray;
                right: 50px;
                position: absolute;
                &::before {
                  @include scroll-button-common-css;
                  transform: rotate(-180deg);
                }
              }
            }
          }
        }
      }
    }
    .product-card-scroll {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      max-width: 1920px;
      margin: 0;
      color: $light-gray;
      left: 50px;
      position: absolute;
      &::before {
        @include scroll-button-common-css;
      }
      &__button-disable {
        margin: 0;
        cursor: not-allowed;
        color: $medium-gray;
        left: 50px;
        position: absolute;
        &::before {
          @include scroll-button-common-css;
        }
      }
      &__right {
        margin: 0;
        color: $light-gray;
        &::before {
          @include scroll-button-common-css;
          transform: rotate(-180deg);
        }
        &__button-disable {
          cursor: not-allowed;
          margin: 0;
          color: $medium-gray;
          right: 50px;
          position: absolute;
          &::before {
            @include scroll-button-common-css;
            transform: rotate(-180deg);
          }
        }
      }
    }
    .shop-the-room-detail-container {
      .text-images-container{
        .shop-the-room-detail-image-container{
           .first-image-container,.second-image-container{
            .product-popup {
              z-index: 100;
              position: absolute;
              width:250px;
              height: 320px;
            }
           }
        }
      }
      .view-more-products-container {
        column-gap: 2.227%;
        @media screen and (max-width: 1038px) {
          column-gap: unset;
        }
        .v3_productCardWrapper__FIxaK {
          width: 23.33%;
          @media screen and (max-width: 1038px) {
            width: 48.6%;
          }
          .inspired-selling-module__card-container {
            padding: 20px 0;
          }
        }
      }
    }
  }
}
